import KHColors from 'khshared/KHColors';
import React, {
  MutableRefObject,
  ReactNode,
  forwardRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  ActivityIndicator,
  Platform,
  StyleProp,
  Text,
  TextInput,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import formatError from '../utils/formatError';
import useUpdateEffect from '../utils/useUpdateEffect';
import KHButton, { Props as KHButtonProps } from './KHButton';
import KHIcon from './KHIcon';
import KHText from './KHText';
import KHThemeContext from './KHThemeContext';

const BORDER_RADIUS = 4;

// NOTE: When changing styles, be sure to also update the text input styles in StripeHTML.ts in the
// booking flow to look consistent.
const styles = {
  label: {
    marginBottom: 4,
  } as TextStyle,
  labelV2: {
    color: KHColors.textInputLabelV2,
    fontSize: 14,
    fontWeight: '500',
    fontFamily: 'Red Hat Display',
    lineHeight: 20,
    letterSpacing: 0.16,
  } as TextStyle,
  outline: {
    borderColor: KHColors.textInputOutline,
    borderRadius: BORDER_RADIUS,
    borderWidth: 1,
    flexDirection: 'row',
    alignItems: 'flex-start',
  } as ViewStyle,
  outlineV2: {
    backgroundColor: KHColors.textInputBackgroundV2,
  } as ViewStyle,
  outlineFocused: {
    borderColor: KHColors.textInputOutlineFocused,
  } as ViewStyle,
  outlineDisabled: {
    backgroundColor: KHColors.textInputBackgroundDisabled,
  } as ViewStyle,
  outlineError: {
    borderColor: KHColors.textInputOutlineError,
  } as ViewStyle,
  outlineContrast: {
    borderColor: KHColors.textInputTextContrast,
  } as ViewStyle,
  textInput: {
    alignSelf: 'stretch',
    borderRadius: BORDER_RADIUS,
    minHeight: 24,
    padding: 12,
    paddingTop: 12, // For some reason this needs to be explicit on iOS.
    flex: 1,
    minWidth: 0,
    ...(Platform.OS === 'web' ? { outline: 'none' } : {}),
  } as TextStyle,
  textInputV2: {
    fontSize: 16,
    color: KHColors.textInputTextV2,
    fontFamily: 'Red Hat Display',
    fontWeight: '500',
    lineHeight: 20,
    letterSpacing: 0.16,
  } as TextStyle,
  textInputDisabled: {
    color: KHColors.textInputTextDisabled,
  } as TextStyle,
  textInputWithLeftAdornment: {
    paddingLeft: 0,
  } as ViewStyle,
  textInputWithRightAdornment: {
    paddingRight: 0,
  } as ViewStyle,
  affix: {
    margin: 12,
    color: KHColors.textSecondary,
  } as TextStyle,
  affixV2: {
    fontSize: 16,
    fontFamily: 'Red Hat Display',
    letterSpacing: 0.16,
  } as TextStyle,
  spinner: {
    margin: 10,
  } as TextStyle,
  spinnerV2: {
    margin: 13,
  } as TextStyle,
  icon: {
    margin: 8,
  } as TextStyle,
  iconV2: {
    marginVertical: 11,
  } as TextStyle,
  iconButton: {
    marginVertical: 8,
    marginHorizontal: 8,
  } as TextStyle,
  iconButtonV2: {
    marginVertical: 11,
  } as TextStyle,
  caption: {
    marginTop: 4,
  } as TextStyle,
  captionV2: {
    color: KHColors.textInputCaptionV2,
    fontSize: 14,
    fontWeight: '400',
    fontFamily: 'Red Hat Display',
    lineHeight: 20,
    letterSpacing: 0.16,
  } as TextStyle,
};

function Spinner({ contrast = false }: { contrast?: boolean }) {
  const theme = useContext(KHThemeContext);
  return (
    <ActivityIndicator
      size="small"
      style={[styles.spinner, theme === 'v2' && styles.spinnerV2]}
      color={contrast ? KHColors.iconContrast : undefined}
    />
  );
}

type FrameProps = {
  children: ReactNode;
  caption?: ReactNode;
  error?: Parameters<typeof formatError>[0];
  errorVerbosity?: Parameters<typeof formatError>[1];
  inputStyle?: StyleProp<ViewStyle>;
  inputLabelStyle?: StyleProp<ViewStyle>;
  style?: StyleProp<TextStyle>;
  editable?: boolean;
  label?: ReactNode | string | null;
  left?: ReactNode;
  right?: ReactNode;
  focused?: boolean;
  loading?: boolean;
  contrast?: boolean;
};

function Frame({
  children,
  caption,
  error,
  errorVerbosity,
  inputStyle,
  inputLabelStyle,
  editable = true,
  label,
  left,
  right,
  focused = false,
  loading = false,
  style,
  contrast = false,
}: FrameProps): JSX.Element {
  const theme = useContext(KHThemeContext);
  return (
    <View style={style}>
      {Boolean(label) && (
        <Text style={[styles.label, theme === 'v2' && styles.labelV2, inputLabelStyle]}>
          {label}
        </Text>
      )}
      <View
        style={[
          styles.outline,
          theme === 'v2' && styles.outlineV2,
          editable && focused && styles.outlineFocused,
          editable === false && styles.outlineDisabled,
          error != null && styles.outlineError,
          contrast && styles.outlineContrast,
          inputStyle,
        ]}
      >
        {left != null && left}
        {children}
        {loading ? <Spinner contrast={contrast} /> : right != null && right}
      </View>
      {error != null && (
        <KHText.CaptionError style={styles.caption}>
          {formatError(error, errorVerbosity)}
        </KHText.CaptionError>
      )}
      {Boolean(caption) && (
        <KHText.Caption style={[styles.caption, theme === 'v2' && styles.captionV2]}>
          {caption}
        </KHText.Caption>
      )}
    </View>
  );
}

type Props = React.ComponentProps<typeof TextInput> &
  Omit<FrameProps, 'children' | 'focused'> & {
    // eslint-disable-next-line react/require-default-props
    inputLabelStyle?: StyleProp<TextStyle>;
    // eslint-disable-next-line react/require-default-props
    inputTextStyle?: StyleProp<TextStyle>;
    // eslint-disable-next-line react/require-default-props
    hasClearButton?: boolean;
    // eslint-disable-next-line react/require-default-props
    onClear?: () => void;
    // eslint-disable-next-line react/require-default-props
    contrast?: boolean;
    // eslint-disable-next-line react/require-default-props
  } & { disabled?: boolean };

function Affix({ children }: { children: ReactNode }) {
  const theme = useContext(KHThemeContext);
  return <Text style={[styles.affix, theme === 'v2' && styles.affixV2]}>{children}</Text>;
}

function Icon({
  iconSource,
  source,
  color,
}: {
  iconSource?: Parameters<typeof KHIcon>[0]['iconSource'];
  source: string;
  color?: string;
}) {
  const theme = useContext(KHThemeContext);
  return (
    <KHIcon
      size={24}
      iconSource={iconSource}
      source={source}
      color={color ?? KHColors.iconSecondary}
      style={[styles.icon, theme === 'v2' && styles.iconV2]}
    />
  );
}

function IconButton<E extends `${string}ButtonClick`>({
  icon,
  style,
  ...iconButtonProps
}: Omit<KHButtonProps<E>, 'primary'>) {
  const theme = useContext(KHThemeContext);
  return (
    <KHButton<E>
      textOnly
      icon={icon}
      style={[styles.iconButton, theme === 'v2' && styles.iconButtonV2, style]}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...iconButtonProps}
    />
  );
}

function constructTestIDForTextInput(
  props: React.ComponentProps<typeof TextInput>,
  label: string,
): string | undefined {
  if (props.testID) return props.testID;
  if (label) return `${label.toLowerCase().replace(/[\W_]+/g, '')}-text-input`;
  return undefined;
}

const KHTextInput = forwardRef<TextInput, Props>(
  (
    {
      caption,
      error,
      errorVerbosity,
      editable,
      inputStyle,
      inputLabelStyle,
      inputTextStyle,
      label,
      left,
      right,
      loading,
      placeholder,
      hasClearButton,
      onClear,
      onChangeText,
      style,
      contrast,
      ...textInputProps
    }: Props,
    ref: ((instance: TextInput | null) => void) | MutableRefObject<TextInput | null> | null,
  ): JSX.Element => {
    const theme = useContext(KHThemeContext);
    const [isFocused, setIsFocused] = useState<boolean>(false);

    return (
      <Frame
        caption={caption}
        error={error}
        errorVerbosity={errorVerbosity}
        inputStyle={inputStyle}
        inputLabelStyle={inputLabelStyle}
        editable={editable}
        label={label}
        left={left}
        right={
          right ||
          (hasClearButton && (
            <IconButton
              icon="close"
              onPress={() => {
                onChangeText?.('');
                onClear?.();
              }}
            />
          ))
        }
        focused={isFocused}
        loading={loading}
        style={style}
        contrast={contrast}
      >
        <TextInput
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...textInputProps}
          editable={editable}
          onChangeText={onChangeText}
          placeholder={editable === false ? undefined : placeholder}
          placeholderTextColor={KHColors.textPlaceholder}
          ref={ref}
          onFocus={(e) => {
            setIsFocused(true);
            if (textInputProps.onFocus) textInputProps.onFocus(e);
          }}
          onBlur={(e) => {
            setIsFocused(false);
            if (textInputProps.onBlur) textInputProps.onBlur(e);
          }}
          style={[
            styles.textInput,
            theme === 'v2' && styles.textInputV2,
            editable === false && styles.textInputDisabled,
            left != null && styles.textInputWithLeftAdornment,
            right != null && styles.textInputWithRightAdornment,
            inputTextStyle,
          ]}
          testID={constructTestIDForTextInput(
            textInputProps,
            typeof label === 'string' ? label : '',
          )}
        />
      </Frame>
    );
  },
);

function Debounced({
  value,
  debounceDelayMS = 2000,
  onDebounceStart,
  onChangeText,
  hasClearButton,
  onClear,
  right,
  disabled,
  contrast = false,
  inputReference,
  ...restProps
}: Props & {
  inputReference?: MutableRefObject<TextInput | null>;
  onDebounceStart?: () => void;
  debounceDelayMS?: 0 | 500 | 1000 | 2000;
}): JSX.Element {
  const [debouncedValue, setDebouncedValue] = useState<string>(value ?? '');
  const debouncingTimeoutIDRef = useRef<number | null>(null);
  const debouncingTimeoutFunctionRef = useRef<(() => void) | null>(null);

  // Using refs allows us to safely exclude handlers from the useUpdateEffect's deps list below
  // rather than relying on the parent to reliably use useCallback to ensure they don't change.
  const onDebounceStartRef = useRef(onDebounceStart);
  const onChangeTextRef = useRef(onChangeText);

  function debounceValueChange(newValue: string) {
    setDebouncedValue(newValue);

    if (debouncingTimeoutIDRef.current == null) onDebounceStartRef.current?.();
    else clearTimeout(debouncingTimeoutIDRef.current);

    debouncingTimeoutFunctionRef.current = () => {
      debouncingTimeoutIDRef.current = null;
      onChangeTextRef.current?.(newValue);
    };
    debouncingTimeoutIDRef.current = window.setTimeout(
      debouncingTimeoutFunctionRef.current,
      debounceDelayMS,
    );
  }

  useEffect(() => {
    onDebounceStartRef.current = onDebounceStart;
    onChangeTextRef.current = onChangeText;
  });

  useUpdateEffect(() => {
    const newValue = value ?? '';
    if (newValue === debouncedValue) return;

    // Just in case things get out of sync
    if (debouncingTimeoutIDRef.current == null) setDebouncedValue(newValue);
  }, [value]);

  useEffect(
    () => () => {
      if (debouncingTimeoutIDRef.current == null) return;

      // Flush any pending debounced handlers.
      clearTimeout(debouncingTimeoutIDRef.current);
      debouncingTimeoutFunctionRef.current?.();
    },
    [],
  );

  return (
    <KHTextInput
      value={debouncedValue}
      onChangeText={debounceValueChange}
      right={
        right ||
        (hasClearButton && debouncedValue.length > 0 && (
          <IconButton
            icon="close-circle"
            compact
            iconColor={contrast ? KHColors.iconContrast : KHColors.buttonPrimary}
            onPress={() => {
              clearTimeout(debouncingTimeoutIDRef.current ?? undefined);
              debouncingTimeoutIDRef.current = null;
              setDebouncedValue('');
              onChangeTextRef.current?.('');
              onClear?.();
            }}
            testID={`${restProps.testID ? `${restProps.testID}-` : ''}clear-circle-button`}
          />
        ))
      }
      contrast={contrast}
      ref={inputReference}
      editable={!disabled}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...restProps}
    />
  );
}

export default Object.assign(KHTextInput, { Affix, Debounced, Icon, IconButton, Frame, Spinner });
