/* eslint sort-keys: ["error", "asc", {caseSensitive: false}] */

import {
  CampaignName,
  LabOrganization,
  PartnerOrganization,
  ProductType,
  ServiceType,
  TimeRangeRuleInput,
} from '../API';
import {
  CALIFORNIA_LEAD_SCREENING_AGE_REQUIREMENTS,
  CUSTOMER_SUPPORT_PHONE,
  HEDIS_LEAD_SCREENING_AGE_REQUIREMENTS,
  INTERNAL_ONLY_REROUTE_FAILURE_EMAIL,
  INTERNAL_ONLY_SPRINTER_HEALTH_EMAIL,
  MAX_PATIENTS_PER_APPOINTMENT,
  MAX_PATIENTS_PER_APPOINTMENT_D2C,
  MAX_PATIENTS_PER_APPOINTMENT_SPECIALITY_LABS,
  STANDARD_LEAD_SCREENING_AGE_REQUIREMENTS,
} from '../constants';
import { Dollar, ZERO_DOLLARS, makeDollar } from '../currency';
import getServiceTypesFromProductTypes from '../getServiceTypesFromProductTypes';
import { AgeEligibilityRequirements } from '../types/AgeEligibilityRequirements';
import ConditionalFields from '../types/ConditionalFields';
import Optionality from '../types/Optionality';
import { VitalThreshold } from '../types/Vitals';
import { PATIENT_CONFIG, PatientConfig } from './getPatientConfig';
import { PRODUCT_TYPE_CONFIGS } from './getProductTypeConfig';
import { VITALS_CONFIG } from './getVitalConfig';
import {
  STANDARD_REFERENCE_LAB_ORGANIZATION,
  STANDARD_REFERENCE_LAB_ORGANIZATIONS,
} from './LAB_CONFIGS';
import PartnerOrganizationGroup from './PartnerOrganizationGroup';
import {
  D2C_TIME_RANGE_CONFIGS,
  SCHEDULING_TIME_RANGE_CONFIGS,
} from './SCHEDULING_TIME_RANGE_CONFIGS';
import SERVICE_REGION_LOOKUP from './SERVICE_REGION_LOOKUP';

// TODO(shilpi) change partner vertical to enum.
export type PartnerVertical =
  | 'drVillivalam'
  | 'homeHealth'
  | 'concierge'
  | 'specialtyLab'
  | 'healthSystem'
  | 'careFacility'
  | 'lifeSciences'
  | 'skilledNursingFacility'
  | 'hospice'
  | 'healthPlan'
  | 'directToConsumer'
  | 'cancerCenter'
  | null;

export type PartnerResponsibleManager = 'Sengyeon' | 'Carole' | 'Ryan' | 'Madi';

type PhoneNumbersE164WithExtensions = {
  phoneE164: `+1${string}`;
  extension?: string;
};

interface NurseContactInformation {
  phoneNumbers: PhoneNumbersE164WithExtensions[];
  directions?: string;
}

interface Image {
  uri: string;
  width: number;
  height: number;
}

interface SprinterHealthDefaultProductTypeMappingOverride {
  displayName: string;
  message: string;
  defaultSprinterHealthExternalID: string;
}

export type CareNavigationEpisodeSummaryReportingModel = 'partnerSFTPAndPractitioner' | 'none';

export type PartnerConfig = {
  displayName: string;
  labOrganizations: { [key in LabOrganization]?: ServiceType[] };
  defaultLabOrganization: LabOrganization;
  vertical: PartnerVertical;
  onlyOrdersSingleServiceRequests: boolean;
  partnerFeedbackLink?: string;
  blockSharedSlotAppointments?: boolean;
  skipCancelationFee?: boolean;
  excludeBillingForFailedAppointments?: boolean; // For partners with whom our contract specifies we dont bill for any failed appointments (regardless of fault)
  vitalsThresholds?: VitalThreshold;
  patientConfig?: PatientConfig;
  supportedProductTypes: ProductType[];
  maxAllowedPatientsPerBooking: number;
  /* Partner representative(s) to receive Encounter Summaries. Please double check the email domains to ensure we won't send these to personal emails. */
  appointmentReportEmails?: string[];
  rerouteCSREmailTo?: { [key: string]: string }; // this will reroute all CSR emails to a single email address.
  rerouteFailureEmailTo?: { [key: string]: string }; // this will reroute all failure emails to a single email address.
  /* list of partner's fax numbers to send encounter summary. (should be less than 20 based on westfax docs) */
  faxNumbersE164?: string[];
  schedulingTimeRangeRules?: TimeRangeRuleInput[];
  partnerOrganizationGroup: PartnerOrganizationGroup;
  isTestResultsUploadRequired?: boolean;
  productTypesRequiringRequisitions: ProductType[]; // Currently only used to calculate if an the appointment should be in the "Needs Requisition" state
  patientSupportEmail?: string;
  patientSupportPhoneE164?: `+1${string}`;
  shouldSendAppointmentReportToCoordinator?: boolean;
  ignoreAbstentionsMessaging?: boolean;
  nurseVitalsUrgencyLineInformation?: NurseContactInformation;
  estimatedServiceDurationAdjustmentSeconds?: number;
  shouldExportAppointmentsForBilling?: boolean;
  sprinterHealthSupportPhoneE164?: string;
  showDiagnosisCodes?: boolean;
  patientBookingAppointmentPhoneE164?: `+1${string}`;
  sprinterHealthDefaultProductTypeMappingOverride?: Partial<
    Record<ProductType, SprinterHealthDefaultProductTypeMappingOverride>
  >;
  internalCoordinatorID?: string;
  isGuestReschedulingAllowed?: boolean;
  referralLandingPageUrlPath?: string; // This allows us to customize the URL path for each partner referral landing page
  referralVerificationPageUrlPath?: string; // This allows us to customize the URL path for each partner referral verification page
  supportedServiceRegions: string[];
  fullPartnerDisplayNameForMessaging?: string; // Using the display name in emails is usually too long in the header. We cannot update display name to be the same for suborgs because it would affect internal dropdowns.
  shortPartnerDisplayNameForMessaging?: string; // Due to SMS character limits, we typically use the shortened partner org display names.
  supportsEDILabOrdering: boolean;
  serviceRequestsAddByDefault?: {
    [key in LabOrganization]?: {
      externalID: string;
      serviceSuccessfullyProvidedCutoffPeriod?: 'ytd' | 'lifetime';
      prerequisiteServiceTypes?: ServiceType[]; // only add this service request if all prerequisiteServiceTypes are present
      prerequisiteCampaign?: CampaignName; // only add this service request for patient in a specific campaign
      exclusionEquivalentServiceRequestTypes?: {
        labOrganization: LabOrganization;
        externalIDs: Set<string>;
      }[]; // only add this service request if none of the prerequisiteServiceRequestTypes are present
    }[];
  };
  shouldGenerateEncounterSummaryForVitals?: boolean;
  isAutofillingRequisitionsAllowed: boolean;
  // based on that it shows correct photo and role of responsible manager in partner CSR email
  partnerSupportResponsibleManager: PartnerResponsibleManager;
  zendeskOrganizationName?: string;
  shouldSkipPriorLeadScreeningsQuestion: boolean;
  // the index is the number of times that a patient has done lead screening
  leadScreeningEligibilityRequirements?: AgeEligibilityRequirements[];
  supportsPatientEncounterSummary: boolean;
  careNavigationEpisodeSummaryReportingModel?: CareNavigationEpisodeSummaryReportingModel;
  shouldHideSprinterHealthSupportEmailInReferralsFlow?: boolean;
} & (
  | {
      /* the encounterSummaryReportingModel is used both for determining both how to send encounter summaries and if we need to validate a practitioner */
      encounterSummaryReportingModel: 'partner' | 'partnerAndPractitioner' | 'practitionerOrThrow';
    }
  | {
      encounterSummaryReportingModel: 'practitionerWithDefaultCaseManagerFallBack';
      encounterSummaryReportingDefaultCaseManagerEmail: string;
    }
) &
  (
    | {
        payer: 'none';
        priceUSD: typeof ZERO_DOLLARS;
      }
    | { payer: 'patient' }
    | {
        payer: 'partner';
        paymentType: 'invoice';
        invoiceTerm: 30 | 45 | 60;
        priceUSD: Dollar;
        invoiceEmailType: 'stripe' | 'custom';
        automaticInvoiceSend: boolean;
        priceForProductsUSD?: { [key in ProductType]?: Dollar };
      }
    | {
        payer: 'partner';
        paymentType: 'claims';
        priceUSD: Dollar;
        shouldIncludeFailedAppointmentsInClaims?: boolean;
      }
  ) &
  ConditionalFields<
    { isSelfBookingAllowed: true },
    {
      insuranceUploadFromPatient: Optionality;
      isMultiPatientBookingAllowed: boolean;
      cobrandingLogo?: Image;
    } & (
      | { requisitionUploadFromPatient: 'required' }
      | {
          requisitionUploadFromPatient: 'skip' | 'optional';
          requisitionUploadFromScheduler: Optionality;
        }
    ),
    'cobrandingLogo'
  > &
  ConditionalFields<
    { isPartnerBookingAllowed: true },
    {
      insuranceUploadFromPartner: Optionality;

      /**
       * Whether a requisition upload is required from the partner at the time of booking.
       *
       * - `skip`: The partner is not allowed to upload a requisition, either because no printable
       *     requisition is needed or we can generate it ourselves, either via EDI or autofilled
       *     PDF. By appointment volume, this is by far the most common case, representing nearly
       *     all health plan and specialty lab partners.
       * - `optional`: The partner is allowed to upload a requisition, but not required to for all
       *     appointments. Such partners are typically large provider-like groups like health
       *     systems and care extension arms of health plans who book both services that do require
       *     printable requisitions (e.g. labs) and gap closure services that don't (e.g. vitals,
       *     DREs).
       * - `required_after_booking`: Due to workflow constraints on their side, we've allowed a
       *     small number of health systems that want to be the ordering provider for their tests to
       *     defer uploads until after booking, though the uploads are still required before the
       *     appointment and will be displayed in UI as "NEEDS REQUISITION" until that point. This
       *     includes care-gap-focused health systems like BMC and UCI, care extension arms of
       *     health plans like Oscar Care, and (now unsupported) cancer centers like Intermountain
       *     and Stanford.
       * - `required`: The partner must upload a requisition for every encounter in the appointment.
       *     This is now mostly unused and is a legacy of now unsupported partners like home health,
       *     concierge, and a few liquid biopsy companies where we were once responsible for
       *     carrying their kits. This really only makes sense for partners who are consistently the
       *     ordering provider for all their patients' service requests, effectively excluding
       *     anything besides basic specimen collection (labs).
       */
      requisitionUploadFromPartner: Optionality | 'required_after_booking';

      // For partners that have historically had issues with their requisition uploads, we want create a ZD ticket and review
      // the upload quickly to catch issues.
      shouldSendRequisitionUploadReviewZDTicket?: boolean;
      serviceRequestsFromPartner: Optionality;
      reschedulingCoordinator: 'partner' | 'sprinterHealth';
      domainAllowList: Map<string, string[]>;
      selectSingleServiceRequestExternalID?: string;
      // Some partners (e.g., Guardant Shield and Guardant) share a LabOrganization, but have different sets of supported testcodes.
      // Because service requests are indexed by service types and lab organizations, this is the only way (currently) to show one set
      // of codes to one partner vs the other when they share the same labOrganization.
      allowedServiceRequests?: { [key in LabOrganization]?: string[] };
      disallowedServiceRequests?: { [key in LabOrganization]?: string[] };
      serviceRequestsFormatType?: string; // used for formatServiceRequest
      canEditServicesAfterBooking?: boolean;
    }
  > &
  ConditionalFields<
    { supportsPatientEncounterSummary: true },
    {
      // Controls whether we provide the option send the patient encounter summary by direct mail in case we cannot send it by SMS or email.
      supportsPatientEncounterSummaryDirectMail: boolean;
    }
  > &
  (
    | ConditionalFields<
        // Ensure that cobranding logo is set if referral booking is allowed
        { isReferralBookingAllowed: true },
        | { cobrandingLogo: Image; shouldUsePartnerBrands?: false }
        | { shouldUsePartnerBrands: true },
        'cobrandingLogo'
      >
    | { isReferralBookingAllowed: false; shouldUsePartnerBrands: boolean }
  ) &
  (
    | { isReferralBookingAllowed: true; supportsReferrals: true } // If referral booking is allowed, supportsReferrals should be true
    | { isReferralBookingAllowed: false; supportsReferrals: boolean } // There are some partners without referrals booking but still support referrals e.g. Elevance
  ) &
  ConditionalFields<{ supportsReferrals: true }, { referralCoordinatorID: string }>; // If supportsReferral is true, we should provide a referral coordinator ID as it'll be used for upload and other related items

export function normalizeEmailOrDomain(emailOrDomain: string): string {
  return emailOrDomain.toLowerCase();
}

/**
 * Takes a list of email domains, normalizes them and associates them with the "native" cognito identity provider.
 * The "native" cognito identity provider is the username and a password login on the site as opposed to OIDC or other single sign on external providers. If a partner needs to create logins with a, external provider the collection should be manually created.
 * @param emailDomains
 * @returns
 */
function normalizeEmailDomainsAndAssociateWithNativeProvider(
  emailDomains: string[],
): Map<string, string[]> {
  return new Map<string, string[]>(
    Array.from(new Set(emailDomains.map(normalizeEmailOrDomain))).map((domain) => [
      domain,
      ['NATIVE'],
    ]),
  );
}

const DEFAULT_SUPPORTED_REGIONS: string[] = Object.entries(SERVICE_REGION_LOOKUP)
  .filter(([, value]) => value.isActive && !value?.isActiveOnlyForReferrals)
  .map(([key]) => key);

export const ALIGNMENT_EXTRA_SERVICE_AREAS: string[] = Object.entries(SERVICE_REGION_LOOKUP)
  .filter(([, value]) => value.isActive && value?.isActiveOnlyForReferrals)
  .map(([key]) => key);

const STANDARD_REFERENCE_LAB_ORGANIZATION_SETTINGS = STANDARD_REFERENCE_LAB_ORGANIZATIONS.reduce(
  (acc, lab) => {
    acc[lab] = getServiceTypesFromProductTypes([ProductType.LAB_TEST]);
    return acc;
  },
  {} as { [key in LabOrganization]?: ServiceType[] },
);

const GLOBAL_PARTNER_DEFAULTS = {
  encounterSummaryReportingModel: 'partner' as const,
  isAutofillingRequisitionsAllowed: false as const,
  isGuestReschedulingAllowed: true as const,
  isReferralBookingAllowed: false as const,
  productTypesRequiringRequisitions: [ProductType.LAB_TEST],
  shouldSkipPriorLeadScreeningsQuestion: false as const,
  supportedServiceRegions: DEFAULT_SUPPORTED_REGIONS,
  supportsEDILabOrdering: false as const,
  supportsPatientEncounterSummary: false as const,
  supportsReferrals: false as const,
};

const STANDARD_SPECIALTY_LAB_PARTNER_BOOKING = {
  ...GLOBAL_PARTNER_DEFAULTS,
  automaticInvoiceSend: true,
  insuranceUploadFromPartner: 'skip' as const,
  invoiceEmailType: 'stripe' as const,
  invoiceTerm: 30 as const,
  isPartnerBookingAllowed: true as const,
  isSelfBookingAllowed: false as const,
  maxAllowedPatientsPerBooking: MAX_PATIENTS_PER_APPOINTMENT_SPECIALITY_LABS as number,
  onlyOrdersSingleServiceRequests: true,
  payer: 'partner' as const,
  paymentType: 'invoice' as const,
  priceUSD: makeDollar(79),
  requisitionUploadFromPartner: 'skip' as const,
  reschedulingCoordinator: 'partner' as const,
  serviceRequestsFromPartner: 'required' as const,
  skipCancelationFee: false,
  supportedProductTypes: [ProductType.LAB_TEST],
  vertical: 'specialtyLab' as const,
};

const STANDARD_SPECIALTY_LAB_SELF_BOOKING = {
  ...GLOBAL_PARTNER_DEFAULTS,
  insuranceUploadFromPatient: 'skip' as const,
  isMultiPatientBookingAllowed: false,
  isSelfBookingAllowed: true as const,
  priceUSD: makeDollar(79),
  requisitionUploadFromPatient: 'skip' as const,
  requisitionUploadFromScheduler: 'skip' as const,
  skipCancelationFee: false,
  supportedProductTypes: [ProductType.LAB_TEST],
  vertical: 'specialtyLab' as const,
};

const STANDARD_HOME_HEALTH_PARTNER_BOOKING = {
  ...GLOBAL_PARTNER_DEFAULTS,
  automaticInvoiceSend: true,
  defaultLabOrganization: STANDARD_REFERENCE_LAB_ORGANIZATION,
  insuranceUploadFromPartner: 'required' as const,
  invoiceEmailType: 'stripe' as const,
  invoiceTerm: 30 as const,
  isPartnerBookingAllowed: true as const,
  isSelfBookingAllowed: false as const,
  labOrganizations: STANDARD_REFERENCE_LAB_ORGANIZATION_SETTINGS,
  maxAllowedPatientsPerBooking: MAX_PATIENTS_PER_APPOINTMENT as number,
  onlyOrdersSingleServiceRequests: false,
  payer: 'partner' as const,
  paymentType: 'invoice' as const,
  priceUSD: makeDollar(79),
  requisitionUploadFromPartner: 'required' as const,
  reschedulingCoordinator: 'partner' as const,
  serviceRequestsFromPartner: 'skip' as const,
  shouldSendRequisitionUploadReviewZDTicket: true,
  skipCancelationFee: false,
  supportedProductTypes: [ProductType.LAB_TEST],
  vertical: 'homeHealth' as const,
};

const STANDARD_CONCIERGE_PARTNER_BOOKING = {
  ...GLOBAL_PARTNER_DEFAULTS,
  automaticInvoiceSend: true,
  blockSharedSlotAppointments: true,
  defaultLabOrganization: STANDARD_REFERENCE_LAB_ORGANIZATION,
  insuranceUploadFromPartner: 'required' as const,
  invoiceEmailType: 'stripe' as const,
  invoiceTerm: 30 as const,
  isPartnerBookingAllowed: true as const,
  isSelfBookingAllowed: false as const,
  labOrganizations: STANDARD_REFERENCE_LAB_ORGANIZATION_SETTINGS,
  maxAllowedPatientsPerBooking: MAX_PATIENTS_PER_APPOINTMENT as number,
  onlyOrdersSingleServiceRequests: false,
  payer: 'partner' as const,
  paymentType: 'invoice' as const,
  priceUSD: makeDollar(79),
  requisitionUploadFromPartner: 'required' as const,
  reschedulingCoordinator: 'partner' as const,
  serviceRequestsFromPartner: 'skip' as const,
  skipCancelationFee: false,
  supportedProductTypes: [ProductType.LAB_TEST],
  vertical: 'concierge' as const,
};

const STANDARD_HEALTH_SYSTEM_PARTNER_BOOKING = {
  ...GLOBAL_PARTNER_DEFAULTS,
  automaticInvoiceSend: true,
  insuranceUploadFromPartner: 'required' as const,
  invoiceEmailType: 'stripe' as const,
  invoiceTerm: 30 as const,
  isPartnerBookingAllowed: true as const,
  isSelfBookingAllowed: false as const,
  labOrganizations: STANDARD_REFERENCE_LAB_ORGANIZATION_SETTINGS,
  maxAllowedPatientsPerBooking: MAX_PATIENTS_PER_APPOINTMENT as number,
  onlyOrdersSingleServiceRequests: false,
  payer: 'partner' as const,
  paymentType: 'invoice' as const,
  priceUSD: makeDollar(79),
  requisitionUploadFromPartner: 'required' as const,
  reschedulingCoordinator: 'partner' as const,
  serviceRequestsFromPartner: 'skip' as const,
  skipCancelationFee: false,
  supportedProductTypes: [ProductType.LAB_TEST],
  vertical: 'healthSystem' as const,
};

const STANDARD_CANCER_CENTER_PARTNER_BOOKING = {
  ...STANDARD_HEALTH_SYSTEM_PARTNER_BOOKING,
  vertical: 'cancerCenter' as const,
};

const INTERMOUNTAIN_CANCER_CENTER_BASE = {
  ...STANDARD_CANCER_CENTER_PARTNER_BOOKING,
  automaticInvoiceSend: false,
  defaultLabOrganization: LabOrganization.INTERMOUNTAIN_CANCER_CENTER,
  domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider([
    'imail.org',
    'sprinterhealth.com',
  ]),
  insuranceUploadFromPartner: 'skip' as const,
  isAutofillingRequisitionsAllowed: true,
  isGuestReschedulingAllowed: false,
  labOrganizations: {
    [LabOrganization.INTERMOUNTAIN_CANCER_CENTER]: [
      ServiceType.BLOOD,
      ServiceType.URINE,
      ServiceType.CAPILLARY_BLOOD_DRAW,
    ],
  },
  partnerOrganizationGroup: PartnerOrganizationGroup.INTERMOUNTAINCANCERCENTER,
  partnerSupportResponsibleManager: 'Carole' as PartnerResponsibleManager,
  priceUSD: makeDollar(120),
  requisitionUploadFromPartner: 'required_after_booking' as const,
  reschedulingCoordinator: 'sprinterHealth' as const,
  shouldExportAppointmentsForBilling: true,
  skipCancelationFee: true,
};

const BMC_BASE = {
  ...STANDARD_HEALTH_SYSTEM_PARTNER_BOOKING,
  automaticInvoiceSend: false,
  domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['bmc.org']),
  partnerFeedbackLink: 'https://sprinterhealth.com/feedback_bmc',
  partnerOrganizationGroup: PartnerOrganizationGroup.BOSTONMEDICALCENTER,
  partnerSupportResponsibleManager: 'Sengyeon',
  shouldExportAppointmentsForBilling: true,
  shouldGenerateEncounterSummaryForVitals: true,
  sprinterHealthDefaultProductTypeMappingOverride: {
    [ProductType.VITALS]: {
      defaultSprinterHealthExternalID: 'BLOOD-PRESSURE-CHECK',
      displayName: 'Blood pressure collection',
      message: '',
    },
  },
};

const CENTENE_BASE = {
  ...GLOBAL_PARTNER_DEFAULTS,
  allowedServiceRequests: {
    QUEST: [
      '375', // eGFR/Creatinine
      '496', //  Quest A1C
      '6517', // uACR/Microalbumin
    ],
    SPRINTER_HEALTH: [
      'BLOOD-PRESSURE-CHECK',
      'DIABETIC-RETINAL-SCAN',
      'RACE-AND-ETHNICITY-QUESTIONNAIRE',
      'SDOH-QUESTIONNAIRE', // TODO(86b0ajwzy) This isn't applied by default as it will be line of business specific. We should add this service on upload
      'INSURE-ONE-FIT-KIT-DELIVERY-AND-EDUCATION', // CENTENEOH has an override for Cologuard
    ],
  },
  automaticInvoiceSend: false,
  defaultLabOrganization: LabOrganization.QUEST,
  domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['centene.com']),
  encounterSummaryReportingModel: 'practitionerOrThrow' as const,
  insuranceUploadFromPartner: 'skip' as const,
  invoiceEmailType: 'stripe' as const,
  invoiceTerm: 60 as const,
  isAutofillingRequisitionsAllowed: true as const,
  isGuestReschedulingAllowed: true as const,
  isPartnerBookingAllowed: true as const,
  isReferralBookingAllowed: true as const,
  isSelfBookingAllowed: false as const,
  isTestResultsUploadRequired: true as const,
  labOrganizations: {
    [LabOrganization.QUEST]: [ServiceType.BLOOD, ServiceType.URINE],
    [LabOrganization.SPRINTER_HEALTH]: [
      ServiceType.VITALS,
      ServiceType.DIABETIC_RETINAL_SCAN,
      ServiceType.RACE_AND_ETHNICITY,
      ServiceType.SDOH_QUESTIONNAIRE,
      ServiceType.KIT_DELIVERY_AND_EDUCATION, // CENTENEOH has an override for Cologuard
    ],
  },
  maxAllowedPatientsPerBooking: 1 as const,
  onlyOrdersSingleServiceRequests: false as const,
  // There isn't a partner-specific support phone number or email
  // The support number might be brand specific but we don't need to show that anywhere
  partnerOrganizationGroup: PartnerOrganizationGroup.CENTENE,
  partnerSupportResponsibleManager: 'Carole' as PartnerResponsibleManager,
  payer: 'partner' as const,
  paymentType: 'invoice' as const,
  // We are not charging for SDOH like we have done for Elevance. We want to mimic Molina base here.
  // Only Centene OH supports Cologuard. CA, FL, MA, MI, NH support Insure One Fit Kit.
  priceForProductsUSD: {
    COLOGUARD_KIT_EDUCATION: makeDollar(20), // Cologuard Kit Education for OH only
    DIABETIC_RETINAL_SCAN: makeDollar(50), // Eye Exam
    KIT_DELIVERY_AND_EDUCATION: makeDollar(20), // Insure One Fit Kit
    LAB_TEST: makeDollar(20), // Labs
    VITALS: makeDollar(10), // BP Check
  },
  priceUSD: makeDollar(130),
  requisitionUploadFromPartner: 'skip' as const,
  reschedulingCoordinator: 'sprinterHealth' as const,
  serviceRequestsAddByDefault: {
    SPRINTER_HEALTH: [
      {
        externalID: 'RACE-AND-ETHNICITY-QUESTIONNAIRE',
        serviceSuccessfullyProvidedCutoffPeriod: 'lifetime' as const,
      },
    ],
  },
  serviceRequestsFromPartner: 'required' as const,
  shouldExportAppointmentsForBilling: true as const,
  shouldGenerateEncounterSummaryForVitals: true as const,
  shouldUsePartnerBrands: true as const,
  showDiagnosisCodes: true as const,
  skipCancelationFee: true as const,
  sprinterHealthDefaultProductTypeMappingOverride: {
    [ProductType.VITALS]: {
      defaultSprinterHealthExternalID: 'BLOOD-PRESSURE-CHECK',
      displayName: 'Blood pressure collection',
      message: '',
    },
    [ProductType.KIT_DELIVERY_AND_EDUCATION]: {
      // We override this FIT Kit override for CENTENEOH, which supports Cologuard
      defaultSprinterHealthExternalID: 'INSURE-ONE-FIT-KIT-DELIVERY-AND-EDUCATION',
      displayName: 'Colorectal screening kit delivery and education',
      message: '',
    },
  },
  supportedProductTypes: [
    ProductType.LAB_TEST,
    ProductType.VITALS,
    ProductType.KIT_DELIVERY_AND_EDUCATION,
    ProductType.DIABETIC_RETINAL_SCAN,
    ProductType.QUESTIONNAIRES,
  ],
  supportsEDILabOrdering: true as const,
  supportsPatientEncounterSummary: true as const,
  supportsPatientEncounterSummaryDirectMail: true as const,
  supportsReferrals: true as const,
  vertical: 'healthPlan' as const,
};

const HUMANA_BASE = {
  ...GLOBAL_PARTNER_DEFAULTS,
  allowedServiceRequests: {
    QUEST: [
      '375', // eGFR/Creatinine
      '496', //  Quest A1C
      '6517', // uACR/Microalbumin
    ],
    SPRINTER_HEALTH: [
      'BLOOD-PRESSURE-CHECK',
      'DIABETIC-RETINAL-SCAN',
      'DEXA_SCAN_CARE_NAVIGATION_BOOKING',
      'DEXA_SCAN_CARE_NAVIGATION_CONFIRMATION',
      'MAMMOGRAM_CARE_NAVIGATION_BOOKING',
      'MAMMOGRAM_CARE_NAVIGATION_CONFIRMATION',
      'PRAPARE_SDOH_CARE_NAVIGATION_RESOURCE_GUIDANCE',
      'SDOH-QUESTIONNAIRE',
      'RACE-AND-ETHNICITY-QUESTIONNAIRE',
      'INSURE-ONE-FIT-KIT-DELIVERY-AND-EDUCATION',
    ],
  },
  automaticInvoiceSend: false,
  blockSharedSlotAppointments: false,
  careNavigationEpisodeSummaryReportingModel: 'none' as const,
  cobrandingLogo: {
    height: 28,
    uri: 'https://app.sprinterhealth.com/humana/humana_logo.png',
    width: 94,
  },
  defaultLabOrganization: LabOrganization.QUEST,
  domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['humana.com']),
  encounterSummaryReportingModel: 'practitionerOrThrow' as const,
  fullPartnerDisplayNameForMessaging: 'Humana',
  insuranceUploadFromPartner: 'skip' as const,
  invoiceEmailType: 'stripe' as const,
  invoiceTerm: 60 as const,
  isAutofillingRequisitionsAllowed: true,
  isPartnerBookingAllowed: true as const,
  isReferralBookingAllowed: true as const,
  isSelfBookingAllowed: false as const,
  isTestResultsUploadRequired: true,
  labOrganizations: {
    [LabOrganization.QUEST]: [ServiceType.BLOOD, ServiceType.URINE],
    [LabOrganization.SPRINTER_HEALTH]: [
      ServiceType.VITALS,
      ServiceType.DIABETIC_RETINAL_SCAN,
      ServiceType.SDOH_CARE_NAVIGATION_RESOURCE_GUIDANCE,
      ServiceType.DEXA_SCAN_CARE_NAVIGATION_BOOKING,
      ServiceType.DEXA_SCAN_CARE_NAVIGATION_CONFIRMATION,
      ServiceType.MAMMOGRAM_CARE_NAVIGATION_BOOKING,
      ServiceType.MAMMOGRAM_CARE_NAVIGATION_CONFIRMATION,
      ServiceType.RACE_AND_ETHNICITY,
      ServiceType.SDOH_QUESTIONNAIRE,
      ServiceType.KIT_DELIVERY_AND_EDUCATION,
    ],
  },
  maxAllowedPatientsPerBooking: 1,
  onlyOrdersSingleServiceRequests: false,
  partnerOrganizationGroup: PartnerOrganizationGroup.HUMANA,
  partnerSupportResponsibleManager: 'Carole' as const,
  patientSupportPhoneE164: '+18669340640' as const,
  payer: 'partner' as const,
  paymentType: 'invoice' as const,
  priceForProductsUSD: {
    DEXA_SCAN_CARE_NAVIGATION_BOOKING: makeDollar(90), // Bone Density Scan booking
    DEXA_SCAN_CARE_NAVIGATION_CONFIRMATION: makeDollar(90), // Bone Density Scan confirmation
    DIABETIC_RETINAL_SCAN: makeDollar(50), // Eye Exam
    KIT_DELIVERY_AND_EDUCATION: makeDollar(20), // FIT Kit
    LAB_TEST: makeDollar(20), // Labs
    MAMMOGRAM_CARE_NAVIGATION_BOOKING: makeDollar(90), // Breast Cancer Screening booking
    MAMMOGRAM_CARE_NAVIGATION_CONFIRMATION: makeDollar(90), // Breast Cancer Screening confirmation
    SDOH_CARE_NAVIGATION_RESOURCE_GUIDANCE: makeDollar(20), // SDOH Care Navigation questionnaire
    VITALS: makeDollar(10), // BP Check
  },
  priceUSD: makeDollar(149),
  productTypesRequiringRequisitions: [ProductType.LAB_TEST],
  requisitionUploadFromPartner: 'skip' as const,
  reschedulingCoordinator: 'sprinterHealth' as const,
  serviceRequestsAddByDefault: {
    [LabOrganization.SPRINTER_HEALTH]: [
      {
        exclusionEquivalentServiceRequestTypes: [
          {
            externalIDs: new Set(['PRAPARE_SDOH_CARE_NAVIGATION_RESOURCE_GUIDANCE']),
            labOrganization: LabOrganization.SPRINTER_HEALTH,
          },
        ],
        externalID: 'RACE-AND-ETHNICITY-QUESTIONNAIRE',
        serviceSuccessfullyProvidedCutoffPeriod: 'lifetime' as const,
      },
      {
        exclusionEquivalentServiceRequestTypes: [
          {
            externalIDs: new Set(['PRAPARE_SDOH_CARE_NAVIGATION_RESOURCE_GUIDANCE']),
            labOrganization: LabOrganization.SPRINTER_HEALTH,
          },
        ],
        externalID: 'SDOH-QUESTIONNAIRE',
        serviceSuccessfullyProvidedCutoffPeriod: 'ytd' as const,
      },
    ],
  },
  serviceRequestsFromPartner: 'required' as const,
  shortPartnerDisplayNameForMessaging: 'Humana',
  shouldGenerateEncounterSummaryForVitals: true,
  showDiagnosisCodes: true,
  skipCancelationFee: true,
  sprinterHealthDefaultProductTypeMappingOverride: {
    [ProductType.VITALS]: {
      defaultSprinterHealthExternalID: 'BLOOD-PRESSURE-CHECK',
      displayName: 'Blood pressure collection',
      message: '',
    },
    [ProductType.KIT_DELIVERY_AND_EDUCATION]: {
      defaultSprinterHealthExternalID: 'INSURE-ONE-FIT-KIT-DELIVERY-AND-EDUCATION',
      displayName: 'Colorectal screening kit delivery and education',
      message: '',
    },
  },
  supportedProductTypes: [
    ProductType.VITALS,
    ProductType.LAB_TEST,
    ProductType.DIABETIC_RETINAL_SCAN,
    ProductType.SDOH_CARE_NAVIGATION_RESOURCE_GUIDANCE,
    ProductType.DEXA_SCAN_CARE_NAVIGATION_BOOKING,
    ProductType.DEXA_SCAN_CARE_NAVIGATION_CONFIRMATION,
    ProductType.MAMMOGRAM_CARE_NAVIGATION_BOOKING,
    ProductType.MAMMOGRAM_CARE_NAVIGATION_CONFIRMATION,
    ProductType.KIT_DELIVERY_AND_EDUCATION,
  ],
  supportsEDILabOrdering: true as const,
  supportsPatientEncounterSummary: true as const,
  supportsPatientEncounterSummaryDirectMail: false as const,
  supportsReferrals: true as const,
  vertical: 'healthPlan' as const,
};

const MOLINA_BASE = {
  ...GLOBAL_PARTNER_DEFAULTS,
  cobrandingLogo: {
    height: 28,
    uri: 'https://app.sprinterhealth.com/molina/molina_logo.png',
    width: 92,
  },
  defaultLabOrganization: LabOrganization.QUEST,
  domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['sprinterhealth.com']),
  fullPartnerDisplayNameForMessaging: 'Molina Healthcare',
  insuranceUploadFromPartner: 'skip' as const,
  isAutofillingRequisitionsAllowed: true as const,
  isPartnerBookingAllowed: true as const,
  isReferralBookingAllowed: true as const,
  isSelfBookingAllowed: false as const,
  isTestResultsUploadRequired: true as const,
  maxAllowedPatientsPerBooking: 1 as const,
  onlyOrdersSingleServiceRequests: false as const,
  partnerOrganizationGroup: PartnerOrganizationGroup.MOLINA,
  partnerSupportResponsibleManager: 'Carole' as const,
  payer: 'partner' as const,
  paymentType: 'claims' as const,
  requisitionUploadFromPartner: 'skip' as const,
  reschedulingCoordinator: 'sprinterHealth' as const,
  serviceRequestsAddByDefault: {
    SPRINTER_HEALTH: [
      {
        externalID: 'RACE-AND-ETHNICITY-QUESTIONNAIRE',
        serviceSuccessfullyProvidedCutoffPeriod: 'lifetime' as const,
      },
      {
        externalID: 'SDOH-QUESTIONNAIRE',
        serviceSuccessfullyProvidedCutoffPeriod: 'ytd' as const,
      },
    ],
  },
  serviceRequestsFromPartner: 'required' as const,
  shortPartnerDisplayNameForMessaging: 'Molina',
  shouldGenerateEncounterSummaryForVitals: true as const,
  shouldIncludeFailedAppointmentsInClaims: true as const,
  showDiagnosisCodes: true as const,
  skipCancelationFee: true as const,
  sprinterHealthDefaultProductTypeMappingOverride: {
    [ProductType.VITALS]: {
      defaultSprinterHealthExternalID: 'BLOOD-PRESSURE-CHECK',
      displayName: 'Blood pressure collection',
      message: '',
    },
    [ProductType.KIT_DELIVERY_AND_EDUCATION]: {
      defaultSprinterHealthExternalID: 'INSURE-ONE-FIT-KIT-DELIVERY-AND-EDUCATION',
      displayName: 'Colorectal screening kit delivery and education',
      message: '',
    },
  },
  supportsPatientEncounterSummary: true as const,
  supportsPatientEncounterSummaryDirectMail: false as const,
  supportsReferrals: true as const,
  vertical: 'healthPlan' as const,
};

const STANDARD_CARE_FACILITY_PARTNER_BOOKING = {
  ...GLOBAL_PARTNER_DEFAULTS,
  automaticInvoiceSend: true,
  blockSharedSlotAppointments: true,
  defaultLabOrganization: STANDARD_REFERENCE_LAB_ORGANIZATION,
  insuranceUploadFromPartner: 'required' as const,
  invoiceEmailType: 'stripe' as const,
  invoiceTerm: 30 as const,
  isPartnerBookingAllowed: true as const,
  isSelfBookingAllowed: false as const,
  labOrganizations: STANDARD_REFERENCE_LAB_ORGANIZATION_SETTINGS,
  maxAllowedPatientsPerBooking: MAX_PATIENTS_PER_APPOINTMENT as number,
  onlyOrdersSingleServiceRequests: false,
  payer: 'partner' as const,
  paymentType: 'invoice' as const,
  priceUSD: makeDollar(79),
  requisitionUploadFromPartner: 'required' as const,
  reschedulingCoordinator: 'partner' as const,
  serviceRequestsFromPartner: 'skip' as const,
  skipCancelationFee: false,
  supportedProductTypes: [ProductType.LAB_TEST],
  vertical: 'careFacility' as const,
};

const STANDARD_SKILLED_NURSING_FACILITY_PARTNER_BOOKING = {
  ...GLOBAL_PARTNER_DEFAULTS,
  automaticInvoiceSend: true,
  defaultLabOrganization: STANDARD_REFERENCE_LAB_ORGANIZATION,
  insuranceUploadFromPartner: 'required' as const,
  invoiceEmailType: 'stripe' as const,
  invoiceTerm: 30 as const,
  isPartnerBookingAllowed: true as const,
  isSelfBookingAllowed: false as const,
  labOrganizations: STANDARD_REFERENCE_LAB_ORGANIZATION_SETTINGS,
  maxAllowedPatientsPerBooking: MAX_PATIENTS_PER_APPOINTMENT as number,
  onlyOrdersSingleServiceRequests: false,
  payer: 'partner' as const,
  paymentType: 'invoice' as const,
  priceUSD: makeDollar(79),
  requisitionUploadFromPartner: 'required' as const,
  reschedulingCoordinator: 'partner' as const,
  serviceRequestsFromPartner: 'skip' as const,
  skipCancelationFee: false,
  supportedProductTypes: [ProductType.LAB_TEST],
  vertical: 'skilledNursingFacility' as const,
};

const HOME_HEALTH_RESOURCE_GROUP_BASE = {
  ...STANDARD_HOME_HEALTH_PARTNER_BOOKING,
  automaticInvoiceSend: false as const,
  domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['hhrgconnect.com']),
  insuranceUploadFromPartner: 'required' as const,
  invoiceEmailType: 'stripe' as const,
  invoiceTerm: 30 as const,
  isPartnerBookingAllowed: true as const,
  isSelfBookingAllowed: false as const,
  onlyOrdersSingleServiceRequests: false,
  partnerOrganizationGroup: PartnerOrganizationGroup.HOMEHEALTHRESOURCEGROUP as const,
  patientSupportEmail: 'yves.delfin@hhrgconnect.com' as const,
  patientSupportPhoneE164: '+18772320807' as const,
  payer: 'partner' as const,
  priceUSD: makeDollar(79),
  requisitionUploadFromPartner: 'required' as const,
  reschedulingCoordinator: 'partner' as const,
  serviceRequestsFromPartner: 'skip' as const,
  skipCancelationFee: false as const,
};

/**
 * The configurations for each partner.
 *
 * Built from https://docs.google.com/spreadsheets/d/1QtRJxSmL--w_LS6A9z2bQQfx10uKcwBiVU_baUEo704.
 *
 * NOTE: Prefer `getPartnerConfig()`, which handles D2C, unless you are iterating over the partners
 * list.
 * Any sensitive partner information like Stripe IDs should go in getSensitivePartnerConfig.ts
 */
export const PARTNER_CONFIGS: {
  [partnerOrganization in PartnerOrganization]: PartnerConfig;
} = {
  // Please try to keep this sorted.
  A1HOMEHEALTHANDHOSPICE: {
    ...STANDARD_HOME_HEALTH_PARTNER_BOOKING,
    displayName: 'A1 Home Health',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['a1hch.com']),
    partnerOrganizationGroup: PartnerOrganizationGroup.A1HOMEHEALTHANDHOSPICE,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportEmail: 'intake@a1hch.com',
    patientSupportPhoneE164: '+19167063647',
    priceUSD: makeDollar(79),
  },
  AAHCKINDREDALAMEDA: {
    ...STANDARD_HOME_HEALTH_PARTNER_BOOKING,
    displayName: 'Asian American Home Care Alameda',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['gentiva.com']),
    fullPartnerDisplayNameForMessaging: 'Asian American Home Care',
    partnerOrganizationGroup: PartnerOrganizationGroup.AAHCKINDRED,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportEmail: 'yingyu.gao@gentiva.com',
    patientSupportPhoneE164: '+14082835100',
  },
  AAHCKINDREDSANJOSE: {
    ...STANDARD_HOME_HEALTH_PARTNER_BOOKING,
    displayName: 'Asian American Home Care San Jose',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['gentiva.com']),
    fullPartnerDisplayNameForMessaging: 'Asian American Home Care',
    partnerOrganizationGroup: PartnerOrganizationGroup.AAHCKINDRED,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportEmail: 'qianyi.huang@gentiva.com',
    patientSupportPhoneE164: '+14082835100',
    priceUSD: makeDollar(99),
  },
  ABLHEALTHCARE: {
    ...STANDARD_HOME_HEALTH_PARTNER_BOOKING,
    displayName: 'ABL Health Care',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['ablhealthcare.com']),
    partnerOrganizationGroup: PartnerOrganizationGroup.ABLHEALTHCARE,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportEmail: 'info@ablhealthcare.com',
    patientSupportPhoneE164: '+16502570559',
  },
  ACCOMPANYHEALTHMI: {
    ...GLOBAL_PARTNER_DEFAULTS,
    appointmentReportEmails: ['accompanydetroit.navigators@accompany.health'],
    automaticInvoiceSend: false,
    blockSharedSlotAppointments: false,
    canEditServicesAfterBooking: true,
    defaultLabOrganization: LabOrganization.QUEST,
    displayName: 'Accompany Health',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['accompany.health']),
    encounterSummaryReportingModel: 'partner' as const,
    excludeBillingForFailedAppointments: true,
    ignoreAbstentionsMessaging: false,
    insuranceUploadFromPartner: 'skip' as const,
    invoiceEmailType: 'stripe',
    invoiceTerm: 30,
    isGuestReschedulingAllowed: true,
    isPartnerBookingAllowed: true,
    isReferralBookingAllowed: false,
    isSelfBookingAllowed: false,
    labOrganizations: {
      [LabOrganization.QUEST]: [ServiceType.BLOOD, ServiceType.URINE],
      [LabOrganization.SPRINTER_HEALTH]: [ServiceType.DIABETIC_RETINAL_SCAN],
    },
    maxAllowedPatientsPerBooking: 1 as number,
    onlyOrdersSingleServiceRequests: false,
    partnerOrganizationGroup: PartnerOrganizationGroup.ACCOMPANYHEALTH,
    partnerSupportResponsibleManager: 'Madi',
    patientConfig: PATIENT_CONFIG.ACCOMPANYHEALTHMI,
    payer: 'partner',
    paymentType: 'invoice',
    priceForProductsUSD: {
      DIABETIC_RETINAL_SCAN: makeDollar(50),
      LAB_TEST: makeDollar(20),
    },
    priceUSD: makeDollar(79),
    productTypesRequiringRequisitions: [ProductType.LAB_TEST],
    requisitionUploadFromPartner: 'required',
    reschedulingCoordinator: 'sprinterHealth',
    serviceRequestsFromPartner: 'skip',
    shouldExportAppointmentsForBilling: true,
    shouldGenerateEncounterSummaryForVitals: true,
    showDiagnosisCodes: true,
    skipCancelationFee: true,
    supportedProductTypes: [ProductType.DIABETIC_RETINAL_SCAN, ProductType.LAB_TEST],
    vertical: 'healthPlan',
    zendeskOrganizationName: 'Accompany Health - Michigan',
  },
  AFFORDABLEPATERNITY: {
    ...GLOBAL_PARTNER_DEFAULTS,
    defaultLabOrganization: LabOrganization.AFFORDABLE_PATERNITY,
    displayName: 'Affordable Paternity',
    isPartnerBookingAllowed: false,
    isSelfBookingAllowed: false,
    labOrganizations: {
      [LabOrganization.AFFORDABLE_PATERNITY]: [
        ServiceType.BLOOD,
        ServiceType.URINE,
        ServiceType.CAPILLARY_BLOOD_DRAW,
      ],
    },
    maxAllowedPatientsPerBooking: MAX_PATIENTS_PER_APPOINTMENT as number,
    onlyOrdersSingleServiceRequests: true,
    partnerOrganizationGroup: PartnerOrganizationGroup.AFFORDABLEPATERNITY,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportEmail: 'affordablednapaternitytesting@gmail.com',
    patientSupportPhoneE164: '+16502810610',
    payer: 'patient',
    supportedProductTypes: [ProductType.LAB_TEST],
    supportsReferrals: false as const,
    vertical: 'specialtyLab',
  },
  ALIGNMENTCAREEXTENSION: {
    ...GLOBAL_PARTNER_DEFAULTS,
    cobrandingLogo: {
      height: 28,
      uri: 'https://app.sprinterhealth.com/alignment/alignment_logo.png',
      width: 112,
    },
    defaultLabOrganization: LabOrganization.QUEST,
    displayName: 'Alignment Health Clinical Team',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider([
      'ahcusa.com',
      'sprinterhealth.com',
    ]),
    faxNumbersE164: ['+17146024885'],
    fullPartnerDisplayNameForMessaging: 'Alignment Health',
    insuranceUploadFromPartner: 'skip' as const,
    internalCoordinatorID: '25202a95-1cab-44f2-ade3-848ccc22ea0a' as const,
    isPartnerBookingAllowed: true as const,
    isReferralBookingAllowed: true as const,
    isSelfBookingAllowed: false as const,
    isTestResultsUploadRequired: true,
    labOrganizations: {
      [LabOrganization.QUEST]: [
        ServiceType.BLOOD,
        ServiceType.URINE,
        ServiceType.CAPILLARY_BLOOD_DRAW,
      ],
      [LabOrganization.LABCORP]: [
        ServiceType.BLOOD,
        ServiceType.URINE,
        ServiceType.CAPILLARY_BLOOD_DRAW,
      ],
      [LabOrganization.SPRINTER_HEALTH]: [
        ServiceType.CARE_NAVIGATION,
        ServiceType.ECG,
        ServiceType.VITALS,
      ],
    },
    maxAllowedPatientsPerBooking: 1 as const,
    onlyOrdersSingleServiceRequests: false as const,
    partnerOrganizationGroup: PartnerOrganizationGroup.ALIGNMENTHEALTH,
    partnerSupportResponsibleManager: 'Carole',
    patientBookingAppointmentPhoneE164: '+18334139745',
    patientConfig: PATIENT_CONFIG.ALIGNMENTCAREEXTENSION,
    patientSupportPhoneE164: '+18332422223' as const,
    payer: 'partner' as const,
    paymentType: 'claims' as const,
    priceUSD: makeDollar(79),
    referralCoordinatorID: '282d86dd-d1ca-4e76-a018-42b15ab9e37d' as const,
    requisitionUploadFromPartner: 'required_after_booking' as const,
    reschedulingCoordinator: 'partner' as const,
    serviceRequestsFromPartner: 'skip' as const,
    shortPartnerDisplayNameForMessaging: 'Alignment',
    shouldGenerateEncounterSummaryForVitals: true,
    shouldIncludeFailedAppointmentsInClaims: true,
    shouldSendAppointmentReportToCoordinator: true,
    skipCancelationFee: true as const,
    supportedProductTypes: [
      ProductType.CARE_NAVIGATION_DROP_IN,
      ProductType.LAB_TEST,
      ProductType.ECG,
      ProductType.VITALS,
    ],
    supportedServiceRegions: [...DEFAULT_SUPPORTED_REGIONS, ...ALIGNMENT_EXTRA_SERVICE_AREAS],
    supportsReferrals: true as const,
    vertical: 'healthPlan',
  },
  ALIGNMENTSTARS: {
    ...GLOBAL_PARTNER_DEFAULTS,
    allowedServiceRequests: {
      QUEST: ['496', '375', '6517'],
      SPRINTER_HEALTH: [
        'BLOOD-PRESSURE-CHECK',
        'DIABETIC-RETINAL-SCAN',
        'CONSULT-FECAL-OCCULT-BLOOD-TEST-KIT-DELIVERY',
      ],
    },
    appointmentReportEmails: ['stars@ahcusa.com'],
    cobrandingLogo: {
      height: 28,
      uri: 'https://app.sprinterhealth.com/alignment/alignment_logo.png',
      width: 112,
    },
    defaultLabOrganization: LabOrganization.QUEST,
    displayName: 'Alignment Health STARS Team',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider([
      'sprinterhealth.com',
      'ahcusa.com',
    ]),
    fullPartnerDisplayNameForMessaging: 'Alignment Health',
    insuranceUploadFromPartner: 'skip' as const,
    internalCoordinatorID: '1cb252a1-689d-4040-abbb-aae3a999d508' as const,
    isAutofillingRequisitionsAllowed: true,
    isPartnerBookingAllowed: true as const,
    isReferralBookingAllowed: true as const,
    isSelfBookingAllowed: false as const,
    isTestResultsUploadRequired: true,
    labOrganizations: {
      [LabOrganization.QUEST]: [
        ServiceType.BLOOD,
        ServiceType.URINE,
        ServiceType.CAPILLARY_BLOOD_DRAW,
      ],
      [LabOrganization.SPRINTER_HEALTH]: [
        ServiceType.KIT_DELIVERY,
        ServiceType.VITALS,
        ServiceType.DIABETIC_RETINAL_SCAN,
      ],
    },
    maxAllowedPatientsPerBooking: 1 as const,
    onlyOrdersSingleServiceRequests: false as const,
    partnerOrganizationGroup: PartnerOrganizationGroup.ALIGNMENTHEALTH,
    partnerSupportResponsibleManager: 'Carole',
    patientConfig: PATIENT_CONFIG.ALIGNMENTSTARS,
    patientSupportPhoneE164: '+18332422223' as const,
    payer: 'partner' as const,
    paymentType: 'claims' as const,
    priceUSD: makeDollar(79),
    referralCoordinatorID: 'b6fc2bcd-8234-47fb-bdfc-bf56e251c683' as const,
    referralLandingPageUrlPath: 'alignment-health',
    referralVerificationPageUrlPath: 'alignment-health',
    requisitionUploadFromPartner: 'skip' as const,
    reschedulingCoordinator: 'sprinterHealth' as const,
    serviceRequestsFromPartner: 'required' as const,
    shortPartnerDisplayNameForMessaging: 'Alignment',
    shouldGenerateEncounterSummaryForVitals: true,
    shouldIncludeFailedAppointmentsInClaims: true,
    showDiagnosisCodes: true,
    skipCancelationFee: true as const,
    sprinterHealthDefaultProductTypeMappingOverride: {
      [ProductType.VITALS]: {
        defaultSprinterHealthExternalID: 'BLOOD-PRESSURE-CHECK',
        displayName: 'Blood pressure collection',
        message: '',
      },
      [ProductType.KIT_DELIVERY]: {
        defaultSprinterHealthExternalID: 'CONSULT-FECAL-OCCULT-BLOOD-TEST-KIT-DELIVERY',
        displayName: 'Colorectal screening kit delivery',
        message: '',
      },
    },
    supportedProductTypes: [
      ProductType.LAB_TEST,
      ProductType.KIT_DELIVERY,
      ProductType.VITALS,
      ProductType.DIABETIC_RETINAL_SCAN,
    ],
    supportsEDILabOrdering: false,
    supportsReferrals: true as const,
    vertical: 'healthPlan',
  },
  ALLIANCEHOMEHEALTH: {
    ...STANDARD_HOME_HEALTH_PARTNER_BOOKING,
    displayName: 'Alliance Home Health',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['alliancehhllc.com']),
    partnerOrganizationGroup: PartnerOrganizationGroup.ALLIANCEHOMEHEALTH,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportEmail: 'clopez@alliancehhllc.com',
    patientSupportPhoneE164: '+16502852503',
    priceUSD: makeDollar(99),
  },
  ALPHACARE: {
    ...STANDARD_HOME_HEALTH_PARTNER_BOOKING,
    displayName: 'AlphaCare',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider([
      'alphacarehomehealth.com',
    ]),
    partnerOrganizationGroup: PartnerOrganizationGroup.ALPHACARE,
    partnerSupportResponsibleManager: 'Sengyeon',
  },
  AMOREHOMEHEALTH: {
    ...STANDARD_HOME_HEALTH_PARTNER_BOOKING,
    displayName: 'Amore Home Health',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['amorehomehealth.org']),
    partnerOrganizationGroup: PartnerOrganizationGroup.AMOREHOMEHEALTH,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportEmail: 'info@amorehomehealth.org',
    patientSupportPhoneE164: '+14086492370',
  },
  APOLLOHOMEHEALTH: {
    ...STANDARD_HOME_HEALTH_PARTNER_BOOKING,
    displayName: 'Apollo',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['apollohomehc.org']),
    partnerOrganizationGroup: PartnerOrganizationGroup.APOLLOHOMEHEALTH,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportEmail: 'info@apollohomehc.com',
    patientSupportPhoneE164: '+19255878244',
  },
  ASIANNETWORKPACIFICHOMECARE: {
    ...STANDARD_HOME_HEALTH_PARTNER_BOOKING,
    displayName: 'Asian Network Pacific Home Care',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['anphc.com']),
    partnerOrganizationGroup: PartnerOrganizationGroup.ASIANNETWORKPACIFICHOMECARE,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportEmail: 'asianet@sbcglobal.net',
    patientSupportPhoneE164: '+15102681118',
  },
  ASSISTONCALL: {
    ...STANDARD_HOME_HEALTH_PARTNER_BOOKING,
    displayName: 'Assist On Call',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['assistoncallprof.com']),
    partnerOrganizationGroup: PartnerOrganizationGroup.ASSISTONCALL,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportEmail: 'info@assistoncallprof.com',
    patientSupportPhoneE164: '+19259697634',
  },
  BESTHOMEHEALTH: {
    ...STANDARD_HOME_HEALTH_PARTNER_BOOKING,
    displayName: 'Best Home Health',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['besthhp.com']),
    partnerOrganizationGroup: PartnerOrganizationGroup.BESTHOMEHEALTH,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportEmail: 'info@besthhp.com',
    patientSupportPhoneE164: '+15107831274',
  },
  BMC: {
    ...BMC_BASE,
    defaultLabOrganization: LabOrganization.BMC_LAB,
    // TODO(dhruv): Rename BMC to BMCGeriatric
    // This is the Partner Org for the BMC Geriatric Department
    displayName: 'BMC Geriatrics Home Care',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['bmc.org']),
    ignoreAbstentionsMessaging: true,
    insuranceUploadFromPartner: 'optional',
    labOrganizations: {
      [LabOrganization.BMC_LAB]: [
        ServiceType.BLOOD,
        ServiceType.URINE,
        ServiceType.CAPILLARY_BLOOD_DRAW,
      ],
      [LabOrganization.SPRINTER_HEALTH]: [
        ServiceType.DIABETIC_RETINAL_SCAN,
        ServiceType.VITALS,
        ServiceType.FIT_KIT_PICKUP,
      ],
    },
    partnerSupportResponsibleManager: 'Sengyeon',
    patientConfig: PATIENT_CONFIG.BMC,
    patientSupportEmail: 'conceicao.montrond@bmc.org',
    requisitionUploadFromPartner: 'required_after_booking',
    skipCancelationFee: true,
  },
  BMCDEMO: {
    ...STANDARD_HEALTH_SYSTEM_PARTNER_BOOKING,
    automaticInvoiceSend: false,
    defaultLabOrganization: LabOrganization.QUEST,
    displayName: 'BMC Demo',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['sprinterhealth.com']),
    insuranceUploadFromPartner: 'skip',
    labOrganizations: {
      [LabOrganization.QUEST]: [
        ServiceType.BLOOD,
        ServiceType.URINE,
        ServiceType.CAPILLARY_BLOOD_DRAW,
      ],
    },
    partnerFeedbackLink: 'https://sprinterhealth.com/feedback_bmc',
    partnerOrganizationGroup: PartnerOrganizationGroup.BMCDEMO,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientConfig: PATIENT_CONFIG.BMC,
    paymentType: 'invoice' as const,
    requisitionUploadFromPartner: 'optional',
    shouldSendAppointmentReportToCoordinator: true,
    skipCancelationFee: true,
  },
  BMCEVANS: {
    ...BMC_BASE,
    canEditServicesAfterBooking: true,
    defaultLabOrganization: LabOrganization.BMC_LAB,
    displayName: 'BMC Adult Primary Care',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider([
      'bmc.org',
      'sprinterhealth.com',
    ]),
    ignoreAbstentionsMessaging: true,
    insuranceUploadFromPartner: 'skip',
    internalCoordinatorID: 'e6528c40-1335-40f3-bd15-004090ab9217',
    labOrganizations: {
      [LabOrganization.BMC_LAB]: [
        ServiceType.BLOOD,
        ServiceType.URINE,
        ServiceType.CAPILLARY_BLOOD_DRAW,
      ],
      [LabOrganization.SPRINTER_HEALTH]: [
        ServiceType.DIABETIC_RETINAL_SCAN,
        ServiceType.VITALS,
        ServiceType.FIT_KIT_PICKUP,
      ],
    },
    nurseVitalsUrgencyLineInformation: {
      phoneNumbers: [
        {
          extension: '9871',
          phoneE164: '+16174145951',
        },
      ],
    },
    partnerSupportResponsibleManager: 'Sengyeon',
    patientConfig: PATIENT_CONFIG.BMCEVANS,
    patientSupportEmail: 'conceicao.montrond@bmc.org',
    priceForProductsUSD: {
      DIABETIC_RETINAL_SCAN: makeDollar(129),
      FIT_KIT_PICKUP: makeDollar(59),
      LAB_TEST: makeDollar(79),
      VITALS: makeDollar(59),
    },
    requisitionUploadFromPartner: 'required_after_booking',
    shouldSendAppointmentReportToCoordinator: true,
    skipCancelationFee: true,
    supportedProductTypes: [
      ProductType.LAB_TEST,
      ProductType.DIABETIC_RETINAL_SCAN,
      ProductType.VITALS,
      ProductType.FIT_KIT_PICKUP,
    ],
    vitalsThresholds: VITALS_CONFIG.BMCEVANS,
  },
  BMCFAMILYMEDICINE: {
    ...BMC_BASE,
    canEditServicesAfterBooking: true,
    defaultLabOrganization: LabOrganization.BMC_LAB,
    displayName: 'BMC Family Medicine',
    faxNumbersE164: ['+18575046220'],
    ignoreAbstentionsMessaging: true,
    insuranceUploadFromPartner: 'skip',
    internalCoordinatorID: 'e97daa03-5d78-4f41-862b-84968111cf1c',
    labOrganizations: {
      [LabOrganization.BMC_LAB]: [
        ServiceType.BLOOD,
        ServiceType.URINE,
        ServiceType.CAPILLARY_BLOOD_DRAW,
      ],
      [LabOrganization.SPRINTER_HEALTH]: [
        ServiceType.DIABETIC_RETINAL_SCAN,
        ServiceType.VITALS,
        ServiceType.FIT_KIT_PICKUP,
      ],
    },
    nurseVitalsUrgencyLineInformation: {
      phoneNumbers: [
        {
          extension: '2076',
          phoneE164: '+18573010504',
        },
      ],
    },
    partnerSupportResponsibleManager: 'Sengyeon',
    patientConfig: PATIENT_CONFIG.BMCFAMILYMEDICINE,
    patientSupportEmail: 'conceicao.montrond@bmc.org',
    priceForProductsUSD: {
      DIABETIC_RETINAL_SCAN: makeDollar(129),
      FIT_KIT_PICKUP: makeDollar(59),
      LAB_TEST: makeDollar(79),
      VITALS: makeDollar(59),
    },
    requisitionUploadFromPartner: 'required_after_booking',
    shouldSendAppointmentReportToCoordinator: true,
    skipCancelationFee: true,
    supportedProductTypes: [
      ProductType.LAB_TEST,
      ProductType.DIABETIC_RETINAL_SCAN,
      ProductType.VITALS,
      ProductType.FIT_KIT_PICKUP,
    ],
    vitalsThresholds: VITALS_CONFIG.BMCFAMILYMEDICINE,
  },
  BRADFORDRABINMDANDHIROYUHATANOMD: {
    ...STANDARD_CONCIERGE_PARTNER_BOOKING,
    defaultLabOrganization: LabOrganization.QUEST,
    displayName: 'Bradford Rabin MD and Hiroyu Hatano MD',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['gmail.com']),
    insuranceUploadFromPatient: 'required',
    isMultiPatientBookingAllowed: false,
    isSelfBookingAllowed: true,
    labOrganizations: {
      [LabOrganization.QUEST]: [
        ServiceType.BLOOD,
        ServiceType.URINE,
        ServiceType.CAPILLARY_BLOOD_DRAW,
      ],
    },
    maxAllowedPatientsPerBooking: 1,
    partnerOrganizationGroup: PartnerOrganizationGroup.BRADFORDRABINMDANDHIROYUHATANOMD,
    partnerSupportResponsibleManager: 'Sengyeon',
    priceUSD: makeDollar(99),
    requisitionUploadFromPatient: 'required',
  },
  CAPITOLHEALTHCARE: {
    ...STANDARD_HOME_HEALTH_PARTNER_BOOKING,
    displayName: 'Capitol Healthcare',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider([
      'capitol-healthcare.com',
      'gmail.com',
    ]),
    partnerOrganizationGroup: PartnerOrganizationGroup.CAPITOLHEALTHCARE,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportEmail: 'info@capitol-healthcare.com',
    patientSupportPhoneE164: '+18883021215',
  },
  CAREINTOUCHHOMEHEALTHAGENCY: {
    ...STANDARD_HOME_HEALTH_PARTNER_BOOKING,
    displayName: 'Care In Touch Home Health Agency',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['careintouch.com']),
    partnerOrganizationGroup: PartnerOrganizationGroup.CAREINTOUCHHOMEHEALTHAGENCY,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportEmail: 'intake@careintouch.com',
    patientSupportPhoneE164: '+15106518500',
    priceUSD: makeDollar(99),
  },
  CAREMUST: {
    ...STANDARD_HOME_HEALTH_PARTNER_BOOKING,
    displayName: 'Care Must',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['caremust.com']),
    partnerOrganizationGroup: PartnerOrganizationGroup.CAREMUST,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportEmail: 'intake@caremust.com',
    patientSupportPhoneE164: '+14087551215',
  },
  CEDARSSINAI: {
    ...STANDARD_HEALTH_SYSTEM_PARTNER_BOOKING,
    defaultLabOrganization: LabOrganization.QUEST,
    displayName: 'Cedars-Sinai',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['cshs.org', 'csmns.org']),
    labOrganizations: {
      [LabOrganization.QUEST]: [
        ServiceType.BLOOD,
        ServiceType.URINE,
        ServiceType.CAPILLARY_BLOOD_DRAW,
      ],
    },
    partnerOrganizationGroup: PartnerOrganizationGroup.CEDARSSINAI,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientConfig: PATIENT_CONFIG.CEDARSSINAI,
    patientSupportPhoneE164: '+13102488311',
    paymentType: 'claims' as const,
    shouldIncludeFailedAppointmentsInClaims: true,
  },
  // Fields like fullPartnerDisplayNameForMessaging, cobranding will be applied at the brand level
  // and will be defined in the brand config
  // referralVerificationPageUrlPath isn't defined as we will just direct either via brand slug or just use the partner org
  CENTENECA: {
    ...CENTENE_BASE,
    displayName: 'Centene California',
    internalCoordinatorID: '9f4db1ff-cb30-4ace-9110-9813bcb43041',
    patientConfig: PATIENT_CONFIG.CENTENECA,
    referralCoordinatorID: '6003cc1a-2410-447a-8e6e-afd8a63cd3a2',
    zendeskOrganizationName: 'Centene - California',
  },
  CENTENECT: {
    ...CENTENE_BASE,
    allowedServiceRequests: {
      ...CENTENE_BASE.allowedServiceRequests,
      QUEST: [], // Labs isn't setup as we don't have insurance info yet
    },
    displayName: 'Centene Connecticut',
    internalCoordinatorID: 'e57c7eb5-c658-4b0d-8a62-e0de0be40aef',
    isAutofillingRequisitionsAllowed: false,
    patientConfig: PATIENT_CONFIG.CENTENECT,
    referralCoordinatorID: '10e60a21-d8bf-4ff4-b6b9-570f6e7aaceb',
    serviceRequestsAddByDefault: {
      [LabOrganization.SPRINTER_HEALTH]: [
        {
          externalID: 'RACE-AND-ETHNICITY-QUESTIONNAIRE',
          serviceSuccessfullyProvidedCutoffPeriod: 'lifetime',
        },
      ],
    },
    supportedProductTypes: [
      // We don't have insurance info yet, so we can't support LAB_TEST
      ProductType.VITALS,
      ProductType.KIT_DELIVERY_AND_EDUCATION,
      ProductType.DIABETIC_RETINAL_SCAN,
      ProductType.QUESTIONNAIRES,
    ],
    supportsEDILabOrdering: false,
    zendeskOrganizationName: 'Centene - Connecticut',
  },
  CENTENEFL: {
    ...CENTENE_BASE,
    allowedServiceRequests: {
      ...CENTENE_BASE.allowedServiceRequests,
      QUEST: [], // Labs isn't setup as we don't have insurance info yet
    },
    displayName: 'Centene Florida',
    internalCoordinatorID: 'a7597a10-737b-496b-8e2d-d6102cbc39c3',
    isAutofillingRequisitionsAllowed: false,
    patientConfig: PATIENT_CONFIG.CENTENEFL,
    referralCoordinatorID: 'd37aadd3-e490-420d-bbd3-d43c1472b21b',
    supportedProductTypes: [
      // We don't have insurance info yet, so we can't support LAB_TEST
      ProductType.VITALS,
      ProductType.KIT_DELIVERY_AND_EDUCATION,
      ProductType.DIABETIC_RETINAL_SCAN,
      ProductType.QUESTIONNAIRES,
    ],
    supportsEDILabOrdering: false,
    zendeskOrganizationName: 'Centene - Florida',
  },
  CENTENEGA: {
    ...CENTENE_BASE,
    displayName: 'Centene Georgia',
    internalCoordinatorID: '94245058-b78b-47d6-ad36-786697126ccd',
    patientConfig: PATIENT_CONFIG.CENTENEGA,
    referralCoordinatorID: '0c753b05-13bd-4c5d-a1a4-fec4837e7294',
    zendeskOrganizationName: 'Centene - Georgia',
  },
  CENTENEIN: {
    ...CENTENE_BASE,
    allowedServiceRequests: {
      ...CENTENE_BASE.allowedServiceRequests,
      QUEST: [], // Labs isn't setup as we don't have insurance info yet
    },
    displayName: 'Centene Indiana',
    internalCoordinatorID: 'bfed8d8e-9838-4516-9885-1bb22587cccd',
    isAutofillingRequisitionsAllowed: false,
    patientConfig: PATIENT_CONFIG.CENTENEIN,
    referralCoordinatorID: '5771bc30-1c61-4629-a4a2-cb0588cdaca8',
    serviceRequestsAddByDefault: {
      [LabOrganization.SPRINTER_HEALTH]: [
        {
          externalID: 'RACE-AND-ETHNICITY-QUESTIONNAIRE',
          serviceSuccessfullyProvidedCutoffPeriod: 'lifetime',
        },
      ],
    },
    supportedProductTypes: [
      // We don't have insurance info yet, so we can't support LAB_TEST
      ProductType.VITALS,
      ProductType.KIT_DELIVERY_AND_EDUCATION,
      ProductType.DIABETIC_RETINAL_SCAN,
      ProductType.QUESTIONNAIRES,
    ],
    supportsEDILabOrdering: false,
    zendeskOrganizationName: 'Centene - Indiana',
  },
  CENTENEKY: {
    ...CENTENE_BASE,
    allowedServiceRequests: {
      QUEST: [
        '375', // eGFR/Creatinine
        '496', //  Quest A1C
        '6517', // uACR/Microalbumin
        '8293', // Direct LDL/Cholesterol
      ],
      SPRINTER_HEALTH: [
        'BLOOD-PRESSURE-CHECK',
        'DIABETIC-RETINAL-SCAN',
        'RACE-AND-ETHNICITY-QUESTIONNAIRE',
        'SDOH-QUESTIONNAIRE',
        'INSURE-ONE-FIT-KIT-DELIVERY-AND-EDUCATION',
        'MAMMOGRAM_CARE_NAVIGATION_BOOKING',
        'MAMMOGRAM_CARE_NAVIGATION_CONFIRMATION',
        'PAIN_SCREENING',
        'WEIGHT_COUNSELING_FOR_CHILDREN',
      ],
    },
    careNavigationEpisodeSummaryReportingModel: 'none',
    displayName: 'Centene Kentucky',
    internalCoordinatorID: '2b8a8aea-3a52-44ad-9c8c-ff87eb056437',
    labOrganizations: {
      ...CENTENE_BASE.labOrganizations,
      [LabOrganization.SPRINTER_HEALTH]: [
        ServiceType.VITALS,
        ServiceType.DIABETIC_RETINAL_SCAN,
        ServiceType.MAMMOGRAM_CARE_NAVIGATION_BOOKING,
        ServiceType.MAMMOGRAM_CARE_NAVIGATION_CONFIRMATION,
        ServiceType.KIT_DELIVERY_AND_EDUCATION,
        ServiceType.RACE_AND_ETHNICITY,
        ServiceType.SDOH_QUESTIONNAIRE,
        ServiceType.PAIN_SCREENING,
        ServiceType.WEIGHT_COUNSELING_FOR_CHILDREN,
      ],
    },
    patientConfig: PATIENT_CONFIG.CENTENEKY,
    priceForProductsUSD: {
      ...CENTENE_BASE.priceForProductsUSD,
      MAMMOGRAM_CARE_NAVIGATION_BOOKING: makeDollar(135),
      MAMMOGRAM_CARE_NAVIGATION_CONFIRMATION: makeDollar(0),
    },
    referralCoordinatorID: 'f943b9e4-2485-4437-9379-b6d393d12564',
    serviceRequestsAddByDefault: {
      SPRINTER_HEALTH: [
        {
          exclusionEquivalentServiceRequestTypes: [
            {
              externalIDs: new Set(['WEIGHT_COUNSELING_FOR_CHILDREN']),
              labOrganization: LabOrganization.SPRINTER_HEALTH,
            },
          ],
          externalID: 'RACE-AND-ETHNICITY-QUESTIONNAIRE',
          serviceSuccessfullyProvidedCutoffPeriod: 'lifetime' as const,
        },
        {
          exclusionEquivalentServiceRequestTypes: [
            {
              externalIDs: new Set(['WEIGHT_COUNSELING_FOR_CHILDREN']),
              labOrganization: LabOrganization.SPRINTER_HEALTH,
            },
          ],
          externalID: 'SDOH-QUESTIONNAIRE',
          serviceSuccessfullyProvidedCutoffPeriod: 'ytd' as const,
        },
      ],
    },
    supportedProductTypes: [
      ...CENTENE_BASE.supportedProductTypes,
      ProductType.MAMMOGRAM_CARE_NAVIGATION_BOOKING,
      ProductType.MAMMOGRAM_CARE_NAVIGATION_CONFIRMATION,
      ProductType.PAIN_SCREENING,
      ProductType.WEIGHT_COUNSELING_FOR_CHILDREN,
    ],
    zendeskOrganizationName: 'Centene - Kentucky',
  },
  CENTENEMA: {
    ...CENTENE_BASE,
    displayName: 'Centene Massachusetts',
    internalCoordinatorID: 'bdb29c91-5fbe-4799-8578-6918fa75a79e',
    patientConfig: PATIENT_CONFIG.CENTENEMA,
    referralCoordinatorID: 'c971aef4-e72f-43db-bd67-cebef136c365',
    zendeskOrganizationName: 'Centene - Massachusetts',
  },
  CENTENEME: {
    ...CENTENE_BASE,
    displayName: 'Centene Maine',
    internalCoordinatorID: '8a69c684-0dcf-4d08-91c2-001799811e83',
    patientConfig: PATIENT_CONFIG.CENTENEME,
    referralCoordinatorID: '2672fb38-3831-4516-a9b3-3c4af72a2424',
    serviceRequestsAddByDefault: {
      [LabOrganization.SPRINTER_HEALTH]: [
        {
          externalID: 'RACE-AND-ETHNICITY-QUESTIONNAIRE',
          serviceSuccessfullyProvidedCutoffPeriod: 'lifetime',
        },
      ],
    },
    zendeskOrganizationName: 'Centene - Maine',
  },
  CENTENEMI: {
    ...CENTENE_BASE,
    displayName: 'Centene Michigan',
    internalCoordinatorID: '8b574147-eb51-479e-86f9-8581c682213c',
    patientConfig: PATIENT_CONFIG.CENTENEMI,
    referralCoordinatorID: 'e1da13ba-50eb-4183-afff-0b3ceed67669',
    serviceRequestsAddByDefault: {
      [LabOrganization.SPRINTER_HEALTH]: [
        {
          externalID: 'RACE-AND-ETHNICITY-QUESTIONNAIRE',
          serviceSuccessfullyProvidedCutoffPeriod: 'lifetime',
        },
        {
          externalID: 'SDOH-QUESTIONNAIRE',
          prerequisiteCampaign:
            CampaignName.CENTENE_MICHIGAN_DUAL_MEDICAID_MEDICARE_INITIAL_ENGAGEMENT_2024,
          serviceSuccessfullyProvidedCutoffPeriod: 'ytd',
        },
      ],
    },
    zendeskOrganizationName: 'Centene - Michigan',
  },
  CENTENENH: {
    ...CENTENE_BASE,
    displayName: 'Centene New Hampshire',
    internalCoordinatorID: 'e9cd4815-982c-4c8a-a177-81256985d098',
    patientConfig: PATIENT_CONFIG.CENTENENH,
    referralCoordinatorID: '20ee2007-7ba6-418d-8fa7-a6026e40aaba',
    zendeskOrganizationName: 'Centene - New Hampshire',
  },
  CENTENEOH: {
    // Centene Ohio supports Cologuard, which requires overrides on a few fields below.
    ...CENTENE_BASE,
    allowedServiceRequests: {
      QUEST: [
        '375', // eGFR/Creatinine
        '496', //  Quest A1C
        '6517', // uACR/Microalbumin
      ],
      SPRINTER_HEALTH: [
        'BLOOD-PRESSURE-CHECK',
        'DIABETIC-RETINAL-SCAN',
        'RACE-AND-ETHNICITY-QUESTIONNAIRE',
        'SDOH-QUESTIONNAIRE',
        'COLOGUARD-KIT-EDUCATION', // Cologuard Kit Education externalID
        'INSURE-ONE-FIT-KIT-DELIVERY-AND-EDUCATION', // If a patient fails contraindications for Cologuard, they will get FIT Kit
      ],
    },
    displayName: 'Centene Ohio',
    internalCoordinatorID: 'ca4656e0-da34-4940-987d-f34ffaad9173',
    labOrganizations: {
      [LabOrganization.QUEST]: [ServiceType.BLOOD, ServiceType.URINE],
      [LabOrganization.SPRINTER_HEALTH]: [
        ServiceType.VITALS,
        ServiceType.DIABETIC_RETINAL_SCAN,
        ServiceType.RACE_AND_ETHNICITY,
        ServiceType.SDOH_QUESTIONNAIRE,
        ServiceType.COLOGUARD_KIT_EDUCATION,
        ServiceType.KIT_DELIVERY_AND_EDUCATION,
      ],
    },
    patientConfig: PATIENT_CONFIG.CENTENEOH,
    referralCoordinatorID: '47f7fe85-5e28-4205-a14e-d77144381f0e',
    serviceRequestsAddByDefault: {
      [LabOrganization.SPRINTER_HEALTH]: [
        {
          externalID: 'RACE-AND-ETHNICITY-QUESTIONNAIRE',
          serviceSuccessfullyProvidedCutoffPeriod: 'lifetime',
        },
        {
          externalID: 'SDOH-QUESTIONNAIRE',
          prerequisiteCampaign: CampaignName.CENTENE_OHIO_MEDICAID_INITIAL_ENGAGEMENT_2024,
          serviceSuccessfullyProvidedCutoffPeriod: 'ytd',
        },
      ],
    },
    sprinterHealthDefaultProductTypeMappingOverride: {
      [ProductType.VITALS]: {
        defaultSprinterHealthExternalID: 'BLOOD-PRESSURE-CHECK',
        displayName: 'Blood pressure collection',
        message: '',
      },
    },
    supportedProductTypes: [
      ProductType.LAB_TEST,
      ProductType.VITALS,
      ProductType.COLOGUARD_KIT_EDUCATION,
      ProductType.KIT_DELIVERY_AND_EDUCATION,
      ProductType.DIABETIC_RETINAL_SCAN,
      ProductType.QUESTIONNAIRES,
    ],
    zendeskOrganizationName: 'Centene - Ohio',
  },
  CENTENETX: {
    ...CENTENE_BASE,
    allowedServiceRequests: {
      ...CENTENE_BASE.allowedServiceRequests,
      QUEST: [], // Labs isn't setup as we don't have insurance info yet
    },
    displayName: 'Centene Texas',
    internalCoordinatorID: '87d60510-c34c-41f7-a3cf-de4ef3a94559',
    isAutofillingRequisitionsAllowed: false,
    patientConfig: PATIENT_CONFIG.CENTENETX,
    referralCoordinatorID: '52bb560c-311a-4dd8-985f-de9bde0331bb',
    serviceRequestsAddByDefault: {
      [LabOrganization.SPRINTER_HEALTH]: [
        {
          externalID: 'RACE-AND-ETHNICITY-QUESTIONNAIRE',
          serviceSuccessfullyProvidedCutoffPeriod: 'lifetime',
        },
      ],
    },
    supportedProductTypes: [
      // We don't have insurance info yet, so we can't support LAB_TEST
      ProductType.VITALS,
      ProductType.KIT_DELIVERY_AND_EDUCATION,
      ProductType.DIABETIC_RETINAL_SCAN,
      ProductType.QUESTIONNAIRES,
    ],
    supportsEDILabOrdering: false,
    zendeskOrganizationName: 'Centene - Texas',
  },
  CENTRALCALIFORNIAALLIANCEFORHEALTH: {
    ...GLOBAL_PARTNER_DEFAULTS,
    allowedServiceRequests: {
      QUEST: [
        '496', // Quest A1C
        '39027', // QUest lead screening (capillary)
      ],
      /**
       * TODO(engagement): there are still services that need to be updated or added
       * - remove the questionnaires and use prepare instead (if we are using prepare)
       * - kit for colorectal cancer screening
       * - other quest labs
       * - care nav services which is not launch blocking
       */
      SPRINTER_HEALTH: [
        'RACE-AND-ETHNICITY-QUESTIONNAIRE',
        'SDOH-QUESTIONNAIRE',
        'BLOOD-PRESSURE-CHECK',
        'DIABETIC-RETINAL-SCAN',
        'TOPICAL_FLUORIDE',
        'WEIGHT_COUNSELING_FOR_CHILDREN',
      ],
    },
    appointmentReportEmails: [], // TODO(engagement): add emails once we have them
    blockSharedSlotAppointments: false,
    canEditServicesAfterBooking: false,
    cobrandingLogo: {
      // TODO(engagement): add cobranding logo
      height: 0,
      uri: '',
      width: 0,
    },
    defaultLabOrganization: LabOrganization.QUEST,
    displayName: 'Central California Alliance for Health',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['@thealliance.health']),
    excludeBillingForFailedAppointments: true,
    fullPartnerDisplayNameForMessaging: 'Central California Alliance for Health',
    insuranceUploadFromPartner: 'skip',
    internalCoordinatorID: '', // TODO(engagement): add internal coordinator ID
    isAutofillingRequisitionsAllowed: true as const,
    isGuestReschedulingAllowed: true as const,
    isPartnerBookingAllowed: true as const,
    isReferralBookingAllowed: true as const,
    isSelfBookingAllowed: false as const,
    isTestResultsUploadRequired: true,
    labOrganizations: {
      [LabOrganization.QUEST]: [ServiceType.BLOOD, ServiceType.CAPILLARY_BLOOD_DRAW], // TODO(engagement): add quest service types
      [LabOrganization.SPRINTER_HEALTH]: [
        ServiceType.VITALS,
        ServiceType.DIABETIC_RETINAL_SCAN,
        ServiceType.TOPICAL_FLUORIDE,
        ServiceType.SDOH_QUESTIONNAIRE, // TODO(engagement): we might use prepare instead, but that is TBA
        ServiceType.WEIGHT_COUNSELING_FOR_CHILDREN,
      ],
    },
    maxAllowedPatientsPerBooking: 1 as const,
    onlyOrdersSingleServiceRequests: false as const,
    partnerOrganizationGroup: PartnerOrganizationGroup.CENTRALCALIFORNIAALLIANCEFORHEALTH,
    partnerSupportResponsibleManager: 'Madi', // TODO(engagement): add Miranda's profile
    patientSupportEmail: '', // TODO(engagement): add patient support email
    patientSupportPhoneE164: '+1', // TODO(engagement): add patient support phone
    payer: 'partner',
    paymentType: 'claims',
    priceUSD: makeDollar(149),
    productTypesRequiringRequisitions: [ProductType.LAB_TEST],
    referralCoordinatorID: '', // TODO(engagement): add referral coordinator ID
    referralLandingPageUrlPath: 'ccah', // TODO(engagement): remove the referral landing page in subsequent PR
    referralVerificationPageUrlPath: 'ccah',
    requisitionUploadFromPartner: 'skip',
    reschedulingCoordinator: 'sprinterHealth',
    serviceRequestsAddByDefault: {
      SPRINTER_HEALTH: [
        {
          externalID: 'SDOH-QUESTIONNAIRE', // TODO(engagement): adding these for now, but we might need to remove these as default services
          serviceSuccessfullyProvidedCutoffPeriod: 'ytd' as const,
        },
        {
          externalID: 'RACE-AND-ETHNICITY-QUESTIONNAIRE',
          serviceSuccessfullyProvidedCutoffPeriod: 'ytd' as const,
        },
      ],
    },
    serviceRequestsFromPartner: 'required',
    shortPartnerDisplayNameForMessaging: 'CCAH',
    shouldExportAppointmentsForBilling: true,
    shouldGenerateEncounterSummaryForVitals: true,
    shouldIncludeFailedAppointmentsInClaims: false,
    showDiagnosisCodes: true,
    skipCancelationFee: true,
    sprinterHealthDefaultProductTypeMappingOverride: {
      [ProductType.VITALS]: {
        defaultSprinterHealthExternalID: 'BLOOD-PRESSURE-CHECK',
        displayName: 'Blood pressure collection',
        message: '',
      },
    },
    supportedProductTypes: [
      ProductType.LAB_TEST,
      ProductType.VITALS,
      ProductType.DIABETIC_RETINAL_SCAN,
      ProductType.TOPICAL_FLUORIDE,
      ProductType.QUESTIONNAIRES,
      ProductType.WEIGHT_COUNSELING_FOR_CHILDREN,
    ],
    supportsReferrals: true,
    vertical: 'healthPlan',
  },
  CHARLESRIVERFAMILYMEDICINE: {
    ...BMC_BASE,
    canEditServicesAfterBooking: true,
    defaultLabOrganization: LabOrganization.BMC_LAB,
    displayName: 'BMC Charles River Family Medicine',
    insuranceUploadFromPartner: 'skip',
    internalCoordinatorID: '5630a386-2c0c-496c-93fd-b40933cc3c8b',
    labOrganizations: {
      [LabOrganization.BMC_LAB]: [
        ServiceType.BLOOD,
        ServiceType.URINE,
        ServiceType.CAPILLARY_BLOOD_DRAW,
      ],
      [LabOrganization.SPRINTER_HEALTH]: [
        ServiceType.DIABETIC_RETINAL_SCAN,
        ServiceType.VITALS,
        ServiceType.FIT_KIT_PICKUP,
      ],
    },
    nurseVitalsUrgencyLineInformation: {
      phoneNumbers: [
        {
          phoneE164: '+16174149660',
        },
      ],
    },
    partnerSupportResponsibleManager: 'Sengyeon',
    patientConfig: PATIENT_CONFIG.CHARLESRIVERFAMILYMEDICINE,
    patientSupportPhoneE164: '+16176385829' as const,
    requisitionUploadFromPartner: 'required_after_booking',
    skipCancelationFee: true,
    supportedProductTypes: [
      ProductType.VITALS,
      ProductType.FIT_KIT_PICKUP,
      ProductType.DIABETIC_RETINAL_SCAN,
      ProductType.LAB_TEST,
    ],
  },
  CODMAN: {
    ...BMC_BASE,
    canEditServicesAfterBooking: true,
    defaultLabOrganization: LabOrganization.CODMAN_LAB,
    displayName: 'Codman',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider([
      'codman.org',
      'sprinterhealth.com',
    ]),
    ignoreAbstentionsMessaging: true,
    insuranceUploadFromPartner: 'skip',
    internalCoordinatorID: '05098ab6-fc19-4389-b794-c873b67a92f7',
    labOrganizations: {
      [LabOrganization.CODMAN_LAB]: [
        ServiceType.BLOOD,
        ServiceType.URINE,
        ServiceType.CAPILLARY_BLOOD_DRAW,
      ],
      [LabOrganization.SPRINTER_HEALTH]: [
        ServiceType.DIABETIC_RETINAL_SCAN,
        ServiceType.VITALS,
        ServiceType.FIT_KIT_PICKUP,
      ],
    },
    partnerSupportResponsibleManager: 'Sengyeon',
    patientConfig: PATIENT_CONFIG.CODMAN,
    patientSupportPhoneE164: '+16178228189',
    priceForProductsUSD: {
      DIABETIC_RETINAL_SCAN: makeDollar(129),
      FIT_KIT_PICKUP: makeDollar(59),
      LAB_TEST: makeDollar(79),
      VITALS: makeDollar(59),
    },
    requisitionUploadFromPartner: 'required_after_booking',
    shouldSendAppointmentReportToCoordinator: true,
    skipCancelationFee: true,
    supportedProductTypes: [
      ProductType.LAB_TEST,
      ProductType.DIABETIC_RETINAL_SCAN,
      ProductType.VITALS,
      ProductType.FIT_KIT_PICKUP,
    ],
    vitalsThresholds: VITALS_CONFIG.CODMAN,
  },
  COMFORTHOMEHEALTHCARE: {
    ...STANDARD_HOME_HEALTH_PARTNER_BOOKING,
    displayName: 'Comfort Home Health Care',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider([
      'yahoo.com',
      'gmail.com',
    ]),
    partnerOrganizationGroup: PartnerOrganizationGroup.COMFORTHOMEHEALTHCARE,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportEmail: 'comforthomehealth2021@yahoo.com',
    patientSupportPhoneE164: '+14084525927',
  },
  CONCIERGEMDLA: {
    ...STANDARD_CONCIERGE_PARTNER_BOOKING,
    displayName: 'Concierge MD LA',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['conciergemdla.com']),
    invoiceEmailType: 'custom' as const,
    maxAllowedPatientsPerBooking: 1,
    partnerOrganizationGroup: PartnerOrganizationGroup.CONCIERGEMDLA,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportEmail: 'operations@conciergemdla.com',
    patientSupportPhoneE164: '+13239314662',
    priceUSD: makeDollar(99),
  },
  DEMOLAB: {
    ...STANDARD_SPECIALTY_LAB_PARTNER_BOOKING,
    defaultLabOrganization: LabOrganization.DEMO_LAB,
    displayName: 'Widget Labs',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider([]), // since this is a test org
    labOrganizations: {
      [LabOrganization.DEMO_LAB]: [
        ServiceType.BLOOD,
        ServiceType.URINE,
        ServiceType.CAPILLARY_BLOOD_DRAW,
      ],
    },
    partnerOrganizationGroup: PartnerOrganizationGroup.DEMOLAB,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportEmail: 'support@widgetlabs.com',
    patientSupportPhoneE164: '+15551234567',
  },
  DEMOPROVIDER: {
    ...STANDARD_HOME_HEALTH_PARTNER_BOOKING,
    displayName: 'Widget MD',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider([]), // since this is a test org
    insuranceUploadFromPartner: 'optional',
    partnerOrganizationGroup: PartnerOrganizationGroup.DEMOPROVIDER,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportEmail: 'support@widgetmd.com',
    patientSupportPhoneE164: '+15551234567',
    requisitionUploadFromPartner: 'optional',
  },
  DEVOTEDHEALTH: {
    ...GLOBAL_PARTNER_DEFAULTS,
    automaticInvoiceSend: false,
    canEditServicesAfterBooking: true,
    defaultLabOrganization: LabOrganization.QUEST,
    displayName: 'Devoted Health',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['devoted.com']),
    encounterSummaryReportingModel: 'partner',
    faxNumbersE164: ['+18665772322'],
    insuranceUploadFromPartner: 'skip',
    invoiceEmailType: 'stripe',
    invoiceTerm: 60,
    isAutofillingRequisitionsAllowed: false,
    isGuestReschedulingAllowed: true,
    isPartnerBookingAllowed: true,
    isReferralBookingAllowed: false,
    isSelfBookingAllowed: false,
    isTestResultsUploadRequired: true,
    labOrganizations: {
      [LabOrganization.QUEST]: [ServiceType.BLOOD, ServiceType.URINE],
      [LabOrganization.SPRINTER_HEALTH]: [ServiceType.DIABETIC_RETINAL_SCAN],
    },
    maxAllowedPatientsPerBooking: 2,
    onlyOrdersSingleServiceRequests: false,
    partnerOrganizationGroup: PartnerOrganizationGroup.DEVOTEDHEALTH,
    partnerSupportResponsibleManager: 'Madi' as PartnerResponsibleManager,
    patientConfig: PATIENT_CONFIG.DEVOTEDHEALTH,
    patientSupportPhoneE164: '+18888022738',
    payer: 'partner',
    paymentType: 'invoice',
    priceForProductsUSD: {
      COLOGUARD_KIT_EDUCATION: makeDollar(20),
      DIABETIC_RETINAL_SCAN: makeDollar(50),
      LAB_TEST: makeDollar(20),
      VITALS: makeDollar(10), // BP check
    },
    priceUSD: makeDollar(130),
    productTypesRequiringRequisitions: [ProductType.LAB_TEST],
    requisitionUploadFromPartner: 'required',
    reschedulingCoordinator: 'sprinterHealth',
    serviceRequestsFromPartner: 'skip',
    shortPartnerDisplayNameForMessaging: 'Devoted',
    shouldExportAppointmentsForBilling: true,
    shouldSkipPriorLeadScreeningsQuestion: true,
    showDiagnosisCodes: false,
    skipCancelationFee: false,
    supportedProductTypes: [ProductType.LAB_TEST, ProductType.DIABETIC_RETINAL_SCAN],
    supportsPatientEncounterSummary: false,
    vertical: 'healthPlan',
    zendeskOrganizationName: 'Devoted Health',
  },
  DISCOVERHEALTH: {
    ...STANDARD_CONCIERGE_PARTNER_BOOKING,
    displayName: 'Discover Health MD',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['discoverhealthmd.com']),
    partnerOrganizationGroup: PartnerOrganizationGroup.DISCOVERHEALTH,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportEmail: 'office@discoverhealthmd.com',
    patientSupportPhoneE164: '+14157327029',
    priceUSD: makeDollar(99),
    requisitionUploadFromPartner: 'required_after_booking',
    skipCancelationFee: true,
    supportedProductTypes: [ProductType.LAB_TEST],
    vitalsThresholds: VITALS_CONFIG.DISCOVERHEALTH,
  },
  DOTHOUSE: {
    ...BMC_BASE,
    canEditServicesAfterBooking: true,
    defaultLabOrganization: LabOrganization.DOTHOUSE_LAB,
    displayName: 'Dothouse',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider([
      'dothousehealth.org',
      'sprinterhealth.com',
    ]),
    ignoreAbstentionsMessaging: true,
    insuranceUploadFromPartner: 'skip',
    internalCoordinatorID: '5205534e-3874-465e-aaa2-71f7ecf1cc93',
    labOrganizations: {
      [LabOrganization.DOTHOUSE_LAB]: [
        ServiceType.BLOOD,
        ServiceType.URINE,
        ServiceType.CAPILLARY_BLOOD_DRAW,
      ],
      [LabOrganization.SPRINTER_HEALTH]: [
        ServiceType.DIABETIC_RETINAL_SCAN,
        ServiceType.VITALS,
        ServiceType.FIT_KIT_PICKUP,
      ],
    },
    nurseVitalsUrgencyLineInformation: {
      phoneNumbers: [
        {
          extension: '6286',
          phoneE164: '+16172883230',
        },
      ],
    },
    partnerSupportResponsibleManager: 'Sengyeon',
    patientConfig: PATIENT_CONFIG.DOTHOUSE,
    priceForProductsUSD: {
      DIABETIC_RETINAL_SCAN: makeDollar(129),
      FIT_KIT_PICKUP: makeDollar(59),
      LAB_TEST: makeDollar(79),
      VITALS: makeDollar(59),
    },
    requisitionUploadFromPartner: 'required_after_booking',
    skipCancelationFee: true,
    supportedProductTypes: [
      ProductType.LAB_TEST,
      ProductType.DIABETIC_RETINAL_SCAN,
      ProductType.VITALS,
      ProductType.FIT_KIT_PICKUP,
    ],
  },
  DRIPHYDRATION: {
    ...STANDARD_CONCIERGE_PARTNER_BOOKING,
    defaultLabOrganization: LabOrganization.QUEST,
    displayName: 'Drip Hydration',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider([
      'driphydration.com',
      'dripecm.com',
    ]),
    labOrganizations: {
      [LabOrganization.QUEST]: [
        ServiceType.BLOOD,
        ServiceType.URINE,
        ServiceType.CAPILLARY_BLOOD_DRAW,
      ],
      [LabOrganization.LABCORP]: [
        ServiceType.BLOOD,
        ServiceType.URINE,
        ServiceType.CAPILLARY_BLOOD_DRAW,
      ],
    },
    partnerOrganizationGroup: PartnerOrganizationGroup.DRIPHYDRATION,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportEmail: 'dispatch@dripecm.com',
    patientSupportPhoneE164: '+17867789641',
  },
  DRIPHYDRATIONPALOALTO: {
    ...STANDARD_CONCIERGE_PARTNER_BOOKING,
    displayName: 'Drip Hydration',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider([
      'driphydration.com',
      'gmail.com',
      'icloud.com',
    ]),
    partnerOrganizationGroup: PartnerOrganizationGroup.DRIPHYDRATION,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportEmail: 'eric@driphydration.com',
    patientSupportPhoneE164: '+16503915465',
  },
  DRVILLIVALAM: {
    ...GLOBAL_PARTNER_DEFAULTS,
    defaultLabOrganization: STANDARD_REFERENCE_LAB_ORGANIZATION,
    displayName: 'Dr. Villivalam',
    insuranceUploadFromPatient: 'required',
    isMultiPatientBookingAllowed: true,
    isPartnerBookingAllowed: false,
    isSelfBookingAllowed: true,
    labOrganizations: STANDARD_REFERENCE_LAB_ORGANIZATION_SETTINGS,
    maxAllowedPatientsPerBooking: MAX_PATIENTS_PER_APPOINTMENT as number,
    onlyOrdersSingleServiceRequests: false,
    partnerOrganizationGroup: PartnerOrganizationGroup.DRVILLIVALAM,
    partnerSupportResponsibleManager: 'Sengyeon',
    payer: 'patient',
    requisitionUploadFromPatient: 'required',
    supportedProductTypes: [ProductType.LAB_TEST],
    vertical: 'drVillivalam',
  },
  ELDERCAREMANAGEMENT: {
    ...STANDARD_HEALTH_SYSTEM_PARTNER_BOOKING,
    defaultLabOrganization: LabOrganization.QUEST,
    displayName: 'Elder Care Management',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider([
      'eldercm.com',
      'elderm.com',
    ]),
    labOrganizations: {
      [LabOrganization.QUEST]: [
        ServiceType.BLOOD,
        ServiceType.URINE,
        ServiceType.CAPILLARY_BLOOD_DRAW,
      ],
      [LabOrganization.LABCORP]: [
        ServiceType.BLOOD,
        ServiceType.URINE,
        ServiceType.CAPILLARY_BLOOD_DRAW,
      ],
    },
    partnerOrganizationGroup: PartnerOrganizationGroup.ELDERCAREMANAGEMENT,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportEmail: 'info@eldercm.com',
    patientSupportPhoneE164: '+19162064420',
  },
  // Fields like fullPartnerDisplayNameForMessaging are applied at the brand level
  // and are defined in the brand config
  ELEVANCEHEALTH: {
    ...GLOBAL_PARTNER_DEFAULTS,
    allowedServiceRequests: {
      QUEST: [
        '375', // eGFR/Creatinine
        '496', //  Quest A1C
        '6517', // uACR/Microalbumin
      ],
      SPRINTER_HEALTH: [
        'ANNUAL_WELLNESS_VISIT_PROVIDER_CALL',
        'BLOOD-PRESSURE-CHECK',
        'DEXA_SCAN_CARE_NAVIGATION_BOOKING',
        'DEXA_SCAN_CARE_NAVIGATION_CONFIRMATION',
        'DIABETIC-RETINAL-SCAN',
        'HEIGHT_AND_WEIGHT_COLLECTION',
        'INSURE-ONE-FIT-KIT-DELIVERY-AND-EDUCATION',
        'MAMMOGRAM_CARE_NAVIGATION_BOOKING',
        'MAMMOGRAM_CARE_NAVIGATION_CONFIRMATION',
        'MINI_OMNI_BONE_DENSITY_SCAN',
        'PCP_VISIT_CARE_NAVIGATION_BOOKING',
        'PCP_VISIT_CARE_NAVIGATION_CONFIRMATION',
        'PRAPARE_SDOH_CARE_NAVIGATION_RESOURCE_GUIDANCE',
        'STATIN_ADHERENCE_ASSESSMENT',
      ],
    },
    automaticInvoiceSend: false,
    careNavigationEpisodeSummaryReportingModel: 'partnerSFTPAndPractitioner',
    defaultLabOrganization: LabOrganization.QUEST,
    displayName: 'Elevance Health',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['elevancehealth.com']),
    encounterSummaryReportingModel: 'practitionerOrThrow', // We will be sending the report to the partner as well via SFTP
    excludeBillingForFailedAppointments: true,
    insuranceUploadFromPartner: 'skip',
    internalCoordinatorID: '2e77d405-24e2-4b9e-af35-95ffefb12e17',
    invoiceEmailType: 'stripe',
    invoiceTerm: 60,
    isAutofillingRequisitionsAllowed: true,
    isGuestReschedulingAllowed: true,
    isPartnerBookingAllowed: true as const,
    isReferralBookingAllowed: true as const,
    isSelfBookingAllowed: false as const,
    isTestResultsUploadRequired: true,
    labOrganizations: {
      [LabOrganization.QUEST]: [ServiceType.BLOOD, ServiceType.URINE],
      [LabOrganization.SPRINTER_HEALTH]: [
        ServiceType.ANNUAL_WELLNESS_VISIT_PROVIDER_CALL,
        ServiceType.BONE_DENSITY_SCAN,
        ServiceType.VITALS,
        ServiceType.DIABETIC_RETINAL_SCAN,
        ServiceType.DEXA_SCAN_CARE_NAVIGATION_BOOKING,
        ServiceType.DEXA_SCAN_CARE_NAVIGATION_CONFIRMATION,
        ServiceType.MAMMOGRAM_CARE_NAVIGATION_BOOKING,
        ServiceType.MAMMOGRAM_CARE_NAVIGATION_CONFIRMATION,
        ServiceType.PCP_VISIT_CARE_NAVIGATION_BOOKING,
        ServiceType.PCP_VISIT_CARE_NAVIGATION_CONFIRMATION,
        ServiceType.KIT_DELIVERY_AND_EDUCATION,
        ServiceType.SDOH_CARE_NAVIGATION_RESOURCE_GUIDANCE,
        ServiceType.HEIGHT_AND_WEIGHT_COLLECTION,
        ServiceType.STATIN_ADHERENCE_ASSESSMENT,
      ],
    },
    maxAllowedPatientsPerBooking: 1 as const,
    onlyOrdersSingleServiceRequests: false as const,
    partnerOrganizationGroup: PartnerOrganizationGroup.ELEVANCEHEALTH,
    partnerSupportResponsibleManager: 'Carole',
    patientConfig: PATIENT_CONFIG.ELEVANCEHEALTH,
    // There is no patientSupportPhone & patientSupportEmail for Elevance Health
    payer: 'partner' as const,
    paymentType: 'invoice',
    priceForProductsUSD: {
      ANNUAL_WELLNESS_VISIT_PROVIDER_CALL: makeDollar(420), // AWV Provider Call
      BONE_DENSITY_SCAN: makeDollar(650), // Bone Density Scan in home service
      DEXA_SCAN_CARE_NAVIGATION_BOOKING: makeDollar(90), // Bone Density Scan booking
      DEXA_SCAN_CARE_NAVIGATION_CONFIRMATION: makeDollar(90), // Bone Density Scan confirmation
      DIABETIC_RETINAL_SCAN: makeDollar(50), // Eye Exam
      HEIGHT_AND_WEIGHT_COLLECTION: makeDollar(0), // Height and Weight collection for AWV. Setting this to 0 since we will have a separate product price for AWV VISIT
      KIT_DELIVERY_AND_EDUCATION: makeDollar(15), // Insure One Fit Kit
      LAB_TEST: makeDollar(20), // Labs
      MAMMOGRAM_CARE_NAVIGATION_BOOKING: makeDollar(90), // Breast Cancer Screening booking
      MAMMOGRAM_CARE_NAVIGATION_CONFIRMATION: makeDollar(90), // Breast Cancer Screening confirmation
      PCP_VISIT_CARE_NAVIGATION_BOOKING: makeDollar(90), // PCP visit booking
      PCP_VISIT_CARE_NAVIGATION_CONFIRMATION: makeDollar(90), // PCP visit confirmation
      SDOH_CARE_NAVIGATION_RESOURCE_GUIDANCE: makeDollar(20), // SDOH Care Navigation questionnaire
      VITALS: makeDollar(5), // BP Check
    },
    priceUSD: makeDollar(145),
    referralCoordinatorID: '9e047f7d-ae2d-4c92-b47e-5d9762aac668',
    referralVerificationPageUrlPath: 'elevancehealth',
    requisitionUploadFromPartner: 'skip',
    reschedulingCoordinator: 'sprinterHealth',
    serviceRequestsAddByDefault: {
      SPRINTER_HEALTH: [
        {
          externalID: 'HEIGHT_AND_WEIGHT_COLLECTION',
          prerequisiteServiceTypes: [ServiceType.ANNUAL_WELLNESS_VISIT_PROVIDER_CALL],
        },
        {
          externalID: 'VITALS-CHECK',
          prerequisiteServiceTypes: [ServiceType.ANNUAL_WELLNESS_VISIT_PROVIDER_CALL],
        },
        {
          externalID: 'PAIN_SCREENING',
        },
      ],
    },
    serviceRequestsFromPartner: 'required',
    shouldExportAppointmentsForBilling: true,
    shouldGenerateEncounterSummaryForVitals: true,
    shouldHideSprinterHealthSupportEmailInReferralsFlow: true,
    shouldUsePartnerBrands: true as const,
    showDiagnosisCodes: true,
    skipCancelationFee: true,
    sprinterHealthDefaultProductTypeMappingOverride: {
      [ProductType.VITALS]: {
        defaultSprinterHealthExternalID: 'BLOOD-PRESSURE-CHECK',
        displayName: 'Blood pressure collection',
        message: '',
      },
    },
    supportedProductTypes: [
      ProductType.ANNUAL_WELLNESS_VISIT_PROVIDER_CALL,
      ProductType.BONE_DENSITY_SCAN,
      ProductType.VITALS,
      ProductType.LAB_TEST,
      ProductType.KIT_DELIVERY_AND_EDUCATION,
      ProductType.DIABETIC_RETINAL_SCAN,
      ProductType.DEXA_SCAN_CARE_NAVIGATION_BOOKING,
      ProductType.DEXA_SCAN_CARE_NAVIGATION_CONFIRMATION,
      ProductType.HEIGHT_AND_WEIGHT_COLLECTION,
      ProductType.MAMMOGRAM_CARE_NAVIGATION_BOOKING,
      ProductType.MAMMOGRAM_CARE_NAVIGATION_CONFIRMATION,
      ProductType.PCP_VISIT_CARE_NAVIGATION_BOOKING,
      ProductType.PCP_VISIT_CARE_NAVIGATION_CONFIRMATION,
      ProductType.SDOH_CARE_NAVIGATION_RESOURCE_GUIDANCE,
      ProductType.STATIN_ADHERENCE_ASSESSMENT,
    ],
    supportsEDILabOrdering: true,
    supportsPatientEncounterSummary: true as const,
    supportsPatientEncounterSummaryDirectMail: true as const,
    supportsReferrals: true,
    vertical: 'healthPlan',
    zendeskOrganizationName: 'Elevance Health',
  },
  EMMANUELHEALTHCAREINC: {
    ...STANDARD_HOME_HEALTH_PARTNER_BOOKING,
    displayName: 'Emmanuel Healthcare',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['emmanuelhc.com']),
    partnerOrganizationGroup: PartnerOrganizationGroup.EMMANUELHEALTHCAREINC,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportEmail: 'intake@emmanuelhc.com',
    patientSupportPhoneE164: '+15108944139',
  },
  ESSENTIALSHOMEHEALTH: {
    ...STANDARD_HOME_HEALTH_PARTNER_BOOKING,
    displayName: 'Essentials Home Health',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider([
      'essentialshomehealth.com',
    ]),
    partnerOrganizationGroup: PartnerOrganizationGroup.ESSENTIALSHOMEHEALTH,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportEmail: 'maininbox@essentialshomehealth.com',
    patientSupportPhoneE164: '+15102395337',
    priceUSD: makeDollar(99),
  },
  EXACTSCIENCES: {
    ...STANDARD_SPECIALTY_LAB_PARTNER_BOOKING,
    ...STANDARD_SPECIALTY_LAB_SELF_BOOKING,
    cobrandingLogo: {
      height: 200,
      uri: 'https://app.sprinterhealth.com/images/exact-sciences-logo.png',
      width: 200,
    },
    defaultLabOrganization: LabOrganization.EXACT_SCIENCES,
    displayName: 'Exact Sciences',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['exactsciences.com']),
    isMultiPatientBookingAllowed: false,
    labOrganizations: {
      [LabOrganization.EXACT_SCIENCES]: [
        ServiceType.BLOOD,
        ServiceType.URINE,
        ServiceType.CAPILLARY_BLOOD_DRAW,
      ],
    },
    onlyOrdersSingleServiceRequests: true,
    partnerOrganizationGroup: PartnerOrganizationGroup.EXACTSCIENCES,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportEmail: 'oglclientaccountrep@exactsciences.com',
    patientSupportPhoneE164: '+18448708870',
    requisitionUploadFromPartner: 'optional',
    schedulingTimeRangeRules: SCHEDULING_TIME_RANGE_CONFIGS.EXACTSCIENCES,
    serviceRequestsFormatType: 'N',
    serviceRequestsFromPartner: 'required',
  },
  FIREFLYHEALTH: {
    ...GLOBAL_PARTNER_DEFAULTS,
    automaticInvoiceSend: false,
    cobrandingLogo: {
      height: 28,
      uri: 'https://app.sprinterhealth.com/firefly/firefly_logo.png',
      width: 112,
    },
    defaultLabOrganization: LabOrganization.QUEST,
    displayName: 'Firefly',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['firefly.health']),
    faxNumbersE164: ['+18573438192'],
    insuranceUploadFromPartner: 'skip',
    invoiceEmailType: 'stripe',
    invoiceTerm: 30,
    isPartnerBookingAllowed: true,
    isReferralBookingAllowed: true,
    isSelfBookingAllowed: false,
    labOrganizations: {
      [LabOrganization.QUEST]: [
        ServiceType.BLOOD,
        ServiceType.URINE,
        ServiceType.CAPILLARY_BLOOD_DRAW,
      ],
      [LabOrganization.SPRINTER_HEALTH]: [
        ServiceType.VITALS,
        ServiceType.DIABETIC_FOOT_SCREENING,
        ServiceType.CARDIAC_AUSCULTATION,
        ServiceType.ECG,
        ServiceType.DIABETIC_RETINAL_SCAN,
      ],
    },
    maxAllowedPatientsPerBooking: 1 as number,
    onlyOrdersSingleServiceRequests: false,
    partnerOrganizationGroup: PartnerOrganizationGroup.FIREFLYHEALTH,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientConfig: PATIENT_CONFIG.FIREFLYHEALTH,
    patientSupportPhoneE164: '+18888971887',
    payer: 'partner',
    paymentType: 'invoice',
    priceUSD: makeDollar(79),
    referralCoordinatorID: 'bb98f99f-dab8-48d7-944f-54a4843a3c2f',
    referralLandingPageUrlPath: 'firefly-health',
    requisitionUploadFromPartner: 'required',
    reschedulingCoordinator: 'sprinterHealth',
    serviceRequestsFromPartner: 'skip',
    shouldExportAppointmentsForBilling: true,
    shouldGenerateEncounterSummaryForVitals: true,
    skipCancelationFee: false,
    supportedProductTypes: [
      ProductType.VITALS,
      ProductType.DIABETIC_FOOT_SCREENING,
      ProductType.CARDIAC_AUSCULTATION,
      ProductType.ECG,
      ProductType.LAB_TEST,
      ProductType.DIABETIC_RETINAL_SCAN,
    ],
    supportsReferrals: true,
    vertical: 'healthPlan',
    vitalsThresholds: VITALS_CONFIG.FIREFLYHEALTH,
  },
  FIRSTCHOICEHOMEHEALTH: {
    ...STANDARD_HOME_HEALTH_PARTNER_BOOKING,
    displayName: '1st Choice Home Health & Hospice',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['firstchoice-hha.com']),
    partnerOrganizationGroup: PartnerOrganizationGroup.FIRSTCHOICEHOMEHEALTH,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportEmail: 'aamir@firstchoice-hha.com',
    patientSupportPhoneE164: '+16503935936',
  },
  FOUNDATIONMEDICINE: {
    ...STANDARD_SPECIALTY_LAB_PARTNER_BOOKING,
    defaultLabOrganization: LabOrganization.FOUNDATION_MEDICINE,
    displayName: 'Foundation Medicine',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider([
      'foundationmedicine.com',
    ]),
    invoiceEmailType: 'custom' as const,
    invoiceTerm: 60 as const,
    labOrganizations: {
      [LabOrganization.FOUNDATION_MEDICINE]: [
        ServiceType.BLOOD,
        ServiceType.URINE,
        ServiceType.CAPILLARY_BLOOD_DRAW,
      ],
    },
    partnerOrganizationGroup: PartnerOrganizationGroup.FOUNDATIONMEDICINE,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportEmail: 'client.services@foundationmedicine.com',
    patientSupportPhoneE164: '+18889883639',
    priceUSD: makeDollar(75),
    skipCancelationFee: true,
  },
  GEORGIAHANDSHOULDERELBOW: {
    ...GLOBAL_PARTNER_DEFAULTS,
    blockSharedSlotAppointments: true,
    defaultLabOrganization: LabOrganization.SPRINTER_HEALTH,
    disallowedServiceRequests: { SPRINTER_HEALTH: ['BD-C19-AG'] },
    displayName: 'Georgia Hand, Shoulder & Elbow',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider([
      'gahand.org',
      'oxos.com',
    ]),
    insuranceUploadFromPartner: 'skip',
    isPartnerBookingAllowed: true,
    isSelfBookingAllowed: false,
    labOrganizations: { [LabOrganization.SPRINTER_HEALTH]: [ServiceType.XRAY] },
    maxAllowedPatientsPerBooking: MAX_PATIENTS_PER_APPOINTMENT as number,
    onlyOrdersSingleServiceRequests: true,
    partnerOrganizationGroup: PartnerOrganizationGroup.GEORGIAHANDSHOULDERELBOW,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportPhoneE164: '+14043523522',
    payer: 'none',
    priceUSD: ZERO_DOLLARS,
    requisitionUploadFromPartner: 'skip',
    reschedulingCoordinator: 'partner',
    serviceRequestsFormatType: 'N C',
    serviceRequestsFromPartner: 'required',
    supportedProductTypes: [ProductType.XRAY],
    vertical: 'concierge',
  },
  GRAIL: {
    ...STANDARD_SPECIALTY_LAB_PARTNER_BOOKING,
    ...STANDARD_SPECIALTY_LAB_SELF_BOOKING,
    defaultLabOrganization: LabOrganization.GRAIL,
    displayName: 'GRAIL',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['grailbio.com']),
    invoiceTerm: 60 as const,
    labOrganizations: {
      [LabOrganization.GRAIL]: [
        ServiceType.BLOOD,
        ServiceType.URINE,
        ServiceType.CAPILLARY_BLOOD_DRAW,
      ],
    },
    partnerFeedbackLink: 'https://sprinterhealth.com/feedback_grail',
    partnerOrganizationGroup: PartnerOrganizationGroup.GRAIL,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportEmail: 'customerservice@grail.com',
    patientSupportPhoneE164: '+18336942553',
    priceUSD: makeDollar(75),
    skipCancelationFee: true,
  },
  GUARDANT: {
    ...STANDARD_SPECIALTY_LAB_PARTNER_BOOKING,
    defaultLabOrganization: LabOrganization.GUARDANT,
    disallowedServiceRequests: { GUARDANT: ['GuardantShield'] },
    displayName: 'Guardant',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['guardanthealth.com']),
    labOrganizations: {
      [LabOrganization.GUARDANT]: [
        ServiceType.BLOOD,
        ServiceType.URINE,
        ServiceType.CAPILLARY_BLOOD_DRAW,
      ],
    },
    partnerFeedbackLink: 'https://sprinterhealth.com/feedback_guardant',
    partnerOrganizationGroup: PartnerOrganizationGroup.GUARDANT,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportEmail: 'clientservices@guardanthealth.com',
    patientSupportPhoneE164: '+18556988887',
    priceUSD: makeDollar(75),
    requisitionUploadFromPartner: 'required',
  },
  GUARDANTSHIELD: {
    ...STANDARD_SPECIALTY_LAB_PARTNER_BOOKING,
    allowedServiceRequests: { GUARDANT: ['GuardantShield'] },
    defaultLabOrganization: LabOrganization.GUARDANT,
    displayName: 'Guardant Shield',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['guardanthealth.com']),
    labOrganizations: {
      [LabOrganization.GUARDANT]: [
        ServiceType.BLOOD,
        ServiceType.URINE,
        ServiceType.CAPILLARY_BLOOD_DRAW,
      ],
    },
    partnerFeedbackLink: 'https://sprinterhealth.com/feedback_guardant',
    partnerOrganizationGroup: PartnerOrganizationGroup.GUARDANT,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportEmail: 'clientservices@guardanthealth.com',
    patientSupportPhoneE164: '+18557227335',
    priceUSD: makeDollar(75),
    requisitionUploadFromPartner: 'optional',
    selectSingleServiceRequestExternalID: 'GuardantShield',
  },
  GUARDIANANGELHOMECARE: {
    ...STANDARD_HOME_HEALTH_PARTNER_BOOKING,
    displayName: 'Guardian Angel Home Care',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['gahc94.com']),
    invoiceEmailType: 'custom' as const,
    partnerOrganizationGroup: PartnerOrganizationGroup.GUARDIANANGELHOMECARE,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportEmail: 'sanjoseintake@gahc94.com',
    patientSupportPhoneE164: '+14082611120',
  },
  HALESPORTS: {
    ...STANDARD_CONCIERGE_PARTNER_BOOKING,
    displayName: 'HALE Sports',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider([
      'halesports.com',
      'gmail.com',
      'adaptandperform.com',
    ]),
    partnerOrganizationGroup: PartnerOrganizationGroup.HALESPORTS,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportEmail: 'contact@halesports.com',
    patientSupportPhoneE164: '+16465490630',
  },
  HARMONYHHPLEASANTHILL: {
    ...STANDARD_HOME_HEALTH_PARTNER_BOOKING,
    displayName: 'Harmony Home Health',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['harmonyhcare.com']),
    partnerOrganizationGroup: PartnerOrganizationGroup.HARMONYHH,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportEmail: 'zoen@harmonyhcare.com',
    patientSupportPhoneE164: '+19253325422',
    priceUSD: makeDollar(99),
  },
  HARMONYHHSANTACLARA: {
    ...STANDARD_HOME_HEALTH_PARTNER_BOOKING,
    displayName: 'Harmony Home Health',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['harmonyhcare.com']),
    partnerOrganizationGroup: PartnerOrganizationGroup.HARMONYHH,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportEmail: 'zoen@harmonyhcare.com',
    patientSupportPhoneE164: '+14082607062',
    priceUSD: makeDollar(99),
  },
  HEALTHFLEXHOMEHEALTH: {
    ...STANDARD_HOME_HEALTH_PARTNER_BOOKING,
    displayName: 'HealthFlex',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['healthflex.com']),
    partnerOrganizationGroup: PartnerOrganizationGroup.HEALTHFLEX,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportEmail: 'labs@healthflex.com',
    patientSupportPhoneE164: '+15105531900',
    priceUSD: makeDollar(99),
  },
  HEALTHFLEXHOSPICE: {
    ...STANDARD_HOME_HEALTH_PARTNER_BOOKING,
    displayName: 'HealthFlex Hospice',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['healthflex.com']),
    partnerOrganizationGroup: PartnerOrganizationGroup.HEALTHFLEX,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportEmail: 'barbara@healthflex.com',
    patientSupportPhoneE164: '+16508252802',
    vertical: 'hospice',
  },
  HHRGCARELINK: {
    ...HOME_HEALTH_RESOURCE_GROUP_BASE,
    automaticInvoiceSend: true,
    displayName: 'Carelink Home Health',
    partnerSupportResponsibleManager: 'Sengyeon',
  },
  HHRGELIXIR: {
    ...HOME_HEALTH_RESOURCE_GROUP_BASE,
    displayName: 'Elixir',
    partnerSupportResponsibleManager: 'Sengyeon',
  },
  HHRGGOLDENPACIFICHOMEHEALTH: {
    ...HOME_HEALTH_RESOURCE_GROUP_BASE,
    displayName: 'Golden Pacific Home Health',
    partnerSupportResponsibleManager: 'Sengyeon',
  },
  HHRGHELPINGHANDS: {
    ...HOME_HEALTH_RESOURCE_GROUP_BASE,
    displayName: 'Helping Hands',
    partnerSupportResponsibleManager: 'Sengyeon',
  },
  HHRGMARYSHELP: {
    ...HOME_HEALTH_RESOURCE_GROUP_BASE,
    displayName: "Mary's Help",
    partnerSupportResponsibleManager: 'Sengyeon',
  },
  HHRGNORCAL: {
    ...HOME_HEALTH_RESOURCE_GROUP_BASE,
    displayName: 'Nor-Cal',
    partnerSupportResponsibleManager: 'Sengyeon',
  },
  HOMEHEALTHBAYAREAINC: {
    ...STANDARD_HOME_HEALTH_PARTNER_BOOKING,
    displayName: 'Home Health Bay Area',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['homehealthbayarea.com']),
    partnerOrganizationGroup: PartnerOrganizationGroup.HOMEHEALTHBAYAREAINC,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportEmail: 'Nicole@homehealthbayarea.com',
    patientSupportPhoneE164: '+16505908300',
  },
  HUMANA: {
    ...HUMANA_BASE,
    displayName: 'Humana National',
    internalCoordinatorID: '3963e020-f1d3-4983-97f8-dcebb5aebb5c',
    patientConfig: PATIENT_CONFIG.HUMANA,
    referralCoordinatorID: '0bd392d8-67cd-487f-af43-3b1fe4f1aff4',
    referralLandingPageUrlPath: 'humana',
    referralVerificationPageUrlPath: 'humana',
    shouldExportAppointmentsForBilling: true,
    zendeskOrganizationName: 'Humana - National',
  },
  HUMANAMI: {
    ...HUMANA_BASE,
    displayName: 'Humana MI',
    internalCoordinatorID: 'a6149584-4449-4a16-9147-b8c48173bc87',
    patientConfig: PATIENT_CONFIG.HUMANAMI,
    referralCoordinatorID: '594786dd-0dd7-459d-ab30-2eb4f3dcb980',
    referralLandingPageUrlPath: 'humana-mi',
    referralVerificationPageUrlPath: 'humana-mi',
    shouldExportAppointmentsForBilling: true,
    zendeskOrganizationName: 'Humana - Michigan',
  },
  HUMANANY: {
    ...HUMANA_BASE,
    displayName: 'Humana NY',
    internalCoordinatorID: 'e68ac8a6-03a3-4ff4-bfa2-cae0a5631029',
    patientConfig: PATIENT_CONFIG.HUMANANY,
    referralCoordinatorID: '591442f7-e344-4b1c-b230-7492c00dfde8',
    referralLandingPageUrlPath: 'humana-ny',
    referralVerificationPageUrlPath: 'humana-ny',
    shouldExportAppointmentsForBilling: true,
    zendeskOrganizationName: 'Humana - New York',
  },
  INTERMOUNTAINCANCERCENTERRIVERTON: {
    ...INTERMOUNTAIN_CANCER_CENTER_BASE,
    displayName: 'Intermountain Cancer Center Riverton',
    fullPartnerDisplayNameForMessaging: 'Intermountain Cancer Center',
  },
  INTERMOUNTAINCANCERCENTERSALTLAKE: {
    ...INTERMOUNTAIN_CANCER_CENTER_BASE,
    displayName: 'Intermountain Cancer Center Salt Lake City',
    fullPartnerDisplayNameForMessaging: 'Intermountain Cancer Center',
  },
  INTERMOUNTAINCANCERCENTERSTGEORGE: {
    ...INTERMOUNTAIN_CANCER_CENTER_BASE,
    displayName: 'Intermountain Cancer Center St George',
    fullPartnerDisplayNameForMessaging: 'Intermountain Cancer Center',
  },
  INTOUCHHOMECARE: {
    ...STANDARD_HOME_HEALTH_PARTNER_BOOKING,
    displayName: 'InTouch Home Care',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['intouchhc.com']),
    partnerOrganizationGroup: PartnerOrganizationGroup.INTOUCHHOMECARE,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportEmail: 'haidee@intouchhc.com',
    patientSupportPhoneE164: '+14086107110',
  },
  JOHNMUIRHEALTH: {
    ...STANDARD_HEALTH_SYSTEM_PARTNER_BOOKING,
    defaultLabOrganization: LabOrganization.MUIRLAB,
    displayName: 'John Muir Health',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['johnmuirhealth.com']),
    insuranceUploadFromPartner: 'optional',
    labOrganizations: {
      [LabOrganization.MUIRLAB]: [
        ServiceType.BLOOD,
        ServiceType.URINE,
        ServiceType.CAPILLARY_BLOOD_DRAW,
      ],
    },
    partnerOrganizationGroup: PartnerOrganizationGroup.JOHNMUIRHEALTH,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportPhoneE164: '+18667512459',
  },
  KANEHEALTH: {
    ...STANDARD_CONCIERGE_PARTNER_BOOKING,
    displayName: 'Kane Health',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['kane.health']),
    partnerOrganizationGroup: PartnerOrganizationGroup.KANEHEALTH,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportEmail: 'backoffice@kane.health',
    patientSupportPhoneE164: '+16509354490',
    priceUSD: makeDollar(99),
  },
  KRISTARAMONASMD: {
    ...STANDARD_CONCIERGE_PARTNER_BOOKING,
    displayName: 'Krista Ramonas, MD',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['healthnavigators.org']),
    partnerOrganizationGroup: PartnerOrganizationGroup.KRISTARAMONASMD,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportEmail: 'drramonas@healthnavigators.org',
    patientSupportPhoneE164: '+14158285228',
  },
  LEBAE: {
    ...STANDARD_CONCIERGE_PARTNER_BOOKING,
    displayName: 'Lebae',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['lebae.com']),
    partnerOrganizationGroup: PartnerOrganizationGroup.LEBAE,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportEmail: 'info@lebae.com',
    patientSupportPhoneE164: '+13108494578',
  },
  LONETREEPOSTACUTE: {
    ...STANDARD_SKILLED_NURSING_FACILITY_PARTNER_BOOKING,
    displayName: 'Lone Tree Post Acute',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['lonetreepa.com']),
    partnerOrganizationGroup: PartnerOrganizationGroup.LONETREEPOSTACUTE,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportEmail: 'kenneth.lim@lonetreepa.com',
    patientSupportPhoneE164: '+19257540470',
  },
  MARSHALLMEDICALCENTER: {
    ...STANDARD_HEALTH_SYSTEM_PARTNER_BOOKING,
    defaultLabOrganization: LabOrganization.MARSHALL_LABORATORY,
    displayName: 'Marshall Medical Center',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['marshallmedical.org']),
    insuranceUploadFromPartner: 'optional',
    labOrganizations: {
      [LabOrganization.MARSHALL_LABORATORY]: [
        ServiceType.BLOOD,
        ServiceType.URINE,
        ServiceType.CAPILLARY_BLOOD_DRAW,
      ],
    },
    partnerOrganizationGroup: PartnerOrganizationGroup.MARSHALLMEDICALCENTER,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportEmail: 'tmoody@marshallmedical.org',
    patientSupportPhoneE164: '+15306262793',
  },
  MATTAPAN: {
    ...BMC_BASE,
    canEditServicesAfterBooking: true,
    defaultLabOrganization: LabOrganization.QUEST,
    displayName: 'Mattapan',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider([
      'matchc.org',
      'sprinterhealth.com',
    ]),
    ignoreAbstentionsMessaging: true,
    insuranceUploadFromPartner: 'skip',
    internalCoordinatorID: '3008cb61-b19c-43ad-88f0-bc2594f4bce4',
    labOrganizations: {
      [LabOrganization.QUEST]: [
        ServiceType.BLOOD,
        ServiceType.URINE,
        ServiceType.CAPILLARY_BLOOD_DRAW,
      ],
      [LabOrganization.SPRINTER_HEALTH]: [
        ServiceType.DIABETIC_RETINAL_SCAN,
        ServiceType.VITALS,
        ServiceType.FIT_KIT_PICKUP,
      ],
    },
    nurseVitalsUrgencyLineInformation: {
      phoneNumbers: [
        { phoneE164: '+16178989075' },
        { phoneE164: '+16178989188' },
        { phoneE164: '+16175045500' },
      ],
    },
    partnerSupportResponsibleManager: 'Sengyeon',
    patientConfig: PATIENT_CONFIG.MATTAPAN,
    patientSupportPhoneE164: '+16178989075',
    priceForProductsUSD: {
      DIABETIC_RETINAL_SCAN: makeDollar(129),
      FIT_KIT_PICKUP: makeDollar(59),
      LAB_TEST: makeDollar(79),
      VITALS: makeDollar(59),
    },
    requisitionUploadFromPartner: 'required_after_booking',
    shouldSendAppointmentReportToCoordinator: true,
    skipCancelationFee: true,
    supportedProductTypes: [
      ProductType.LAB_TEST,
      ProductType.DIABETIC_RETINAL_SCAN,
      ProductType.VITALS,
      ProductType.FIT_KIT_PICKUP,
    ],
    vitalsThresholds: VITALS_CONFIG.MATTAPAN,
  },
  MERIDIANHOMEHEALTH: {
    ...STANDARD_HOME_HEALTH_PARTNER_BOOKING,
    displayName: 'Meridian Home Health',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider([
      'meridiancarehomehealth.com',
    ]),
    partnerOrganizationGroup: PartnerOrganizationGroup.MERIDIANHOMEHEALTH,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportEmail: 'info@meridiancarehomehealth.com',
    patientSupportPhoneE164: '+15107939255',
    priceUSD: makeDollar(99),
  },
  MOLINACA: {
    ...MOLINA_BASE,
    allowedServiceRequests: {
      QUEST: [
        '496', //  Quest A1C
        '375', // Quest eGFR (creatinine)
        '6517', // Quest uACR
        '39027', // QUest lead screening (capillary)
        '599', // QUest lead screening (venous)
      ],
      SPRINTER_HEALTH: [
        'RACE-AND-ETHNICITY-QUESTIONNAIRE',
        'SDOH-QUESTIONNAIRE',
        'BLOOD-PRESSURE-CHECK',
        'DIABETIC-RETINAL-SCAN',
        'INSURE-ONE-FIT-KIT-DELIVERY-AND-EDUCATION',
        'COLOGUARD-KIT-EDUCATION',
        'TOPICAL_FLUORIDE',
      ],
    },
    displayName: 'Molina Healthcare California',
    encounterSummaryReportingModel: 'practitionerOrThrow',
    internalCoordinatorID: 'daf275d9-1aed-4960-be93-d24bc03e318f',
    labOrganizations: {
      [LabOrganization.QUEST]: [
        ServiceType.BLOOD,
        ServiceType.URINE,
        ServiceType.CAPILLARY_BLOOD_DRAW,
      ],
      [LabOrganization.SPRINTER_HEALTH]: [
        ServiceType.VITALS,
        ServiceType.DIABETIC_RETINAL_SCAN,
        ServiceType.RACE_AND_ETHNICITY,
        ServiceType.SDOH_QUESTIONNAIRE,
        ServiceType.KIT_DELIVERY_AND_EDUCATION,
        ServiceType.COLOGUARD_KIT_EDUCATION,
        ServiceType.TOPICAL_FLUORIDE,
      ],
    },
    leadScreeningEligibilityRequirements: CALIFORNIA_LEAD_SCREENING_AGE_REQUIREMENTS,
    patientConfig: PATIENT_CONFIG.MOLINACA,
    patientSupportPhoneE164: '+18556654627',
    priceUSD: makeDollar(134),
    referralCoordinatorID: '3330e47f-614f-49d9-961f-02ea12894901',
    referralLandingPageUrlPath: 'molina-ca',
    referralVerificationPageUrlPath: 'molina-ca',
    shouldSkipPriorLeadScreeningsQuestion: true,
    supportedProductTypes: [
      ProductType.VITALS,
      ProductType.DIABETIC_RETINAL_SCAN,
      ProductType.LAB_TEST,
      ProductType.QUESTIONNAIRES,
      ProductType.KIT_DELIVERY_AND_EDUCATION,
      ProductType.COLOGUARD_KIT_EDUCATION,
      ProductType.TOPICAL_FLUORIDE,
    ],
    supportsEDILabOrdering: true,
    zendeskOrganizationName: 'Molina - California',
  },
  MOLINAFL: {
    ...MOLINA_BASE,
    allowedServiceRequests: {
      QUEST: [
        '496', //  Quest A1C
        '39027', // Quest lead screening
      ],
      SPRINTER_HEALTH: [
        'RACE-AND-ETHNICITY-QUESTIONNAIRE',
        'SDOH-QUESTIONNAIRE',
        'BLOOD-PRESSURE-CHECK',
        'DIABETIC-RETINAL-SCAN',
        'INSURE-ONE-FIT-KIT-DELIVERY-AND-EDUCATION',
        'COLOGUARD-KIT-EDUCATION',
      ],
    },
    displayName: 'Molina Healthcare Florida',
    encounterSummaryReportingDefaultCaseManagerEmail: 'MFLMemberIncentives@MolinaHealthcare.com',
    encounterSummaryReportingModel: 'practitionerWithDefaultCaseManagerFallBack',
    excludeBillingForFailedAppointments: true,
    internalCoordinatorID: '71915602-d725-4984-946f-7c3f0813ba0b',
    labOrganizations: {
      [LabOrganization.QUEST]: [
        ServiceType.BLOOD,
        ServiceType.URINE,
        ServiceType.CAPILLARY_BLOOD_DRAW,
      ],
      [LabOrganization.SPRINTER_HEALTH]: [
        ServiceType.VITALS,
        ServiceType.DIABETIC_RETINAL_SCAN,
        ServiceType.RACE_AND_ETHNICITY,
        ServiceType.SDOH_QUESTIONNAIRE,
        ServiceType.KIT_DELIVERY_AND_EDUCATION,
        ServiceType.COLOGUARD_KIT_EDUCATION,
      ],
    },
    leadScreeningEligibilityRequirements: HEDIS_LEAD_SCREENING_AGE_REQUIREMENTS,
    patientConfig: PATIENT_CONFIG.MOLINAFL,
    patientSupportPhoneE164: '+18664724585',
    priceUSD: makeDollar(134),
    referralCoordinatorID: '28934941-8f79-4a79-bfcc-634eb6f74b52',
    referralLandingPageUrlPath: 'molina-fl',
    referralVerificationPageUrlPath: 'molina-fl',
    shouldSkipPriorLeadScreeningsQuestion: true,
    supportedProductTypes: [
      ProductType.VITALS,
      ProductType.DIABETIC_RETINAL_SCAN,
      ProductType.LAB_TEST,
      ProductType.QUESTIONNAIRES,
      ProductType.KIT_DELIVERY_AND_EDUCATION,
      ProductType.COLOGUARD_KIT_EDUCATION,
    ],
    supportsEDILabOrdering: true,
    zendeskOrganizationName: 'Molina - Florida',
  },
  MOLINAMI: {
    ...MOLINA_BASE,
    allowedServiceRequests: {
      QUEST: [
        '496', //  Quest A1C
        '375', // Quest eGFR (creatinine)
        '6517', // Quest uACR
        '39027', // Quest lead screening
      ],
      SPRINTER_HEALTH: [
        'RACE-AND-ETHNICITY-QUESTIONNAIRE',
        'SDOH-QUESTIONNAIRE',
        'BLOOD-PRESSURE-CHECK',
        'DIABETIC-RETINAL-SCAN',
        'INSURE-ONE-FIT-KIT-DELIVERY-AND-EDUCATION',
        'COLOGUARD-KIT-EDUCATION',
      ],
    },
    displayName: 'Molina Healthcare Michigan',
    encounterSummaryReportingDefaultCaseManagerEmail: 'Karen.Unholz@molinahealthcare.com',
    encounterSummaryReportingModel: 'practitionerWithDefaultCaseManagerFallBack',
    excludeBillingForFailedAppointments: true,
    internalCoordinatorID: 'dd542bfb-6eff-40d7-924c-508c8053eb9c',
    labOrganizations: {
      [LabOrganization.QUEST]: [
        ServiceType.BLOOD,
        ServiceType.URINE,
        ServiceType.CAPILLARY_BLOOD_DRAW,
      ],
      [LabOrganization.SPRINTER_HEALTH]: [
        ServiceType.VITALS,
        ServiceType.DIABETIC_RETINAL_SCAN,
        ServiceType.RACE_AND_ETHNICITY,
        ServiceType.SDOH_QUESTIONNAIRE,
        ServiceType.KIT_DELIVERY_AND_EDUCATION,
        ServiceType.COLOGUARD_KIT_EDUCATION,
      ],
    },
    leadScreeningEligibilityRequirements: HEDIS_LEAD_SCREENING_AGE_REQUIREMENTS,
    patientConfig: PATIENT_CONFIG.MOLINAMI,
    patientSupportPhoneE164: '+18888987969',
    priceUSD: makeDollar(134),
    referralCoordinatorID: '65a6c859-074c-4448-b59c-34a0261688b2',
    referralLandingPageUrlPath: 'molina-mi',
    referralVerificationPageUrlPath: 'molina-mi',
    shouldSkipPriorLeadScreeningsQuestion: true,
    supportedProductTypes: [
      ProductType.VITALS,
      ProductType.DIABETIC_RETINAL_SCAN,
      ProductType.LAB_TEST,
      ProductType.QUESTIONNAIRES,
      ProductType.KIT_DELIVERY_AND_EDUCATION,
      ProductType.COLOGUARD_KIT_EDUCATION,
    ],
    supportsEDILabOrdering: true,
    zendeskOrganizationName: 'Molina - Michigan',
  },
  MOLINAUT: {
    ...MOLINA_BASE,
    allowedServiceRequests: {
      LETS_GET_CHECKED_LAB: ['LETS-GET-CHECKED-COLON-CANCER-SCREENING'],
      QUEST: [
        '496', //  Quest A1C
        '375', // Quest eGFR (creatinine)
        '6517', // Quest uACR
      ],
      SPRINTER_HEALTH: [
        'RACE-AND-ETHNICITY-QUESTIONNAIRE',
        'SDOH-QUESTIONNAIRE',
        'BLOOD-PRESSURE-CHECK',
        'DIABETIC-RETINAL-SCAN',
        'INSURE-ONE-FIT-KIT-DELIVERY-AND-EDUCATION',
        'COLOGUARD-KIT-EDUCATION',
        'PCP_VISIT_CARE_NAVIGATION_BOOKING',
        'PCP_VISIT_CARE_NAVIGATION_CONFIRMATION',
        'MAMMOGRAM_CARE_NAVIGATION_BOOKING',
        'MAMMOGRAM_CARE_NAVIGATION_CONFIRMATION',
      ],
    },
    careNavigationEpisodeSummaryReportingModel: 'none',
    displayName: 'Molina Healthcare Utah',
    encounterSummaryReportingDefaultCaseManagerEmail: 'MHUTCASEMANAGEMENT@molinahealthcare.com',
    encounterSummaryReportingModel: 'practitionerWithDefaultCaseManagerFallBack',
    internalCoordinatorID: 'fa38d135-ec7b-4dbe-8e87-a8fe69ae652c',
    labOrganizations: {
      [LabOrganization.QUEST]: [
        ServiceType.BLOOD,
        ServiceType.URINE,
        ServiceType.CAPILLARY_BLOOD_DRAW,
      ],
      [LabOrganization.LETS_GET_CHECKED_LAB]: [ServiceType.FIT_KIT_PICKUP],
      [LabOrganization.SPRINTER_HEALTH]: [
        ServiceType.VITALS,
        ServiceType.DIABETIC_RETINAL_SCAN,
        ServiceType.SDOH_QUESTIONNAIRE,
        ServiceType.RACE_AND_ETHNICITY,
        ServiceType.KIT_DELIVERY_AND_EDUCATION,
        ServiceType.COLOGUARD_KIT_EDUCATION,
      ],
    },
    patientConfig: PATIENT_CONFIG.MOLINAUT,
    patientSupportPhoneE164: '+18884830760',
    priceUSD: makeDollar(79),
    referralCoordinatorID: '0a10cad6-ceb5-4699-aabe-6b4defda868f',
    referralLandingPageUrlPath: 'molina-ut',
    referralVerificationPageUrlPath: 'molina-ut',
    shouldSkipPriorLeadScreeningsQuestion: true,
    supportedProductTypes: [
      ProductType.LAB_TEST,
      ProductType.VITALS,
      ProductType.DIABETIC_RETINAL_SCAN,
      ProductType.QUESTIONNAIRES,
      ProductType.FIT_KIT_PICKUP,
      ProductType.KIT_DELIVERY_AND_EDUCATION,
      ProductType.COLOGUARD_KIT_EDUCATION,
    ],
    supportsEDILabOrdering: true,
    zendeskOrganizationName: 'Molina - Utah',
  },
  MYDOCTORMEDICALGROUP: {
    ...GLOBAL_PARTNER_DEFAULTS,
    blockSharedSlotAppointments: true,
    defaultLabOrganization: STANDARD_REFERENCE_LAB_ORGANIZATION,
    displayName: 'My Doctor Medical Group',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['mydoctorsf.com']),
    insuranceUploadFromPartner: 'skip',
    insuranceUploadFromPatient: 'skip',
    isMultiPatientBookingAllowed: true,
    isPartnerBookingAllowed: true,
    isSelfBookingAllowed: true,
    labOrganizations: STANDARD_REFERENCE_LAB_ORGANIZATION_SETTINGS,
    maxAllowedPatientsPerBooking: MAX_PATIENTS_PER_APPOINTMENT as number,
    onlyOrdersSingleServiceRequests: false,
    partnerOrganizationGroup: PartnerOrganizationGroup.MYDOCTORMEDICALGROUP,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportEmail: 'office@mydoctorsf.com',
    patientSupportPhoneE164: '+14159634431',
    payer: 'patient',
    requisitionUploadFromPartner: 'required',
    requisitionUploadFromPatient: 'required',
    reschedulingCoordinator: 'partner',
    serviceRequestsFromPartner: 'skip',
    supportedProductTypes: [ProductType.LAB_TEST],
    vertical: 'concierge',
  },
  MYDOCTORMEDICALGROUPVIP: {
    ...STANDARD_CONCIERGE_PARTNER_BOOKING,
    displayName: 'My Doctor Medical Group',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['mydoctorsf.com']),
    insuranceUploadFromPartner: 'optional',
    invoiceEmailType: 'custom',
    partnerOrganizationGroup: PartnerOrganizationGroup.MYDOCTORMEDICALGROUP,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportEmail: 'office@mydoctorsf.com',
    patientSupportPhoneE164: '+14159634431',
    priceUSD: makeDollar(99),
    requisitionUploadFromPartner: 'required',
  },
  NATERA: {
    ...STANDARD_SPECIALTY_LAB_PARTNER_BOOKING,
    defaultLabOrganization: LabOrganization.NATERA,
    displayName: 'Natera',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['sprinterhealth.com']),
    isGuestReschedulingAllowed: true,
    labOrganizations: {
      [LabOrganization.NATERA]: [
        ServiceType.BLOOD,
        ServiceType.URINE,
        ServiceType.CAPILLARY_BLOOD_DRAW,
      ],
    },
    onlyOrdersSingleServiceRequests: false,
    partnerOrganizationGroup: PartnerOrganizationGroup.NATERA,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportEmail: 'support@natera.com',
    patientSupportPhoneE164: '+18447784700',
    // Natera is billing manually through Stripe. Specifically,
    // total appointment volume is gathered from MPX and then an invoice
    // is created manually in Stripe. Thus, we don't need to add appointment charges
    // in Stripe, but we do need to keep their account open.
    payer: 'none',
    reschedulingCoordinator: 'sprinterHealth',
    // Natera appointments are booked by our CMs, this is used to turn off appointment failure fees
    skipCancelationFee: true,
    sprinterHealthSupportPhoneE164: '+12096770049',
    zendeskOrganizationName: 'Natera',
  },
  NAVERIS: {
    ...STANDARD_SPECIALTY_LAB_PARTNER_BOOKING,
    ...STANDARD_SPECIALTY_LAB_SELF_BOOKING,
    cobrandingLogo: {
      height: 28,
      uri: 'https://app.sprinterhealth.com/naveris/navdx_logo_32h.png',
      width: 112,
    },
    defaultLabOrganization: LabOrganization.NAVERIS,
    displayName: 'Naveris',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider([
      'sprinterhealth.com',
      'naveris.com',
    ]),
    labOrganizations: {
      [LabOrganization.NAVERIS]: [
        ServiceType.BLOOD,
        ServiceType.URINE,
        ServiceType.CAPILLARY_BLOOD_DRAW,
      ],
    },
    partnerFeedbackLink: 'https://sprinterhealth.com/feedback_naveris',
    partnerOrganizationGroup: PartnerOrganizationGroup.NAVERIS,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportEmail: 'support@naveris.com',
    patientSupportPhoneE164: '+18336283747',
    priceUSD: makeDollar(75),
    selectSingleServiceRequestExternalID: 'NavDx',
    skipCancelationFee: true,
  },
  NORTHBAYPRIVATEMEDICINE: {
    ...STANDARD_CONCIERGE_PARTNER_BOOKING,
    displayName: 'North Bay Private Medicine',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['nbpmd.com']),
    partnerOrganizationGroup: PartnerOrganizationGroup.NORTHBAYPRIVATEMEDICINE,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportEmail: 'kori@nbpmd.com',
    patientSupportPhoneE164: '+14155006220',
    priceUSD: makeDollar(99),
  },
  NURSINGANDREHABATHOME: {
    ...STANDARD_HOME_HEALTH_PARTNER_BOOKING,
    displayName: 'Nursing and Rehab at Home',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['rehabathome.org']),
    partnerOrganizationGroup: PartnerOrganizationGroup.NURSINGANDREHABATHOME,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportEmail: 'psandhu@rehabathome.org',
    patientSupportPhoneE164: '+16502864272',
  },
  OPOHEALTH: {
    ...STANDARD_HOME_HEALTH_PARTNER_BOOKING,
    displayName: 'Opo Health',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['OPOHealth.com']),
    partnerOrganizationGroup: PartnerOrganizationGroup.OPOHEALTH,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportEmail: 'Info@OPOHealth.com',
    patientSupportPhoneE164: '+16502880130',
  },
  OSCAR: {
    // A.k.a. "Oscar Care" or "Oscar VPC"
    ...GLOBAL_PARTNER_DEFAULTS,
    allowedServiceRequests: {
      QUEST: [
        '496', // Quest A1C
        '17674', // Quest Urine
        '10231', // Quest Metabolic Panel
        '7600', // Quest Lipid Panel
      ],
      SPRINTER_HEALTH: [
        'CARDIAC-AUSCULTATION',
        'DIABETIC-FOOT-SCREENING',
        'DIABETIC-RETINAL-SCAN',
        'ELECTROCARDIOGRAM-12-LEAD',
        'INSURE-ONE-FIT-KIT-DELIVERY-AND-EDUCATION',
        'VITALS-CHECK',
      ],
    },
    appointmentReportEmails: ['omgmail@hioscar.com'],
    canEditServicesAfterBooking: true,
    cobrandingLogo: {
      height: 28,
      uri: 'https://app.sprinterhealth.com/oscar/oscar_logo.png',
      width: 112,
    },
    defaultLabOrganization: LabOrganization.QUEST,
    displayName: 'Oscar Care',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider([
      'sprinterhealth.com',
      'hioscar.com',
    ]),
    fullPartnerDisplayNameForMessaging: 'Oscar Care',
    insuranceUploadFromPartner: 'skip',
    internalCoordinatorID: 'f516b328-5944-4b44-974f-7601fe28cba3',
    isPartnerBookingAllowed: true,
    isReferralBookingAllowed: true,
    isSelfBookingAllowed: false,
    labOrganizations: {
      [LabOrganization.QUEST]: [
        ServiceType.BLOOD,
        ServiceType.URINE,
        ServiceType.CAPILLARY_BLOOD_DRAW,
      ],
      [LabOrganization.SPRINTER_HEALTH]: [
        ServiceType.VITALS,
        ServiceType.DIABETIC_FOOT_SCREENING,
        ServiceType.CARDIAC_AUSCULTATION,
        ServiceType.ECG,
        ServiceType.DIABETIC_RETINAL_SCAN,
      ],
    },
    // TODO(shilpi): fix to handle retinal scan check for multiple patients.
    maxAllowedPatientsPerBooking: 1 as number,
    nurseVitalsUrgencyLineInformation: {
      phoneNumbers: [{ phoneE164: '+18559611942' }],
    },
    onlyOrdersSingleServiceRequests: false,
    partnerFeedbackLink: 'https://sprinterhealth.com/feedback_oscar',
    partnerOrganizationGroup: PartnerOrganizationGroup.OSCAR,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientConfig: PATIENT_CONFIG.OSCAR,
    patientSupportEmail: 'oscarcare@hioscar.com',
    patientSupportPhoneE164: '+18559611942',
    payer: 'partner',
    paymentType: 'claims',
    priceUSD: makeDollar(49),
    productTypesRequiringRequisitions: [
      ProductType.LAB_TEST,
      ProductType.KIT_DELIVERY_AND_EDUCATION,
    ],
    referralCoordinatorID: '00e02dd0-03ab-4755-bd60-bafc2621761d',
    referralLandingPageUrlPath: 'oscar-care',
    referralVerificationPageUrlPath: 'oscar-care',
    requisitionUploadFromPartner: 'required_after_booking',
    rerouteCSREmailTo: { prod: 'oscarcare@hioscar.com', test: INTERNAL_ONLY_SPRINTER_HEALTH_EMAIL },
    rerouteFailureEmailTo: {
      prod: 'oscar.orders@sprinterhealth.com',
      test: INTERNAL_ONLY_REROUTE_FAILURE_EMAIL,
    },
    reschedulingCoordinator: 'sprinterHealth',
    schedulingTimeRangeRules: SCHEDULING_TIME_RANGE_CONFIGS.OSCAR,
    serviceRequestsFromPartner: 'skip',
    shortPartnerDisplayNameForMessaging: 'Oscar Care',
    shouldExportAppointmentsForBilling: true,
    shouldGenerateEncounterSummaryForVitals: true,
    shouldIncludeFailedAppointmentsInClaims: true,
    showDiagnosisCodes: true,
    skipCancelationFee: true,
    sprinterHealthDefaultProductTypeMappingOverride: {
      [ProductType.KIT_DELIVERY_AND_EDUCATION]: {
        defaultSprinterHealthExternalID: 'INSURE-ONE-FIT-KIT-DELIVERY-AND-EDUCATION',
        displayName: 'Colorectal screening kit delivery and education',
        message: '',
      },
    },
    supportedProductTypes: [
      ProductType.VITALS,
      ProductType.DIABETIC_RETINAL_SCAN,
      ProductType.DIABETIC_FOOT_SCREENING,
      ProductType.CARDIAC_AUSCULTATION,
      ProductType.ECG,
      ProductType.KIT_DELIVERY_AND_EDUCATION,
      ProductType.LAB_TEST,
    ],
    supportsReferrals: true,
    vertical: 'healthPlan',
    vitalsThresholds: VITALS_CONFIG.OSCAR,
    zendeskOrganizationName: 'Oscar Care',
  },
  OSCARDEMO: {
    ...GLOBAL_PARTNER_DEFAULTS,
    allowedServiceRequests: {
      QUEST: ['39027', '6517', '496'],
      SPRINTER_HEALTH: ['BLOOD-PRESSURE-CHECK', 'DIABETIC-RETINAL-SCAN', 'FIT-KIT-PICKUP'],
    },
    appointmentReportEmails: [INTERNAL_ONLY_SPRINTER_HEALTH_EMAIL],
    defaultLabOrganization: LabOrganization.QUEST,
    displayName: 'Oscar Demo',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['sprinterhealth.com']),
    encounterSummaryReportingDefaultCaseManagerEmail:
      'test+oscardemocasemanager@sprinterhealth.com',
    encounterSummaryReportingModel: 'practitionerWithDefaultCaseManagerFallBack',
    insuranceUploadFromPartner: 'skip',
    isPartnerBookingAllowed: true,
    isSelfBookingAllowed: false,
    labOrganizations: {
      [LabOrganization.QUEST]: [
        ServiceType.BLOOD,
        ServiceType.URINE,
        ServiceType.CAPILLARY_BLOOD_DRAW,
      ],
      [LabOrganization.SPRINTER_HEALTH]: [
        ServiceType.VITALS,
        ServiceType.DIABETIC_FOOT_SCREENING,
        ServiceType.CARDIAC_AUSCULTATION,
        ServiceType.ECG,
        ServiceType.DIABETIC_RETINAL_SCAN,
      ],
    },
    leadScreeningEligibilityRequirements: STANDARD_LEAD_SCREENING_AGE_REQUIREMENTS,
    maxAllowedPatientsPerBooking: 2 as number,
    onlyOrdersSingleServiceRequests: false,
    partnerOrganizationGroup: PartnerOrganizationGroup.OSCARDEMO,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientBookingAppointmentPhoneE164: '+11111111111',
    patientConfig: PATIENT_CONFIG.OSCARDEMO,
    patientSupportEmail: INTERNAL_ONLY_SPRINTER_HEALTH_EMAIL,
    patientSupportPhoneE164: CUSTOMER_SUPPORT_PHONE,
    payer: 'partner',
    paymentType: 'claims',
    priceUSD: makeDollar(49),
    requisitionUploadFromPartner: 'required',
    rerouteCSREmailTo: {
      prod: INTERNAL_ONLY_SPRINTER_HEALTH_EMAIL,
      test: INTERNAL_ONLY_SPRINTER_HEALTH_EMAIL,
    },
    reschedulingCoordinator: 'sprinterHealth',
    serviceRequestsFormatType: 'O N',
    serviceRequestsFromPartner: 'required',
    shouldGenerateEncounterSummaryForVitals: true,
    skipCancelationFee: true,
    sprinterHealthDefaultProductTypeMappingOverride: {
      [ProductType.VITALS]: {
        defaultSprinterHealthExternalID: 'BLOOD-PRESSURE-CHECK',
        displayName: 'Blood pressure collection',
        message: '',
      },
    },
    supportedProductTypes: [
      ProductType.LAB_TEST,
      ProductType.VITALS,
      ProductType.DIABETIC_RETINAL_SCAN,
      ProductType.FIT_KIT_PICKUP,
    ],
    vertical: 'healthPlan',
    vitalsThresholds: VITALS_CONFIG.OSCAR,
  },
  OSCARHEALTH: {
    // A.k.a. "Oscar Stars"
    ...GLOBAL_PARTNER_DEFAULTS,
    allowedServiceRequests: {
      QUEST: ['496', '6517', '375'],
      SPRINTER_HEALTH: [
        'BLOOD-PRESSURE-CHECK',
        'DIABETIC-RETINAL-SCAN',
        'INSURE-ONE-FIT-KIT-DELIVERY-AND-EDUCATION',
      ],
    },
    cobrandingLogo: {
      height: 28,
      uri: 'https://app.sprinterhealth.com/oscarhealth/oscarhealth_logo.png',
      width: 112,
    },
    defaultLabOrganization: LabOrganization.QUEST,
    displayName: 'Oscar Health',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['hioscar.com']),
    encounterSummaryReportingModel: 'practitionerOrThrow',
    fullPartnerDisplayNameForMessaging: 'Oscar Health',
    insuranceUploadFromPartner: 'skip',
    internalCoordinatorID: '3fa7c5ea-eab8-4dac-94b9-821608a5e69d',
    isAutofillingRequisitionsAllowed: true,
    isPartnerBookingAllowed: true as const,
    isReferralBookingAllowed: true,
    isSelfBookingAllowed: false as const,
    isTestResultsUploadRequired: true,
    labOrganizations: {
      [LabOrganization.QUEST]: [
        ServiceType.BLOOD,
        ServiceType.URINE,
        ServiceType.CAPILLARY_BLOOD_DRAW,
      ],
      [LabOrganization.LABCORP]: [
        ServiceType.BLOOD,
        ServiceType.URINE,
        ServiceType.CAPILLARY_BLOOD_DRAW,
      ],
      [LabOrganization.SPRINTER_HEALTH]: [
        ServiceType.KIT_DELIVERY_AND_EDUCATION,
        ServiceType.DIABETIC_RETINAL_SCAN,
        ServiceType.VITALS,
      ],
    },
    maxAllowedPatientsPerBooking: 1 as const,
    onlyOrdersSingleServiceRequests: false as const,
    partnerOrganizationGroup: PartnerOrganizationGroup.OSCAR,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientConfig: PATIENT_CONFIG.OSCARHEALTH,
    patientSupportEmail: 'help@hioscar.com',
    patientSupportPhoneE164: '+18556722700',
    payer: 'partner' as const,
    paymentType: 'claims',
    priceUSD: makeDollar(59),
    referralCoordinatorID: '792c6fa7-99cb-449d-9403-d5ce8c5c4619',
    referralLandingPageUrlPath: 'oscar',
    referralVerificationPageUrlPath: 'oscar',
    requisitionUploadFromPartner: 'skip',
    reschedulingCoordinator: 'sprinterHealth',
    serviceRequestsFromPartner: 'required',
    shortPartnerDisplayNameForMessaging: 'Oscar Health',
    shouldGenerateEncounterSummaryForVitals: true,
    shouldIncludeFailedAppointmentsInClaims: true,
    showDiagnosisCodes: true,
    skipCancelationFee: true as const,
    sprinterHealthDefaultProductTypeMappingOverride: {
      [ProductType.VITALS]: {
        defaultSprinterHealthExternalID: 'BLOOD-PRESSURE-CHECK',
        displayName: 'Blood pressure collection',
        message: '',
      },
      [ProductType.KIT_DELIVERY_AND_EDUCATION]: {
        defaultSprinterHealthExternalID: 'INSURE-ONE-FIT-KIT-DELIVERY-AND-EDUCATION',
        displayName: 'Colorectal screening kit delivery and education',
        message: '',
      },
    },
    supportedProductTypes: [
      ProductType.LAB_TEST,
      ProductType.KIT_DELIVERY_AND_EDUCATION,
      ProductType.VITALS,
      ProductType.DIABETIC_RETINAL_SCAN,
    ],
    supportsEDILabOrdering: false,
    supportsReferrals: true,
    vertical: 'healthPlan',
    zendeskOrganizationName: 'Oscar Health',
  },
  PENINSULADOCTOR: {
    ...STANDARD_CONCIERGE_PARTNER_BOOKING,
    displayName: 'Peninsula Doctor',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['peninsuladoctor.com']),
    partnerOrganizationGroup: PartnerOrganizationGroup.PENINSULADOCTOR,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportEmail: 'susanna@peninsuladoctor.com',
    patientSupportPhoneE164: '+16508003365',
  },
  PERPETUALCAREHOMEHEALTH: {
    ...STANDARD_HOME_HEALTH_PARTNER_BOOKING,
    displayName: 'Perpetual Care Home Health',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['pchha.com']),
    partnerOrganizationGroup: PartnerOrganizationGroup.PERPETUALCAREHOMEHEALTH,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportEmail: 'admin@pchha.com',
    patientSupportPhoneE164: '+14082628801',
    priceUSD: makeDollar(99),
  },
  PINNACLEHOMEHEALTH: {
    ...STANDARD_HOME_HEALTH_PARTNER_BOOKING,
    displayName: 'Pinnacle Home Health',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['pinnaclehha.org']),
    partnerOrganizationGroup: PartnerOrganizationGroup.PINNACLEHOMEHEALTH,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportEmail: 'admin@pinnaclehha.com',
    patientSupportPhoneE164: '+15103517595',
    priceUSD: makeDollar(99),
  },
  PRIMELIVINGHOMEHEALTH: {
    ...STANDARD_HOME_HEALTH_PARTNER_BOOKING,
    displayName: 'Primeliving Home Health',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider([
      'primelivinghomehealth.net',
    ]),
    partnerOrganizationGroup: PartnerOrganizationGroup.PRIMELIVINGHOMEHEALTH,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportEmail: 'info@primelivinghomehealth.net',
    patientSupportPhoneE164: '+15107709810',
  },
  PULSEDEMO: {
    ...STANDARD_SPECIALTY_LAB_PARTNER_BOOKING,
    defaultLabOrganization: LabOrganization.PULSE_DEMO,
    displayName: 'Pulse Demo',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider([]),
    labOrganizations: {
      [LabOrganization.PULSE_DEMO]: [
        ServiceType.BLOOD,
        ServiceType.URINE,
        ServiceType.CAPILLARY_BLOOD_DRAW,
      ],
    },
    partnerOrganizationGroup: PartnerOrganizationGroup.PULSEDEMO,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportEmail: 'demo@pulse.com',
    patientSupportPhoneE164: '+15551234567',
    requisitionUploadFromPartner: 'optional',
    serviceRequestsFormatType: 'N',
  },
  READY2NURSE: {
    ...STANDARD_HOME_HEALTH_PARTNER_BOOKING,
    displayName: 'Ready2Nurse',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider([
      'ready2nurse.com',
      'outlook.com',
    ]),
    partnerOrganizationGroup: PartnerOrganizationGroup.READY2NURSE,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportEmail: 'info@ready2nurse.com',
    patientSupportPhoneE164: '+14085403255',
    priceUSD: makeDollar(99),
  },
  ROSLINDALE: {
    ...BMC_BASE,
    canEditServicesAfterBooking: true,
    defaultLabOrganization: LabOrganization.ROSLINDALE_LAB,
    displayName: 'Roslindale',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider([]),
    ignoreAbstentionsMessaging: true,
    insuranceUploadFromPartner: 'skip',
    internalCoordinatorID: '7f9edc5f-42d3-4b58-bf8e-ead7fa13c681',
    labOrganizations: {
      [LabOrganization.ROSLINDALE_LAB]: [
        ServiceType.BLOOD,
        ServiceType.URINE,
        ServiceType.CAPILLARY_BLOOD_DRAW,
      ],
      [LabOrganization.SPRINTER_HEALTH]: [
        ServiceType.DIABETIC_RETINAL_SCAN,
        ServiceType.VITALS,
        ServiceType.FIT_KIT_PICKUP,
      ],
    },
    nurseVitalsUrgencyLineInformation: {
      phoneNumbers: [{ phoneE164: '+16173631219' }],
    },
    partnerSupportResponsibleManager: 'Sengyeon',
    patientConfig: PATIENT_CONFIG.ROSLINDALE,
    patientSupportEmail: 'sherine.germain@bmc.org',
    priceForProductsUSD: {
      DIABETIC_RETINAL_SCAN: makeDollar(129),
      FIT_KIT_PICKUP: makeDollar(59),
      LAB_TEST: makeDollar(79),
      VITALS: makeDollar(59),
    },
    requisitionUploadFromPartner: 'required_after_booking',
    shouldSendAppointmentReportToCoordinator: true,
    skipCancelationFee: true,
    supportedProductTypes: [
      ProductType.LAB_TEST,
      ProductType.DIABETIC_RETINAL_SCAN,
      ProductType.VITALS,
      ProductType.FIT_KIT_PICKUP,
    ],
    vitalsThresholds: VITALS_CONFIG.ROSLINDALE,
  },
  RUPAHEALTH: {
    ...STANDARD_SPECIALTY_LAB_SELF_BOOKING,
    defaultLabOrganization: LabOrganization.RUPA_HEALTH,
    displayName: 'Rupa Health',
    isPartnerBookingAllowed: false,
    labOrganizations: {
      [LabOrganization.RUPA_HEALTH]: [
        ServiceType.BLOOD,
        ServiceType.URINE,
        ServiceType.CAPILLARY_BLOOD_DRAW,
      ],
    },
    maxAllowedPatientsPerBooking: MAX_PATIENTS_PER_APPOINTMENT as number,
    onlyOrdersSingleServiceRequests: false,
    partnerOrganizationGroup: PartnerOrganizationGroup.RUPAHEALTH,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportEmail: 'phlebotomy@rupahealth.com',
    payer: 'patient',
    requisitionUploadFromPatient: 'required',
  },
  SEHAJHOMEHEALTH: {
    ...STANDARD_HOME_HEALTH_PARTNER_BOOKING,
    displayName: 'Sehaj Home Health',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['sehajhomehealth.com']),
    partnerOrganizationGroup: PartnerOrganizationGroup.SEHAJHOMEHEALTH,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportEmail: 'loleini@sehajhomehealth.com',
    patientSupportPhoneE164: '+15105732013',
  },
  SILVERADOBELMONTMEMORYCARE: {
    ...STANDARD_CARE_FACILITY_PARTNER_BOOKING,
    blockSharedSlotAppointments: true,
    displayName: 'Silverado Belmont Memory Care',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['silverado.com']),
    partnerOrganizationGroup: PartnerOrganizationGroup.SILVERADOBELMONTMEMORYCARE,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportEmail: 'amyda.astrero@silverado.com',
    patientSupportPhoneE164: '+16506549700',
    priceUSD: makeDollar(99),
    shouldSendRequisitionUploadReviewZDTicket: true,
  },
  SILVERLINESTAFF: {
    ...STANDARD_HOME_HEALTH_PARTNER_BOOKING,
    displayName: 'Silverline Staff',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider([]),
    partnerOrganizationGroup: PartnerOrganizationGroup.SILVERLINESTAFF,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportEmail: 'contact@silverlinestaff.com',
    patientSupportPhoneE164: '+19254765350',
    priceUSD: makeDollar(99),
  },
  SOCOLMD: {
    ...STANDARD_CONCIERGE_PARTNER_BOOKING,
    cobrandingLogo: {
      height: 28,
      uri: 'https://app.sprinterhealth.com/socolmd/socolmd_logo_32h.png',
      width: 112,
    },
    defaultLabOrganization: LabOrganization.QUEST,
    displayName: 'SocolMD',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['socolmd.com']),
    insuranceUploadFromPartner: 'optional',
    insuranceUploadFromPatient: 'optional',
    invoiceTerm: 60,
    isMultiPatientBookingAllowed: false,
    isSelfBookingAllowed: true,
    // TODO(alessio): add QUEST to labOrganizations when starting to support multiple dropoff time rules.
    // Appointments with a Cyrex kit should be dropped off at FedEx, while appointments w/o a Cyrex kit should be dropped off at Quest.
    // However, we are currently not supporting multiple dropoff, and I think we should be okay in using only Quest for now as dropoff rules are the same minus FedEx's holidays
    labOrganizations: {
      [LabOrganization.QUEST]: [
        ServiceType.BLOOD,
        ServiceType.URINE,
        ServiceType.CAPILLARY_BLOOD_DRAW,
      ],
    },
    partnerOrganizationGroup: PartnerOrganizationGroup.SOCOLMD,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportEmail: 'david@socolmd.com',
    patientSupportPhoneE164: '+13239510200',
    requisitionUploadFromPartner: 'required',
    requisitionUploadFromPatient: 'required',
  },
  SONDERHEALTHCARE: {
    ...STANDARD_HOME_HEALTH_PARTNER_BOOKING,
    displayName: 'Sonder Healthcare',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['sonderhealthcare.com']),
    partnerOrganizationGroup: PartnerOrganizationGroup.SONDERHEALTHCARE,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportEmail: 'sangeeta@sonderhealthcare.com',
    patientSupportPhoneE164: '+19168649528',
  },
  STANFORDCANCERCENTER: {
    ...STANDARD_CANCER_CENTER_PARTNER_BOOKING,
    defaultLabOrganization: LabOrganization.STANFORD_CANCER_CENTER,
    displayName: 'Stanford Cancer Center',
    domainAllowList: new Map<string, string[]>([
      ['stanfordhealthcare.org', ['STANFORDHEALTHCAREOIDC']],
    ]),
    insuranceUploadFromPartner: 'skip',
    invoiceTerm: 60,
    isGuestReschedulingAllowed: false,
    labOrganizations: {
      [LabOrganization.STANFORD_CANCER_CENTER]: [
        ServiceType.BLOOD,
        ServiceType.URINE,
        ServiceType.CAPILLARY_BLOOD_DRAW,
      ],
    },
    maxAllowedPatientsPerBooking: 1 as number,
    partnerOrganizationGroup: PartnerOrganizationGroup.STANFORDCANCERCENTER,
    partnerSupportResponsibleManager: 'Carole',
    patientConfig: PATIENT_CONFIG.STANFORDCANCERCENTER,
    patientSupportPhoneE164: '+18334447622',
    requisitionUploadFromPartner: 'required_after_booking',
    reschedulingCoordinator: 'sprinterHealth',
    skipCancelationFee: true,
    sprinterHealthSupportPhoneE164: '+14155994152',
    supportedServiceRegions: [
      'ca-bay-area-peninsula',
      'ca-bay-area-palo-alto',
      'ca-bay-area-south',
      'ca-bay-area-san-jose-east',
    ],
  },
  STANFORDRESEARCH: {
    ...GLOBAL_PARTNER_DEFAULTS,
    allowedServiceRequests: { STANFORD_RESEARCH: ['BIOBANKING-STUDY'] },
    automaticInvoiceSend: true,
    defaultLabOrganization: LabOrganization.STANFORD_RESEARCH,
    displayName: 'Stanford Research',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['stanford.edu']),
    insuranceUploadFromPartner: 'skip',
    invoiceEmailType: 'stripe',
    invoiceTerm: 30,
    isGuestReschedulingAllowed: false,
    isPartnerBookingAllowed: true,
    isSelfBookingAllowed: false,
    labOrganizations: {
      [LabOrganization.STANFORD_RESEARCH]: [
        ServiceType.BLOOD,
        ServiceType.URINE,
        ServiceType.CAPILLARY_BLOOD_DRAW,
      ],
    },
    maxAllowedPatientsPerBooking: 1 as number,
    onlyOrdersSingleServiceRequests: true,
    partnerOrganizationGroup: PartnerOrganizationGroup.STANFORDRESEARCH,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientConfig: PATIENT_CONFIG.STANFORDRESEARCH,
    patientSupportEmail: 'tlanz@stanford.edu',
    patientSupportPhoneE164: '+16506445396',
    payer: 'partner',
    paymentType: 'invoice',
    priceUSD: makeDollar(99),
    requisitionUploadFromPartner: 'required',
    reschedulingCoordinator: 'partner',
    selectSingleServiceRequestExternalID: 'BIOBANKING-STUDY',
    serviceRequestsFromPartner: 'required',
    skipCancelationFee: true,
    supportedProductTypes: [ProductType.LAB_TEST],
    supportedServiceRegions: [
      'ca-bay-area-peninsula',
      'ca-bay-area-palo-alto',
      'ca-bay-area-south',
      'ca-bay-area-san-jose-east',
    ],
    vertical: 'lifeSciences',
  },
  STORYHEALTH: {
    ...GLOBAL_PARTNER_DEFAULTS,
    automaticInvoiceSend: true,
    defaultLabOrganization: LabOrganization.INTERMOUNTAIN,
    displayName: 'Story Health',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['storyhealth.ai']),
    insuranceUploadFromPartner: 'skip',
    invoiceEmailType: 'stripe',
    invoiceTerm: 30,
    isPartnerBookingAllowed: true,
    isSelfBookingAllowed: false,
    labOrganizations: {
      [LabOrganization.INTERMOUNTAIN]: [
        ServiceType.BLOOD,
        ServiceType.URINE,
        ServiceType.CAPILLARY_BLOOD_DRAW,
      ],
    },
    maxAllowedPatientsPerBooking: 1 as number,
    onlyOrdersSingleServiceRequests: false,
    partnerOrganizationGroup: PartnerOrganizationGroup.STORYHEALTH,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportPhoneE164: '+14352644854',
    payer: 'partner',
    paymentType: 'invoice',
    priceUSD: makeDollar(79),
    requisitionUploadFromPartner: 'required',
    reschedulingCoordinator: 'partner',
    serviceRequestsFromPartner: 'skip',
    skipCancelationFee: false,
    supportedProductTypes: [ProductType.LAB_TEST],
    vertical: 'healthPlan',
  },
  STPATRICKHOMEHEALTH: {
    ...STANDARD_HOME_HEALTH_PARTNER_BOOKING,
    displayName: 'St. Patrick Home Health Providers',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['gmail.com']),
    partnerOrganizationGroup: PartnerOrganizationGroup.STPATRICKHOMEHEALTH,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportEmail: 'stpatrick440@gmail.com',
    patientSupportPhoneE164: '+14083893500',
    priceUSD: makeDollar(99),
  },
  SUNCRESTHOSPICE: {
    ...STANDARD_HOME_HEALTH_PARTNER_BOOKING,
    displayName: 'Suncrest Home Heath & Hospice',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['suncrestcare.com']),
    partnerOrganizationGroup: PartnerOrganizationGroup.SUNCRESTHOSPICE,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportEmail: 'info@suncrestcare.com',
    patientSupportPhoneE164: '+14089008838',
    shouldSendRequisitionUploadReviewZDTicket: true,
    vertical: 'hospice',
  },
  THEBODYWRX: {
    ...STANDARD_CONCIERGE_PARTNER_BOOKING,
    defaultLabOrganization: LabOrganization.QUEST,
    displayName: 'The Body WRX',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['thebodywrx.com']),
    labOrganizations: {
      [LabOrganization.QUEST]: [
        ServiceType.BLOOD,
        ServiceType.URINE,
        ServiceType.CAPILLARY_BLOOD_DRAW,
      ],
    },
    partnerOrganizationGroup: PartnerOrganizationGroup.THEBODYWRX,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportEmail: 'michelle@thebodywrx.com',
    patientSupportPhoneE164: '+16619165951',
  },
  TRAININGPARTNER: {
    ...GLOBAL_PARTNER_DEFAULTS,
    allowedServiceRequests: {
      LETS_GET_CHECKED_LAB: ['LETS-GET-CHECKED-COLON-CANCER-SCREENING'],
      QUEST: ['39027'],
      SPRINTER_HEALTH: [
        ...Object.values(PRODUCT_TYPE_CONFIGS)
          .map((config) => config.defaultSprinterHealthExternalID)
          .filter(Boolean),
        'RACE-AND-ETHNICITY-QUESTIONNAIRE',
        'SDOH-QUESTIONNAIRE',
      ],
    },
    blockSharedSlotAppointments: true,
    cobrandingLogo: {
      height: 0,
      uri: '',
      width: 0,
    },
    defaultLabOrganization: LabOrganization.QUEST,
    displayName: 'Training Partner',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['sprinterhealth.com']),
    fullPartnerDisplayNameForMessaging: 'Training Partner',
    insuranceUploadFromPartner: 'skip' as const,
    insuranceUploadFromPatient: 'skip' as const,
    isMultiPatientBookingAllowed: true as const,
    isPartnerBookingAllowed: true as const,
    isReferralBookingAllowed: true as const,
    isSelfBookingAllowed: true as const,
    labOrganizations: {
      [LabOrganization.LETS_GET_CHECKED_LAB]: [ServiceType.FIT_KIT_PICKUP],
      [LabOrganization.QUEST]: [
        ServiceType.BLOOD,
        ServiceType.URINE,
        ServiceType.CAPILLARY_BLOOD_DRAW,
      ],
      [LabOrganization.SPRINTER_HEALTH]: [
        ServiceType.CARDIAC_AUSCULTATION,
        ServiceType.CARE_NAVIGATION,
        ServiceType.COLOGUARD_KIT_EDUCATION,
        ServiceType.DIABETIC_FOOT_SCREENING,
        ServiceType.DIABETIC_RETINAL_SCAN,
        ServiceType.ECG,
        ServiceType.FIT_KIT_PICKUP,
        ServiceType.KIT_DELIVERY,
        ServiceType.KIT_DELIVERY_AND_EDUCATION,
        ServiceType.MISCELLANEOUS_SPECIMEN,
        ServiceType.SDOH_CARE_NAVIGATION_RESOURCE_GUIDANCE,
        ServiceType.RACE_AND_ETHNICITY,
        ServiceType.DEXA_SCAN_CARE_NAVIGATION_BOOKING,
        ServiceType.DEXA_SCAN_CARE_NAVIGATION_CONFIRMATION,
        ServiceType.MAMMOGRAM_CARE_NAVIGATION_BOOKING,
        ServiceType.MAMMOGRAM_CARE_NAVIGATION_CONFIRMATION,
        ServiceType.PCP_VISIT_CARE_NAVIGATION_BOOKING,
        ServiceType.PCP_VISIT_CARE_NAVIGATION_CONFIRMATION,
        ServiceType.HEIGHT_AND_WEIGHT_COLLECTION,
        ServiceType.ANNUAL_WELLNESS_VISIT_PROVIDER_CALL,
        ServiceType.PAIN_SCREENING,
        ServiceType.BONE_DENSITY_SCAN,
        ServiceType.TOPICAL_FLUORIDE,
        ServiceType.WEIGHT_COUNSELING_FOR_CHILDREN,
      ],
    },
    leadScreeningEligibilityRequirements: STANDARD_LEAD_SCREENING_AGE_REQUIREMENTS,
    maxAllowedPatientsPerBooking: Math.max(
      MAX_PATIENTS_PER_APPOINTMENT_SPECIALITY_LABS,
      MAX_PATIENTS_PER_APPOINTMENT,
      MAX_PATIENTS_PER_APPOINTMENT_D2C,
    ),
    onlyOrdersSingleServiceRequests: false as const,
    partnerOrganizationGroup: PartnerOrganizationGroup.TRAININGPARTNER,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientConfig: PATIENT_CONFIG.TRAININGPARTNER,
    patientSupportEmail: 'test+trainingpartner-patientsupport@sprinterhealth.com',
    patientSupportPhoneE164: CUSTOMER_SUPPORT_PHONE,
    payer: 'partner' as const,
    paymentType: 'claims' as const,
    priceUSD: makeDollar(0),
    referralCoordinatorID: 'd56fce3a-83fa-4389-ad42-1e846224bfcf',
    referralLandingPageUrlPath: 'training-partner',
    requisitionUploadFromPartner: 'skip' as const,
    requisitionUploadFromPatient: 'skip' as const,
    requisitionUploadFromScheduler: 'skip' as const,
    reschedulingCoordinator: 'partner' as const,
    serviceRequestsAddByDefault: {
      SPRINTER_HEALTH: [
        {
          externalID: 'HEIGHT_AND_WEIGHT_COLLECTION',
          prerequisiteServiceTypes: [ServiceType.ANNUAL_WELLNESS_VISIT_PROVIDER_CALL],
        },
        {
          externalID: 'VITALS-CHECK',
          prerequisiteServiceTypes: [ServiceType.ANNUAL_WELLNESS_VISIT_PROVIDER_CALL],
        },
      ],
    },
    serviceRequestsFromPartner: 'optional' as const,
    shortPartnerDisplayNameForMessaging: 'Training Partner',
    shouldSkipPriorLeadScreeningsQuestion: true as const,
    skipCancelationFee: true as const,
    supportedProductTypes: Object.values(ProductType),
    supportsPatientEncounterSummary: true as const,
    supportsPatientEncounterSummaryDirectMail: false as const,
    supportsReferrals: true as const,
    vertical: 'healthPlan' as const,
  },
  TRUEMED: {
    ...STANDARD_HOME_HEALTH_PARTNER_BOOKING,
    displayName: 'TrueMed',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['mytruemed.com']),
    partnerOrganizationGroup: PartnerOrganizationGroup.TRUEMED,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportEmail: 'info@mytruemed.com',
    patientSupportPhoneE164: '+16505888331',
  },
  TRUEMEDSACRAMENTO: {
    ...STANDARD_HOME_HEALTH_PARTNER_BOOKING,
    displayName: 'TrueMed Sacramento',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['mytruemed.com']),
    fullPartnerDisplayNameForMessaging: 'TrueMed',
    partnerOrganizationGroup: PartnerOrganizationGroup.TRUEMED,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportEmail: 'intake_sac@mytruemed.com',
    patientSupportPhoneE164: '+19163797790',
  },
  UCIHEALTH: {
    ...GLOBAL_PARTNER_DEFAULTS,
    allowedServiceRequests: {
      [LabOrganization.SPRINTER_HEALTH]: ['DIABETIC-RETINAL-SCAN'],
      [LabOrganization.QUEST]: [
        '496', // A1C
        '375', // eGFR/Creatinine
        '6517', // uACR/Microalbumin
        '39027', // lead screening
        '40085', // HIV Viral Load
        '11363', // Chlamydia screening
      ],
      [LabOrganization.UCI_LAB]: [
        'A1C',
        'CREGFR', // eGFR/Creatinine
        'MALCR', // Microalbumin
        'LEADBL', // lead screening
        'SHIVVL', // HIV Viral Load
        'CTNG', // Clamydia screening
      ],
      [LabOrganization.LABCORP]: [
        '001453', // A1C
        '322000', // eGFR/Creatinine
        '140285', // Microalbumin
        '717016', // lead screening
        '550430', // HIV Viral Load
        '183194', // Chlamydia screening
      ],
    },
    appointmentReportEmails: ['uci-encounter-summaries@sprinterhealth.com'],
    automaticInvoiceSend: false,
    cobrandingLogo: {
      height: 28,
      uri: 'https://app.sprinterhealth.com/ucihealth/ucihealth_logo.png',
      width: 112,
    },
    defaultLabOrganization: LabOrganization.QUEST,
    displayName: 'UCI Health',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider([
      'sprinterhealth.com',
      'hs.uci.edu',
    ]),
    fullPartnerDisplayNameForMessaging: 'UCI Health',
    insuranceUploadFromPartner: 'skip',
    internalCoordinatorID: 'e4e55b53-c71e-4e98-9a3f-54185891d117',
    invoiceEmailType: 'stripe',
    invoiceTerm: 60,
    isPartnerBookingAllowed: true,
    isReferralBookingAllowed: true,
    isSelfBookingAllowed: false,
    labOrganizations: {
      [LabOrganization.QUEST]: [
        ServiceType.BLOOD,
        ServiceType.URINE,
        ServiceType.CAPILLARY_BLOOD_DRAW,
      ],
      [LabOrganization.UCI_LAB]: [
        ServiceType.BLOOD,
        ServiceType.URINE,
        ServiceType.CAPILLARY_BLOOD_DRAW,
      ],
      [LabOrganization.LABCORP]: [
        ServiceType.BLOOD,
        ServiceType.URINE,
        ServiceType.CAPILLARY_BLOOD_DRAW,
      ],
      [LabOrganization.SPRINTER_HEALTH]: [ServiceType.DIABETIC_RETINAL_SCAN],
    },
    leadScreeningEligibilityRequirements: HEDIS_LEAD_SCREENING_AGE_REQUIREMENTS,
    maxAllowedPatientsPerBooking: 1 as const,
    onlyOrdersSingleServiceRequests: false as const,
    partnerOrganizationGroup: PartnerOrganizationGroup.UCI,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientConfig: PATIENT_CONFIG.UCIHEALTH,
    patientSupportPhoneE164: '+17144567002',
    payer: 'partner',
    paymentType: 'invoice',
    priceForProductsUSD: {
      [ProductType.LAB_TEST]: makeDollar(20),
      [ProductType.KIT_DELIVERY_AND_EDUCATION]: makeDollar(20),
      [ProductType.FIT_KIT_PICKUP]: makeDollar(20),
      [ProductType.DIABETIC_RETINAL_SCAN]: makeDollar(50),
    },
    priceUSD: makeDollar(79),
    referralCoordinatorID: '7a53c57b-fdf3-4896-947d-2280db1effbd',
    // TODO(wendy): add fit kit; clickup task: 86ayhe9wb
    referralLandingPageUrlPath: 'ucihealth-medicaid',
    requisitionUploadFromPartner: 'required_after_booking',
    reschedulingCoordinator: 'sprinterHealth',
    serviceRequestsFormatType: 'O N',
    serviceRequestsFromPartner: 'optional',
    shortPartnerDisplayNameForMessaging: 'UCI Health',
    shouldSkipPriorLeadScreeningsQuestion: true,
    showDiagnosisCodes: true,
    skipCancelationFee: true as const,
    supportedProductTypes: [ProductType.DIABETIC_RETINAL_SCAN, ProductType.LAB_TEST],
    supportsReferrals: true,
    vertical: 'healthSystem',
    zendeskOrganizationName: 'UCI Health',
  },
  UCLAHEALTH: {
    ...GLOBAL_PARTNER_DEFAULTS,
    allowedServiceRequests: {
      [LabOrganization.SPRINTER_HEALTH]: [
        'BLOOD-PRESSURE-CHECK',
        'DIABETIC-RETINAL-SCAN',
        'MAMMOGRAM_CARE_NAVIGATION_BOOKING',
        'MAMMOGRAM_CARE_NAVIGATION_CONFIRMATION',
        'SDOH-QUESTIONNAIRE',
      ],
      [LabOrganization.QUEST]: [
        '496', // A1C
        '375', // eGFR/Creatinine
        '6517', // uACR/Microalbumin
        '39027', // lead screening
      ],
    },
    appointmentReportEmails: ['phi-ucla-encounter-summaries@sprinterhealth.com'],
    automaticInvoiceSend: false,
    cobrandingLogo: {
      height: 32,
      uri: 'https://app.sprinterhealth.com/uclahealth/uclahealth_logo.png',
      width: 112,
    },
    defaultLabOrganization: LabOrganization.QUEST,
    displayName: 'UCLA Health',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider([
      'sprinterhealth.com',
      'mednet.ucla.edu',
    ]),
    encounterSummaryReportingModel: 'partnerAndPractitioner',
    fullPartnerDisplayNameForMessaging: 'UCLA Health',
    insuranceUploadFromPartner: 'skip',
    internalCoordinatorID: '1d2f72e1-9cbf-42f3-9d1b-9214ea33db25',
    invoiceEmailType: 'stripe',
    invoiceTerm: 30,
    isAutofillingRequisitionsAllowed: true as const,
    isPartnerBookingAllowed: true as const,
    isReferralBookingAllowed: true as const,
    isSelfBookingAllowed: false as const,
    isTestResultsUploadRequired: true,
    labOrganizations: {
      [LabOrganization.QUEST]: [ServiceType.BLOOD, ServiceType.URINE],
      [LabOrganization.SPRINTER_HEALTH]: [
        ServiceType.VITALS,
        ServiceType.DIABETIC_RETINAL_SCAN,
        ServiceType.MAMMOGRAM_CARE_NAVIGATION_BOOKING,
        ServiceType.MAMMOGRAM_CARE_NAVIGATION_CONFIRMATION,
        ServiceType.KIT_DELIVERY_AND_EDUCATION,
        ServiceType.SDOH_QUESTIONNAIRE,
      ],
    },
    leadScreeningEligibilityRequirements: STANDARD_LEAD_SCREENING_AGE_REQUIREMENTS,
    maxAllowedPatientsPerBooking: 1 as const,
    onlyOrdersSingleServiceRequests: false as const,
    partnerOrganizationGroup: PartnerOrganizationGroup.UCLA,
    // TODO(86b2xg140): after we've created a profile for Miranda, add her as the responsible manager
    partnerSupportResponsibleManager: 'Madi',
    patientConfig: PATIENT_CONFIG.UCLAHEALTH,
    payer: 'partner',
    paymentType: 'invoice',
    priceForProductsUSD: {
      [ProductType.VITALS]: makeDollar(10),
      [ProductType.LAB_TEST]: makeDollar(20),
      [ProductType.KIT_DELIVERY_AND_EDUCATION]: makeDollar(20),
      [ProductType.DIABETIC_RETINAL_SCAN]: makeDollar(50),
      [ProductType.QUESTIONNAIRES]: makeDollar(20),
      [ProductType.MAMMOGRAM_CARE_NAVIGATION_BOOKING]: makeDollar(90),
      [ProductType.MAMMOGRAM_CARE_NAVIGATION_CONFIRMATION]: makeDollar(90),
    },
    priceUSD: makeDollar(79),
    referralCoordinatorID: '9b8e205c-c549-43a6-bc67-ee307296961f',
    referralLandingPageUrlPath: 'ucla-medicaid',
    requisitionUploadFromPartner: 'skip' as const,
    reschedulingCoordinator: 'sprinterHealth' as const,
    serviceRequestsAddByDefault: {
      SPRINTER_HEALTH: [
        {
          externalID: 'SDOH-QUESTIONNAIRE',
          serviceSuccessfullyProvidedCutoffPeriod: 'ytd' as const,
        },
      ],
    },
    serviceRequestsFromPartner: 'required',
    shortPartnerDisplayNameForMessaging: 'UCLA Health',
    shouldSkipPriorLeadScreeningsQuestion: false,
    showDiagnosisCodes: true,
    skipCancelationFee: true as const,
    supportedProductTypes: [
      ProductType.VITALS,
      ProductType.LAB_TEST,
      ProductType.KIT_DELIVERY_AND_EDUCATION,
      ProductType.DIABETIC_RETINAL_SCAN,
      ProductType.QUESTIONNAIRES,
      ProductType.MAMMOGRAM_CARE_NAVIGATION_BOOKING,
      ProductType.MAMMOGRAM_CARE_NAVIGATION_CONFIRMATION,
    ],
    supportsEDILabOrdering: true as const,
    supportsReferrals: true,
    vertical: 'healthSystem',
    zendeskOrganizationName: 'UCLA Health',
  },
  UCSF: {
    ...STANDARD_HEALTH_SYSTEM_PARTNER_BOOKING,
    defaultLabOrganization: LabOrganization.UCSF_BLOOD_DRAW_LAB,
    displayName: 'UCSF',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['ucsf.edu']),
    insuranceUploadFromPartner: 'optional',
    labOrganizations: {
      [LabOrganization.UCSF_BLOOD_DRAW_LAB]: [
        ServiceType.BLOOD,
        ServiceType.URINE,
        ServiceType.CAPILLARY_BLOOD_DRAW,
      ],
    },
    partnerOrganizationGroup: PartnerOrganizationGroup.UCSF,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportPhoneE164: '+14155147293',
  },
  UHCMA: {
    ...GLOBAL_PARTNER_DEFAULTS,
    allowedServiceRequests: {
      QUEST: [
        '375', // eGFR/Creatinine
        '496', //  Quest A1C
        '6517', // uACR/Microalbumin
      ],
      SPRINTER_HEALTH: [
        'RACE-AND-ETHNICITY-QUESTIONNAIRE',
        'SDOH-QUESTIONNAIRE',
        'BLOOD-PRESSURE-CHECK',
      ],
    },
    blockSharedSlotAppointments: false,
    cobrandingLogo: {
      height: 28,
      uri: 'https://app.sprinterhealth.com/uhc/uhc_logo.png',
      width: 94,
    },
    defaultLabOrganization: LabOrganization.QUEST,
    displayName: 'UnitedHealthcare - Massachusetts',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['uhc.com']),
    encounterSummaryReportingModel: 'practitionerOrThrow',
    fullPartnerDisplayNameForMessaging: 'UnitedHealthcare',
    insuranceUploadFromPartner: 'skip' as const,
    internalCoordinatorID: 'b2968343-a68d-4ad4-9259-9503bdaa3b93',
    isAutofillingRequisitionsAllowed: true,
    isPartnerBookingAllowed: true,
    isReferralBookingAllowed: true,
    isSelfBookingAllowed: false as const,
    isTestResultsUploadRequired: true,
    labOrganizations: {
      [LabOrganization.QUEST]: [ServiceType.BLOOD, ServiceType.URINE],
      [LabOrganization.SPRINTER_HEALTH]: [
        ServiceType.VITALS,
        ServiceType.RACE_AND_ETHNICITY,
        ServiceType.SDOH_QUESTIONNAIRE,
      ],
    },
    maxAllowedPatientsPerBooking: 1,
    onlyOrdersSingleServiceRequests: false,
    partnerOrganizationGroup: PartnerOrganizationGroup.UHC,
    partnerSupportResponsibleManager: 'Ryan',
    patientConfig: PATIENT_CONFIG.UHCMA,
    patientSupportPhoneE164: '+18666334454',
    payer: 'partner',
    paymentType: 'claims',
    priceUSD: makeDollar(161),
    productTypesRequiringRequisitions: [ProductType.LAB_TEST],
    referralCoordinatorID: '9007cf0e-e28a-4074-970f-99062e7c13b8',
    referralLandingPageUrlPath: 'uhc-ma',
    referralVerificationPageUrlPath: 'uhc-ma',
    requisitionUploadFromPartner: 'required_after_booking',
    reschedulingCoordinator: 'sprinterHealth',
    serviceRequestsAddByDefault: {
      SPRINTER_HEALTH: [
        {
          externalID: 'RACE-AND-ETHNICITY-QUESTIONNAIRE',
          serviceSuccessfullyProvidedCutoffPeriod: 'lifetime' as const,
        },
        {
          externalID: 'SDOH-QUESTIONNAIRE',
          serviceSuccessfullyProvidedCutoffPeriod: 'ytd' as const,
        },
      ],
    },
    serviceRequestsFromPartner: 'required',
    shortPartnerDisplayNameForMessaging: 'UnitedHealthcare',
    shouldExportAppointmentsForBilling: true,
    shouldGenerateEncounterSummaryForVitals: true,
    showDiagnosisCodes: true,
    skipCancelationFee: true,
    sprinterHealthDefaultProductTypeMappingOverride: {
      [ProductType.VITALS]: {
        defaultSprinterHealthExternalID: 'BLOOD-PRESSURE-CHECK',
        displayName: 'Blood pressure collection',
        message: '',
      },
    },
    supportedProductTypes: [ProductType.VITALS, ProductType.LAB_TEST, ProductType.QUESTIONNAIRES],
    supportsEDILabOrdering: true,
    supportsPatientEncounterSummary: true as const,
    supportsPatientEncounterSummaryDirectMail: true as const,
    supportsReferrals: true,
    vertical: 'healthPlan' as const,
    zendeskOrganizationName: 'UnitedHealthcare - Massachusetts',
  },
  VNHOMEHEALTHCARE: {
    ...STANDARD_HOME_HEALTH_PARTNER_BOOKING,
    displayName: 'VN Home Health Care',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['gmail.com']),
    partnerOrganizationGroup: PartnerOrganizationGroup.VNHOMEHEALTHCARE,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportEmail: 'vnhomehealth@gmail.com',
    patientSupportPhoneE164: '+14089980550',
    priceUSD: makeDollar(99),
  },
  WELLSENSE: {
    ...GLOBAL_PARTNER_DEFAULTS,
    allowedServiceRequests: {
      QUEST: [
        '496', //  Quest A1C
        '375', // Quest eGFR
        '6517', // Quest uACR
      ],
      SPRINTER_HEALTH: ['BLOOD-PRESSURE-CHECK', 'DIABETIC-RETINAL-SCAN'],
    },
    cobrandingLogo: {
      height: 28,
      uri: 'https://app.sprinterhealth.com/wellsense/wellsense_logo.png',
      width: 92,
    },
    defaultLabOrganization: LabOrganization.QUEST,
    displayName: 'WellSense',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['wellsense.org']),
    encounterSummaryReportingDefaultCaseManagerEmail: 'SCO_CareManagement@wellsense.org',
    encounterSummaryReportingModel: 'practitionerWithDefaultCaseManagerFallBack',
    fullPartnerDisplayNameForMessaging: 'WellSense Senior Care Options',
    insuranceUploadFromPartner: 'skip',
    internalCoordinatorID: 'd527d650-1605-4c95-884e-8f332fe5bb03',
    isAutofillingRequisitionsAllowed: true,
    isPartnerBookingAllowed: true,
    isReferralBookingAllowed: true,
    isSelfBookingAllowed: false,
    isTestResultsUploadRequired: true,
    labOrganizations: {
      [LabOrganization.QUEST]: [ServiceType.BLOOD, ServiceType.URINE],
      [LabOrganization.SPRINTER_HEALTH]: [ServiceType.VITALS, ServiceType.DIABETIC_RETINAL_SCAN],
    },
    maxAllowedPatientsPerBooking: 1 as number,
    onlyOrdersSingleServiceRequests: false,
    partnerOrganizationGroup: PartnerOrganizationGroup.WELLSENSE,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientConfig: PATIENT_CONFIG.WELLSENSE,
    patientSupportEmail: 'MemberQuestions@wellsense.org',
    patientSupportPhoneE164: '+18558338125',
    payer: 'partner',
    paymentType: 'claims',
    priceUSD: makeDollar(79),
    referralCoordinatorID: '92d68dcb-98e4-40b5-965f-9a8922ceb8cc',
    referralLandingPageUrlPath: 'wellsense-ma',
    referralVerificationPageUrlPath: 'wellsense-ma',
    requisitionUploadFromPartner: 'skip',
    reschedulingCoordinator: 'partner',
    schedulingTimeRangeRules: SCHEDULING_TIME_RANGE_CONFIGS.WELLSENSE,
    serviceRequestsFromPartner: 'required',
    shortPartnerDisplayNameForMessaging: 'WellSense',
    shouldExportAppointmentsForBilling: true,
    shouldGenerateEncounterSummaryForVitals: true,
    shouldIncludeFailedAppointmentsInClaims: true,
    showDiagnosisCodes: true,
    skipCancelationFee: true,
    sprinterHealthDefaultProductTypeMappingOverride: {
      [ProductType.VITALS]: {
        defaultSprinterHealthExternalID: 'BLOOD-PRESSURE-CHECK',
        displayName: 'Blood pressure collection',
        message: '',
      },
    },
    supportedProductTypes: [
      ProductType.VITALS,
      ProductType.DIABETIC_RETINAL_SCAN,
      ProductType.LAB_TEST,
    ],
    supportsEDILabOrdering: false,
    supportsReferrals: true,
    vertical: 'healthPlan',
    vitalsThresholds: VITALS_CONFIG.WELLSENSE,
    zendeskOrganizationName: 'Wellsense - MA (Medicare)',
  },
  WELLSENSENHMEDICAID: {
    ...GLOBAL_PARTNER_DEFAULTS,
    allowedServiceRequests: {
      QUEST: [
        '496', //  Quest A1C
        '39027', // Quest lead screening
      ],
      SPRINTER_HEALTH: ['BLOOD-PRESSURE-CHECK'],
    },
    cobrandingLogo: {
      height: 28,
      uri: 'https://app.sprinterhealth.com/wellsense/wellsense_logo.png',
      width: 92,
    },
    defaultLabOrganization: LabOrganization.QUEST,
    displayName: 'WellSense (NH Medicaid)',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['sprinterhealth.com']),
    encounterSummaryReportingDefaultCaseManagerEmail: 'NHCare.Management@Wellsense.org',
    encounterSummaryReportingModel: 'practitionerWithDefaultCaseManagerFallBack',
    fullPartnerDisplayNameForMessaging: 'WellSense Health Plan',
    insuranceUploadFromPartner: 'skip',
    internalCoordinatorID: '3c980855-69f3-4ec5-ad33-fda56472b397',
    isAutofillingRequisitionsAllowed: true,
    isPartnerBookingAllowed: true,
    isReferralBookingAllowed: true,
    isSelfBookingAllowed: false as const,
    isTestResultsUploadRequired: true,
    labOrganizations: {
      [LabOrganization.QUEST]: [ServiceType.BLOOD, ServiceType.CAPILLARY_BLOOD_DRAW],
      [LabOrganization.SPRINTER_HEALTH]: [ServiceType.VITALS],
    },
    leadScreeningEligibilityRequirements: STANDARD_LEAD_SCREENING_AGE_REQUIREMENTS,
    maxAllowedPatientsPerBooking: 1 as const,
    onlyOrdersSingleServiceRequests: false as const,
    partnerOrganizationGroup: PartnerOrganizationGroup.WELLSENSENH,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientConfig: PATIENT_CONFIG.WELLSENSENHMEDICAID,
    patientSupportEmail: 'NHmembers@wellsense.org',
    patientSupportPhoneE164: '+18779571300',
    payer: 'partner',
    paymentType: 'claims',
    priceUSD: makeDollar(79),
    referralCoordinatorID: 'e4d29728-47d2-4759-8c45-87cffca2fc12',
    referralLandingPageUrlPath: 'wellsense-nh-medicaid',
    referralVerificationPageUrlPath: 'wellsense-nh-medicaid',
    requisitionUploadFromPartner: 'required_after_booking',
    reschedulingCoordinator: 'sprinterHealth',
    serviceRequestsFromPartner: 'required',
    shortPartnerDisplayNameForMessaging: 'WellSense',
    shouldGenerateEncounterSummaryForVitals: true,
    shouldIncludeFailedAppointmentsInClaims: true,
    shouldSkipPriorLeadScreeningsQuestion: true,
    skipCancelationFee: true as const,
    sprinterHealthDefaultProductTypeMappingOverride: {
      [ProductType.VITALS]: {
        defaultSprinterHealthExternalID: 'BLOOD-PRESSURE-CHECK',
        displayName: 'Blood pressure collection',
        message: '',
      },
    },
    supportedProductTypes: [ProductType.VITALS, ProductType.LAB_TEST],
    supportsEDILabOrdering: false,
    supportsReferrals: true,
    vertical: 'healthPlan',
    zendeskOrganizationName: 'Wellsense - NH (Medicaid)',
  },
  WELLSENSENHMEDICARE: {
    ...GLOBAL_PARTNER_DEFAULTS,
    allowedServiceRequests: {
      QUEST: [
        '496', //  Quest A1C
        '375', // Quest eGFR
        '6517', // Quest uACR
      ],
      SPRINTER_HEALTH: ['BLOOD-PRESSURE-CHECK', 'DIABETIC-RETINAL-SCAN'],
    },
    cobrandingLogo: {
      height: 28,
      uri: 'https://app.sprinterhealth.com/wellsense/wellsense_logo.png',
      width: 92,
    },
    defaultLabOrganization: LabOrganization.QUEST,
    displayName: 'WellSense (NH Medicare)',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['sprinterhealth.com']),
    encounterSummaryReportingDefaultCaseManagerEmail: 'NHCM.MedAdv@wellsense.org',
    encounterSummaryReportingModel: 'practitionerWithDefaultCaseManagerFallBack',
    fullPartnerDisplayNameForMessaging: 'WellSense Health Plan',
    insuranceUploadFromPartner: 'skip',
    internalCoordinatorID: '02d3c709-7ac3-4463-b743-04c742aabd59',
    isAutofillingRequisitionsAllowed: true,
    isPartnerBookingAllowed: true,
    isReferralBookingAllowed: true,
    isSelfBookingAllowed: false as const,
    isTestResultsUploadRequired: true,
    labOrganizations: {
      [LabOrganization.QUEST]: [ServiceType.BLOOD, ServiceType.URINE],
      [LabOrganization.SPRINTER_HEALTH]: [ServiceType.DIABETIC_RETINAL_SCAN, ServiceType.VITALS],
    },
    maxAllowedPatientsPerBooking: 1 as const,
    onlyOrdersSingleServiceRequests: false as const,
    partnerOrganizationGroup: PartnerOrganizationGroup.WELLSENSENH,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientConfig: PATIENT_CONFIG.WELLSENSENHMEDICARE,
    patientSupportPhoneE164: '+18558338128',
    payer: 'partner',
    paymentType: 'claims',
    priceUSD: makeDollar(79),
    referralCoordinatorID: 'd0ef120c-f18a-4af3-8b4e-88e6491cde07',
    referralLandingPageUrlPath: 'wellsense-nh-medicare',
    referralVerificationPageUrlPath: 'wellsense-nh-medicare',
    requisitionUploadFromPartner: 'required_after_booking',
    reschedulingCoordinator: 'sprinterHealth',
    serviceRequestsFromPartner: 'required',
    shortPartnerDisplayNameForMessaging: 'WellSense',
    shouldGenerateEncounterSummaryForVitals: true,
    shouldIncludeFailedAppointmentsInClaims: true,
    showDiagnosisCodes: true,
    skipCancelationFee: true as const,
    sprinterHealthDefaultProductTypeMappingOverride: {
      [ProductType.VITALS]: {
        defaultSprinterHealthExternalID: 'BLOOD-PRESSURE-CHECK',
        displayName: 'Blood pressure collection',
        message: '',
      },
    },
    supportedProductTypes: [
      ProductType.VITALS,
      ProductType.DIABETIC_RETINAL_SCAN,
      ProductType.LAB_TEST,
    ],
    supportsEDILabOrdering: false,
    supportsReferrals: true,
    vertical: 'healthPlan',
    zendeskOrganizationName: 'Wellsense - NH (Medicare)',
  },
  YOURCHOICEHOMEHEALTH: {
    ...STANDARD_HOME_HEALTH_PARTNER_BOOKING,
    displayName: 'Your Choice Home Health',
    domainAllowList: normalizeEmailDomainsAndAssociateWithNativeProvider(['yahoo.com']),
    partnerOrganizationGroup: PartnerOrganizationGroup.YOURCHOICEHOMEHEALTH,
    partnerSupportResponsibleManager: 'Sengyeon',
    patientSupportEmail: 'yourchoice_h@yahoo.com',
    patientSupportPhoneE164: '+19164766037',
  },
};

const DIRECT_TO_CONSUMER_CONFIG: PartnerConfig = {
  ...GLOBAL_PARTNER_DEFAULTS,
  defaultLabOrganization: STANDARD_REFERENCE_LAB_ORGANIZATION,
  // TODO(dhruv) Set displayName to D2C, ensuring all usages of displayName are
  // valid & contextually make sense.
  displayName: '',
  insuranceUploadFromPatient: 'required',
  isGuestReschedulingAllowed: false,
  isMultiPatientBookingAllowed: true,
  isPartnerBookingAllowed: false,
  isSelfBookingAllowed: true,
  labOrganizations: STANDARD_REFERENCE_LAB_ORGANIZATION_SETTINGS,
  maxAllowedPatientsPerBooking: MAX_PATIENTS_PER_APPOINTMENT_D2C as number,
  onlyOrdersSingleServiceRequests: false,
  partnerOrganizationGroup: PartnerOrganizationGroup.D2C,
  partnerSupportResponsibleManager: 'Sengyeon',
  payer: 'patient',
  requisitionUploadFromPatient: 'required',
  schedulingTimeRangeRules: D2C_TIME_RANGE_CONFIGS,
  skipCancelationFee: true,
  supportedProductTypes: [ProductType.LAB_TEST],
  vertical: 'directToConsumer' as const,
};

export default function getPartnerConfig(
  partnerOrganization: PartnerOrganization | null | undefined,
): PartnerConfig {
  return partnerOrganization == null
    ? DIRECT_TO_CONSUMER_CONFIG
    : PARTNER_CONFIGS[partnerOrganization];
}

export function getPartnersForPartnerOrganizationGroup(
  partnerOrganizationGroup: PartnerOrganizationGroup,
): PartnerOrganization[] {
  return Object.entries(PARTNER_CONFIGS)
    .filter(([, partnerConfig]) => {
      return partnerConfig.partnerOrganizationGroup === partnerOrganizationGroup;
    })
    .map(([partnerOrganization]) => partnerOrganization as PartnerOrganization);
}

export function isPartnerConfigPartnerInvoiceable(partnerConfig: PartnerConfig): boolean {
  return partnerConfig.payer === 'partner' && partnerConfig.paymentType === 'invoice';
}

export const mapAllowedDisallowedServiceRequests = (
  serviceRequests: { [key in LabOrganization]?: string[] },
): { externalID: string; labOrganization: LabOrganization }[] => {
  return Object.keys(serviceRequests).flatMap((labOrganization) =>
    (serviceRequests?.[labOrganization as LabOrganization] ?? []).map(
      (serviceRequestExternalID) => ({
        externalID: serviceRequestExternalID,
        labOrganization: labOrganization as LabOrganization,
      }),
    ),
  );
};
