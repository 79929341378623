// Note: be careful when logging here; please do not log sensitive data such as tokens

import { EventEmitter } from 'events';

// Unfortunately, aws-amplify types are incorrect https://github.com/aws-amplify/amplify-js/issues/4927
import { CognitoUserInterface } from '@aws-amplify/ui-components';
import { captureException } from '@sentry/react';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { Amplify, Auth } from 'aws-amplify';
import { PartnerOrganization } from 'khshared/API';
import { SUPPORT_EMAIL } from 'khshared/constants';
import getErrorLoggingFields from 'khshared/getErrorLoggingFields';
import getPartnerOrganizationsFromCognitoGroups from 'khshared/getPartnerOrganizationsFromCognitoGroups';
import getPartnerConfig from 'khshared/metadata/getPartnerConfig';
import { Logger } from 'pino';
import { Platform } from 'react-native';

import awsExports from '../aws-exports';
import {
  fingerprintSignInSuccessful,
  genLastSession,
  genSignInSession,
  genSignOutSession,
} from '../xapis/AuthXAPI';

// The `?` is required for jest & android to succeed as window.location is undefined in those environments.
// oauth is only used for the partners so we don't actually need the values set correctly for android
awsExports.oauth.redirectSignIn = `${window.location?.origin}/`;
awsExports.oauth.redirectSignOut = `${window.location?.origin}/`;

// Configure Amplify
Amplify.configure(awsExports);

export class NoSignedInSessionError extends Error {
  constructor() {
    super('You are not signed in.');
    this.name = 'NoSignedInSessionError';
  }
}

// Unfortunately, aws-amplify does not export its ICredentials type so we have to extract it.
type Await<T> = T extends PromiseLike<infer U> ? U : T;
type ICredentials = Await<ReturnType<typeof Auth.currentUserCredentials>>;

let session: CognitoUserSession | null = null;
let credentials: ICredentials | null = null;
let cognitoUser: CognitoUserInterface | null = null;

const authEventEmitter = new EventEmitter();

async function genSessionAndCredentials() {
  /*
   * On react native android, encountered "Auth Error" if getting user session or credentials
   * when the user not logged in.
   *
   * Therefore, during Android initialization, we want to try get existing session if possible first.
   */

  try {
    [session, credentials] = await Promise.all([
      Auth.currentSession(),
      Auth.currentUserCredentials(),
    ]);

    authEventEmitter.emit('signIn');
  } catch (e: unknown) {
    captureException(e);
  }
}

function getSessionOrNull(): CognitoUserSession | null {
  return session;
}

function getViewerIDOrThrow(): string {
  if (session == null) throw new NoSignedInSessionError();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
  return session.getIdToken().payload['custom:reference_id'];
}

function getViewerIDOrNull(): string | null {
  if (session == null) return null;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
  return session.getIdToken().payload['custom:reference_id'];
}

function getViewerIdentityIDOrThrow(): string {
  if (credentials == null) throw new NoSignedInSessionError();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return credentials.identityId;
}

function getViewerIdentityIDOrNull(): string | null {
  if (credentials == null) return null;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return credentials.identityId;
}

function getEmailOrNull(): string | null {
  if (session == null) return null;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return session.getIdToken().payload.email;
}

function getEmailOrThrow(): string {
  if (session == null) throw new NoSignedInSessionError();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return session.getIdToken().payload.email;
}

function getGroupsOrNull(): string[] | null {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return session?.getIdToken().payload['cognito:groups'] ?? null;
}

function getGroupsOrThrow(): string[] {
  if (session == null) throw new NoSignedInSessionError();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return session.getIdToken().payload['cognito:groups'];
}

function getPartnerOrganizations(): PartnerOrganization[] {
  const viewerCognitoGroups = session?.getIdToken().payload['cognito:groups'] as string[];
  return getPartnerOrganizationsFromCognitoGroups(viewerCognitoGroups ?? []);
}

/**
 * As a scheduler, when this is called, the intent is to retrieve the full list of PartnerOrganizations.
 */
function getPartnerOrganizationsAsScheduler(): PartnerOrganization[] {
  const partnerOrganizationList = Object.values(PartnerOrganization);

  return partnerOrganizationList;
}

function getSomePartnerOrganizationsOrThrow(
  ...params: Parameters<typeof getPartnerOrganizations>
): NonNullable<ReturnType<typeof getPartnerOrganizations>> {
  if (session == null) throw new NoSignedInSessionError();

  const partnerOrganizations = getPartnerOrganizations(...params);

  if (partnerOrganizations.length <= 0)
    throw new Error(
      `Your account is not authorized for access to these patients. Please reach out to Sprinter Health Support at ${SUPPORT_EMAIL}.`,
    );

  return partnerOrganizations;
}

function isSignedIn(): boolean {
  return session != null;
}

function getCognitoUserOrNull(): CognitoUserInterface | null {
  return cognitoUser;
}

async function genSignIn(logger: Logger, email: string, password: string): Promise<void> {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  cognitoUser = await genSignInSession(email, password);

  await genSessionAndCredentials();

  logger.info({
    eventName: 'cognitoUserSignIn',
    signInMethod: 'credentials',
    viewerGroups: getGroupsOrNull(),
    viewerID: getViewerIDOrNull(),
    viewerEmail: getEmailOrNull(),
    viewerPartnerOrganizations: getPartnerOrganizations(),
    viewerPartnerOrganizationGroup: getPartnerConfig(getPartnerOrganizations()[0])
      .partnerOrganizationGroup,
  });
}

async function genSignInUsingFingerprint(logger: Logger): Promise<boolean> {
  if (await fingerprintSignInSuccessful(logger)) {
    await genSessionAndCredentials();
    logger.info({
      eventName: 'cognitoUserSignIn',
      signInMethod: 'fingerprint',
      viewerGroups: getGroupsOrNull(),
      viewerID: getViewerIDOrNull(),
      viewerEmail: getEmailOrNull(),
      viewerPartnerOrganizations: getPartnerOrganizations(),
      viewerPartnerOrganizationGroup: getPartnerConfig(getPartnerOrganizations()[0])
        .partnerOrganizationGroup,
    });
    return true;
  }
  return false;
}

async function genRefreshSession(logger: Logger): Promise<boolean> {
  // On Android, session is not saved/maintained between app activity is killed and started again, therefore we want to refresh session instead of prompting sign in again.
  try {
    session = await genLastSession();
    if (session == null) return false;

    await genSessionAndCredentials();
    logger.info({
      eventName: 'cognitoUserRefreshSession',
      viewerGroups: getGroupsOrNull(),
      viewerID: getViewerIDOrNull(),
      viewerEmail: getEmailOrNull(),
      viewerPartnerOrganization: getPartnerOrganizations(),
      viewerPartnerOrganizationGroup: getPartnerConfig(getPartnerOrganizations()[0])
        .partnerOrganizationGroup,
    });
    return true;
  } catch (e) {
    logger.error({
      eventName: 'refreshSessionError',
      ...getErrorLoggingFields(e),
    });
    captureException(e);
  }
  return false;
}

async function genSignOut(logger: Logger): Promise<void> {
  try {
    await genSignOutSession();

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const viewerEmail: string | null = session?.getIdToken().payload.email ?? null;
    logger.info({
      eventName: 'cognitoUserSignOut',
      viewerEmail,
    });
    session = null;
    credentials = null;
    authEventEmitter.emit('signOut');
  } catch (e: unknown) {
    logger.error({
      eventName: 'cognitoUserSignOutError',
      ...getErrorLoggingFields(e),
    });
    captureException(e);
  }
}

async function genInitialize(logger: Logger): Promise<void> {
  try {
    if (Platform.OS === 'android') {
      await genRefreshSession(logger);
    } else {
      await genSessionAndCredentials();
    }
  } catch (e: unknown) {
    logger.error({
      eventName: 'genInitializeError',
      ...getErrorLoggingFields(e),
    });
    captureException(e);
  }
}

export default {
  getSessionOrNull,
  getViewerIDOrThrow,
  getViewerIDOrNull,
  getViewerIdentityIDOrThrow,
  getViewerIdentityIDOrNull,
  getEmailOrNull,
  getEmailOrThrow,
  getGroupsOrNull,
  getGroupsOrThrow,
  getPartnerOrganizations,
  getSomePartnerOrganizationsOrThrow,
  getPartnerOrganizationsAsScheduler,
  isSignedIn,
  genSignIn,
  genSignInUsingFingerprint,
  genRefreshSession,
  genSignOut,
  genInitialize,
  getCognitoUserOrNull,
  eventEmitter: authEventEmitter,
};
