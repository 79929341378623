import KHColors from 'khshared/KHColors';
import React from 'react';
import { Pressable, StyleProp, Text, TextStyle, ViewStyle } from 'react-native';

import KHIcon from './KHIcon';

const styles = {
  view: {
    position: 'absolute',
    bottom: 16,
    left: 16,
    backgroundColor: KHColors.toastBackground,
    borderRadius: 8,
    paddingVertical: 4,
    paddingHorizontal: 8,
  } as ViewStyle,
  closableView: {
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
  } as ViewStyle,
  error: {
    backgroundColor: KHColors.toastBackgroundError,
  } as ViewStyle,
  label: {
    color: KHColors.toastLabel,
  } as TextStyle,
  labelError: {
    color: KHColors.textError,
  } as TextStyle,
};

interface Props {
  label: string;
  error?: boolean;
  onPress?: () => void;
  onClose?: () => void;
  style?: StyleProp<TextStyle>;
  testID?: string;
  closable?: boolean;
  labelStyle?: StyleProp<TextStyle>;
}

export default function KHToast({
  label,
  error = false,
  onPress,
  style,
  testID,
  closable = false,
  onClose,
  labelStyle,
}: Props): JSX.Element | null {
  return (
    <Pressable
      style={[styles.view, error && styles.error, closable && styles.closableView, style]}
      onPress={onPress}
      testID={testID}
    >
      <Text style={[styles.label, error && styles.labelError, labelStyle]}>{label}</Text>
      {closable && (
        <Pressable onPress={onClose}>
          <KHIcon
            color={error ? KHColors.textError : KHColors.toastLabel}
            source="close"
            size={16}
          />
        </Pressable>
      )}
    </Pressable>
  );
}
