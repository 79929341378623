import { PhoneNumber, PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import KHColors from 'khshared/KHColors';
import React, { MutableRefObject, forwardRef, useEffect, useRef, useState } from 'react';
import { Text, TextInput, TextStyle, View, ViewStyle } from 'react-native';
import { formatWithMask } from 'react-native-mask-input';

import useUpdateEffect from '../utils/useUpdateEffect';
import KHConstants from './KHConstants';
import KHTextInput from './KHTextInput';

const styles = {
  countryCode: {
    minHeight: 24,
    padding: 12,
    paddingTop: 12, // For some reason this needs to be explicit on iOS.
    paddingLeft: 0,
    minWidth: 0,
    width: 30,
    outline: 'none',
    borderRightWidth: 1,
    borderRightColor: KHColors.textInputOutline,
    marginRight: 12,
  } as ViewStyle,
  countryCodeBox: {
    flexDirection: 'row',
  } as ViewStyle,
  countryCodePlus: {
    padding: 12,
    paddingRight: 0,
  } as TextStyle,
};

let phoneNumberUtil: PhoneNumberUtil;

function getPhoneNumberUtil(): PhoneNumberUtil {
  if (phoneNumberUtil == null) phoneNumberUtil = PhoneNumberUtil.getInstance();
  return phoneNumberUtil;
}

phoneNumberUtil = getPhoneNumberUtil();

/* eslint-disable react/require-default-props */
type Props = Omit<React.ComponentProps<typeof KHTextInput>, 'value' | 'onChangeText'> & {
  onChangePhoneNumber?: (phoneNumber: string | null, hasInvalidPhoneInput?: boolean) => void;
  phoneNumber?: string | null;
  countryCodeVisible?: boolean;
  errorMessage?: string;
};
/* eslint-enable react/require-default-props */

const PHONE_NUMBER_MASK = [
  '(',
  /\d/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

const MASKED_SYMBOLS = [' ', '-', '(', ')'];

const KHPhoneTextInput = forwardRef<TextInput, Props>(
  (
    {
      onChangePhoneNumber,
      phoneNumber,
      editable,
      countryCodeVisible = true,
      errorMessage = 'Please enter a valid phone number',
      ...khTextInputProps
    }: Props,
    ref: ((instance: TextInput | null) => void) | MutableRefObject<TextInput | null> | null,
  ): JSX.Element => {
    const [countryCode, setCountryCode] = useState<string>('1');
    const [phoneNumberString, setPhoneNumberString] = useState<string>('');
    const [formatError, setFormatError] = useState<string | null>(null);

    // set phone number from input to null or valid phone number
    function onChangeText(newCountryCode: string, newPhoneString: string) {
      try {
        const phoneNumberWithCountryCode = `+${newCountryCode}${newPhoneString}`;
        const number: PhoneNumber = phoneNumberUtil.parse(phoneNumberWithCountryCode);
        if (phoneNumberUtil?.isValidNumber(number)) {
          const formattedNumber = phoneNumberUtil.format(number, PhoneNumberFormat.E164);
          onChangePhoneNumber?.(formattedNumber, false);
        } else {
          throw new Error();
        }
      } catch (error) {
        onChangePhoneNumber?.(null, newPhoneString.length !== 0);
      }
    }

    useEffect(() => {
      if (phoneNumber != null) {
        // preset countryCode and phoneNumberString if phone number is given
        let presetCountryCode = '1';
        let presetPhoneNumberString = '';

        // to handle legacy 10-digit format phone numbers.
        if (phoneNumber.length === 10) {
          const maskedPhone = formatWithMask({
            text: phoneNumber,
            mask: PHONE_NUMBER_MASK,
          }).masked;
          presetPhoneNumberString = maskedPhone;
        } else {
          try {
            const number = phoneNumberUtil.parse(phoneNumber);
            presetCountryCode = number.getCountryCode()?.toString() ?? '1';
            const nationalPhonePart = number.getNationalNumber()?.toString() ?? '';
            const maskedPhone = formatWithMask({
              text: nationalPhonePart,
              mask: PHONE_NUMBER_MASK,
            }).masked;
            presetPhoneNumberString = maskedPhone;
          } catch (error) {
            presetCountryCode = '1';
            presetPhoneNumberString = '';
          }
        }

        setCountryCode(presetCountryCode);
        setPhoneNumberString(presetPhoneNumberString);
      }
    }, [phoneNumber]);

    return (
      <KHTextInput
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...khTextInputProps}
        onFocus={() => {
          setFormatError(null);
        }}
        onBlur={() => {
          setFormatError(phoneNumber != null || phoneNumberString === '' ? null : errorMessage);
        }}
        ref={ref}
        value={phoneNumberString}
        editable={editable}
        onChangeText={(text) => {
          let cleanedText = text;
          // check if user is in a delete mode
          if (phoneNumberString.length > text.length) {
            // delete last non digit symbol
            while (MASKED_SYMBOLS.includes(cleanedText[cleanedText.length - 1])) {
              cleanedText = cleanedText.slice(0, cleanedText.length - 1);
            }
          }
          const maskedPhone = formatWithMask({
            text: cleanedText,
            mask: PHONE_NUMBER_MASK,
          }).masked;
          setPhoneNumberString(maskedPhone);
          onChangeText(countryCode, maskedPhone);
        }}
        placeholder="(xxx) xxx-xxxx"
        keyboardType="number-pad"
        error={formatError ?? khTextInputProps.error}
        left={
          countryCodeVisible ? (
            <View style={styles.countryCodeBox}>
              <Text style={styles.countryCodePlus}>+</Text>
              <TextInput
                editable={editable}
                placeholder="x"
                style={[
                  styles.countryCode,
                  {
                    width: KHConstants.GUTTER_WIDTH + countryCode.length * 5,
                  },
                ]}
                value={countryCode}
                onFocus={() => setFormatError(null)}
                onBlur={() => {
                  setFormatError(
                    phoneNumber != null || phoneNumberString === '' ? null : errorMessage,
                  );
                }}
                onChangeText={(newCountryCode) => {
                  setCountryCode(newCountryCode.slice(0, 3));
                  onChangeText(newCountryCode, phoneNumberString);
                }}
              />
            </View>
          ) : null
        }
      />
    );
  },
);

function Debounced({
  phoneNumber,
  debounceDelayMS = 2000,
  onDebounceStart,
  onChangePhoneNumber,
  hasClearButton,
  onClear,
  right,
  inputReference,
  editable,
  ...restProps
}: Props & {
  inputReference?: MutableRefObject<TextInput | null>;
  onDebounceStart?: () => void;
  debounceDelayMS?: 500 | 1000 | 2000;
}): JSX.Element {
  const [debouncedValue, setDebouncedValue] = useState<string>(phoneNumber ?? '');
  const debouncingTimeoutIDRef = useRef<number | null>(null);
  const debouncingTimeoutFunctionRef = useRef<(() => void) | null>(null);

  // Using refs allows us to safely exclude handlers from the useUpdateEffect's deps list below
  // rather than relying on the parent to reliably use useCallback to ensure they don't change.
  const onDebounceStartRef = useRef(onDebounceStart);
  const onChangeTextRef = useRef(onChangePhoneNumber);

  function debounceValueChange(newValue: string | null) {
    if (newValue == null) return;
    setDebouncedValue(newValue);

    if (debouncingTimeoutIDRef.current == null) onDebounceStartRef.current?.();
    else clearTimeout(debouncingTimeoutIDRef.current);

    debouncingTimeoutFunctionRef.current = () => {
      debouncingTimeoutIDRef.current = null;
      onChangeTextRef.current?.(newValue);
    };
    debouncingTimeoutIDRef.current = window.setTimeout(
      debouncingTimeoutFunctionRef.current,
      debounceDelayMS,
    );
  }

  useEffect(() => {
    onDebounceStartRef.current = onDebounceStart;
    onChangeTextRef.current = onChangePhoneNumber;
  });

  useUpdateEffect(() => {
    const newValue = phoneNumber ?? '';
    if (newValue === debouncedValue) return;

    // Just in case things get out of sync
    if (debouncingTimeoutIDRef.current == null) setDebouncedValue(newValue);
  }, [phoneNumber]);

  useEffect(
    () => () => {
      if (debouncingTimeoutIDRef.current == null) return;

      // Flush any pending debounced handlers.
      clearTimeout(debouncingTimeoutIDRef.current);
      debouncingTimeoutFunctionRef.current?.();
    },
    [],
  );

  return (
    <KHPhoneTextInput
      phoneNumber={debouncedValue}
      onChangePhoneNumber={debounceValueChange}
      ref={inputReference}
      editable={editable}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...restProps}
    />
  );
}

export default Object.assign(KHPhoneTextInput, { Debounced });
