import {
  ApolloClient,
  ApolloLink,
  FieldPolicy,
  InMemoryCache,
  NormalizedCacheObject,
  createHttpLink,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers';
import { Amplify, Auth } from 'aws-amplify';
import { AUTH_TYPE, createAuthLink } from 'aws-appsync-auth-link';
import nullthrows from 'nullthrows';

import awsExports from '../../aws-exports';
import cloneAsInput from '../cloneAsInput';
import KHAuth from '../KHAuth';
import useLogger from '../useLogger';
import { PaginatedQueryResult } from './types';

Amplify.configure(awsExports);

const url = awsExports.aws_appsync_graphqlEndpoint;
const region = awsExports.aws_project_region;

let apolloClient: ApolloClient<NormalizedCacheObject> | null;

// Prefer using useApolloClient whenever possible. This is only intended as an escape hatch for when
// using useApolloClient would lead to an old client (typically before sign-in) being enclosed
// by a callback that is invoked on sign-in.
export function getApolloClientOrThrowDONOTUSE(): ApolloClient<NormalizedCacheObject> {
  return nullthrows(apolloClient);
}

function createOmitTypenameLink() {
  return new ApolloLink((operation, forward) => {
    if (operation.variables) {
      // eslint-disable-next-line no-param-reassign
      operation.variables = cloneAsInput(operation.variables);
    }

    return forward(operation);
  });
}

// Queries which support appync pagination and should be merged together in the cache
// when using `useQueryWithAllPages` or `useQuery` with regular pagination.
const QUERIES_WITH_PAGINATION = ['listAppointmentsByTechByDate'];

function appSyncPagination(): FieldPolicy<PaginatedQueryResult> {
  return {
    keyArgs: (args, { typename, fieldName, variables }) => {
      // Remove the pagination variables from the cache key since they're going to be
      // different for every page but we want all results to be merged into one cache
      // entry. Without this, we would only ever show the first page of results even if
      // we queried multiple.
      const nonPaginationVariables = variables
        ? Object.fromEntries(
            Object.entries(variables).filter(
              ([variableName]) => !['limit', 'nextToken'].includes(variableName),
            ),
          )
        : {};

      // Produce a cache key with all of the fields that are normally used
      return [typename, fieldName, JSON.stringify(nonPaginationVariables)];
    },
    merge(existing, incoming) {
      // With no existing data, or the existing data already including the last page,
      // we just store the incoming data which prevents us from storing the same results
      // repeatedly.
      if (!existing || existing.nextToken == null) {
        return incoming;
      }

      // Merge the existing page with the next page of results, and update the nextToken
      // to be the nextToken so that the next query makes a request for the correct page.
      return {
        items: [...existing.items, ...incoming.items],
        nextToken: incoming.nextToken,
      };
    },
  };
}

export function initializeApolloClient(): ApolloClient<NormalizedCacheObject> {
  if (KHAuth.isSignedIn()) {
    // Get the viewer's partner organizations, which also validates that they're all from the same
    // partner organization group or the user is a PES. If they're not, it's a very dangerous security issue, so we should
    // allow the validation error to bubble up and (intentionally) prevent initialization of the Apollo client.
    KHAuth.getPartnerOrganizations();
  }
  apolloClient = new ApolloClient({
    link: ApolloLink.from([
      onError(({ graphQLErrors }) => {
        if (graphQLErrors) {
          const logger = useLogger();
          graphQLErrors.forEach(({ message, locations, path }) => {
            logger.error({
              eventName: 'apolloClientResolverError',
              contextJSON: JSON.stringify({
                message,
                locations,
                path,
              }),
            });
          });
        }
      }),
      // From https://github.com/apollographql/apollo-client/issues/2160#issuecomment-643357153
      createOmitTypenameLink(),
      createAuthLink({
        url,
        region,
        auth: KHAuth.isSignedIn()
          ? {
              type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS as AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
              jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken(),
            }
          : {
              type: AUTH_TYPE.AWS_IAM as AUTH_TYPE.AWS_IAM,
              credentials: fromCognitoIdentityPool({
                identityPoolId: awsExports.aws_cognito_identity_pool_id,
                clientConfig: { region: awsExports.aws_cognito_region },
              }),
            },
      }) as ApolloLink,
      createHttpLink({ uri: url }),
    ]),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          // List of fields/queries where we want to automatically merge paginated results. This can
          // theoretically be done generically by passing appSyncPagination() to the `Query` type
          // policy instead, but that would apply the logic to every field which is more error-prone.
          fields: Object.fromEntries(
            QUERIES_WITH_PAGINATION.map((fieldName) => [fieldName, appSyncPagination()]),
          ),
        },
        Escalation: {
          merge: false, // We don't want to merge values, and only use fresh information for escalations
        },
      },
    }),
  });

  return apolloClient;
}
