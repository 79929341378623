import { PartnerOrganization } from '../API';
import Optionality from '../types/Optionality';

export enum PatientField {
  EXTERNAL_ID,
  SECONDARY_EXTERNAL_ID,
  PLAN_NAME,
  ENABLE_DIABETIC_CHECK,
}

export interface PatientIdentifier {
  label: string;
  pattern?: RegExp;
  placeholder?: string;
}

export type PatientConfig = {
  externalID?: PatientIdentifier;
  planName?: PatientIdentifier;
  secondaryExternalID?: PatientIdentifier;
  enableDiabeticCheck?: boolean;
  showMailingAddress?: boolean;
  responsiblePractitionerOptionality?: Optionality;
};

const BMC_GROUP_DEFAULT_PATIENT_CONFIG = {
  externalID: {
    label: 'MRN',
    pattern: /.*.{1,}/, // As part of initial BMC launch, we are not enforcing MRN external ID format
    placeholder: 'XXXXXXXXXX',
  },
  secondaryExternalID: {
    label: 'Member ID',
    placeholder: '123456789-01',
  },
  planName: {
    label: 'Plan name',
    placeholder: 'WellSense Community Alliance ACO',
  },
};

const MOLINA_GROUP_DEFAULT_PATIENT_CONFIG = {
  externalID: {
    label: 'Member ID',
    pattern: /.{1,}/, // for initial launch require at least 1 character
    placeholder: '',
  },
  planName: {
    label: 'Plan name',
    pattern: /.{1,}/,
    placeholder: '',
  },
  enableDiabeticCheck: true,
  responsiblePractitionerOptionality: 'optional' as const,
};
const WELLSENSENH_GROUP_DEFAULT_PATIENT_CONFIG = {
  externalID: {
    label: 'WellSense ID',
    pattern: /.{1,}/, // for initial launch require at least 1 character
    placeholder: '',
  },
  secondaryExternalID: {
    label: 'Member ID',
    pattern: /.{1,}/,
    placeholder: '',
  },
  planName: {
    label: 'Plan name',
    placeholder: 'WellSense Health Plan',
  },
  responsiblePractitionerOptionality: 'optional' as const,
};

const ELEVANCE_HEALTH_GROUP_DEFAULT_PATIENT_CONFIG = {
  externalID: {
    label: 'Member ID',
    pattern: /.+/, // for initial launch require at least 1 character
    placeholder: '',
  },
  responsiblePractitionerOptionality: 'optional' as const,
};

const CENTENE_GROUP_DEFAULT_PATIENT_CONFIG = {
  externalID: {
    label: 'Member ID',
    pattern: /.+/, // for initial launch require at least 1 character
    placeholder: '',
  },
  planName: {
    label: 'Plan name',
  },
  responsiblePractitionerOptionality: 'optional' as const,
  enableDiabeticCheck: true,
};

const HUMANA_GROUP_DEFAULT_PATIENT_CONFIG = {
  externalID: {
    label: 'Member ID',
    pattern: /.{1,}/,
    placeholder: 'XXXXXXXXXXXXX',
  },
  planName: {
    label: 'Plan name',
    placeholder: '',
  },
  responsiblePractitionerOptionality: 'optional' as const,
  enableDiabeticCheck: true,
};

export const PATIENT_CONFIG: {
  [partnerOrganization in PartnerOrganization]?: PatientConfig;
} = {
  CEDARSSINAI: {
    externalID: { label: 'MRN', pattern: /^[a-zA-Z0-9]{9}$/, placeholder: 'XXXXXXXXX' },
  },
  CENTRALCALIFORNIAALLIANCEFORHEALTH: {
    externalID: {
      label: 'member_id',
      pattern: /^[0-9]{8}[A-Za-z]$/,
      placeholder: 'XXXXXXXX',
    },
  },
  OSCAR: {
    externalID: {
      label: 'Oscar ID',
      pattern: /^OSC[0-9]{8}-[0-9]{2}$/,
      placeholder: 'OSC01234567-01',
    },
    planName: {
      label: 'Plan name',
      placeholder: 'Bronze Classic',
    },
    enableDiabeticCheck: true,
  },
  OSCARHEALTH: {
    externalID: {
      label: 'Oscar ID',
      pattern: /^OSC[0-9]{8}-[0-9]{2}$/,
      placeholder: 'OSC01234567-01',
    },
    secondaryExternalID: {
      label: 'Policy ID',
      placeholder: 'OSC01234567',
    },
    planName: {
      label: 'Plan name',
      placeholder: 'Bronze Classic',
    },
    enableDiabeticCheck: true,
    responsiblePractitionerOptionality: 'required',
  },
  OSCARDEMO: {
    externalID: {
      label: 'MRN',
      pattern: /.*/,
      placeholder: 'XXXXXXXXXX',
    },
    enableDiabeticCheck: true,
    responsiblePractitionerOptionality: 'required',
  },
  BMC: BMC_GROUP_DEFAULT_PATIENT_CONFIG,
  DOTHOUSE: BMC_GROUP_DEFAULT_PATIENT_CONFIG,
  MATTAPAN: {
    ...BMC_GROUP_DEFAULT_PATIENT_CONFIG,
    enableDiabeticCheck: true,
  },
  CODMAN: {
    ...BMC_GROUP_DEFAULT_PATIENT_CONFIG,
    enableDiabeticCheck: true,
  },
  BMCEVANS: {
    ...BMC_GROUP_DEFAULT_PATIENT_CONFIG,
    enableDiabeticCheck: true,
  },
  BMCFAMILYMEDICINE: {
    ...BMC_GROUP_DEFAULT_PATIENT_CONFIG,
    enableDiabeticCheck: true,
  },
  ROSLINDALE: {
    ...BMC_GROUP_DEFAULT_PATIENT_CONFIG,
    enableDiabeticCheck: true,
  },
  CHARLESRIVERFAMILYMEDICINE: { ...BMC_GROUP_DEFAULT_PATIENT_CONFIG, enableDiabeticCheck: true },
  STANFORDCANCERCENTER: {
    externalID: {
      label: 'MRN',
      pattern: /.*/, // As part of initial stanford cancer center launch, we are not enforcing MRN external ID format
      placeholder: 'XXXXXXXXXX',
    },
  },
  STANFORDRESEARCH: {
    externalID: {
      label: 'MRN',
      pattern: /.*/, // We are not enforcing MRN external ID format
      placeholder: 'XXXXXXXXXX',
    },
  },
  TRAININGPARTNER: {
    externalID: {
      label: 'MRN',
      pattern: /.*/, // We are not enforcing MRN external ID format
      placeholder: 'XXXXXXXXXX',
    },
    responsiblePractitionerOptionality: 'optional' as const,
  },
  WELLSENSE: {
    enableDiabeticCheck: true,
    secondaryExternalID: {
      label: 'Member ID',
      pattern: /.{1,}/,
      placeholder: '12345678901',
    },
    planName: {
      label: 'Plan name',
      placeholder: 'Senior Care Options',
    },
    responsiblePractitionerOptionality: 'optional' as const,
  },
  FIREFLYHEALTH: {
    enableDiabeticCheck: true,
  },
  ALIGNMENTCAREEXTENSION: {
    externalID: {
      label: 'Member ID',
      pattern: /^[\w]{1,11}$/,
      placeholder: 'XXXXXXXXXX',
    },
  },
  ALIGNMENTSTARS: {
    externalID: {
      label: 'Member ID',
      pattern: /^[\w]{1,11}$/,
      placeholder: 'XXXXXXXXXX',
    },
    enableDiabeticCheck: true,
  },
  MOLINAUT: MOLINA_GROUP_DEFAULT_PATIENT_CONFIG,
  MOLINAMI: MOLINA_GROUP_DEFAULT_PATIENT_CONFIG,
  MOLINACA: {
    ...MOLINA_GROUP_DEFAULT_PATIENT_CONFIG,
    responsiblePractitionerOptionality: 'required',
  },
  MOLINAFL: MOLINA_GROUP_DEFAULT_PATIENT_CONFIG,
  WELLSENSENHMEDICARE: { ...WELLSENSENH_GROUP_DEFAULT_PATIENT_CONFIG, enableDiabeticCheck: true },
  WELLSENSENHMEDICAID: WELLSENSENH_GROUP_DEFAULT_PATIENT_CONFIG,
  UCIHEALTH: {
    externalID: {
      label: 'Member ID',
      pattern: /^.{1,}$/,
      placeholder: 'XXXXXXX',
    },
  },
  UCLAHEALTH: {
    enableDiabeticCheck: true,
    // TODO(86b2x1mhm): Verify that this pattern for externalID is correct
    // For now, we are assuming that UCLA follows the same format at UCI
    externalID: {
      label: 'Member ID',
      pattern: /^.{1,}$/,
      placeholder: 'XXXXXXX',
    },
    responsiblePractitionerOptionality: 'optional',
  },
  ELEVANCEHEALTH: ELEVANCE_HEALTH_GROUP_DEFAULT_PATIENT_CONFIG,
  CENTENECA: CENTENE_GROUP_DEFAULT_PATIENT_CONFIG,
  CENTENEFL: CENTENE_GROUP_DEFAULT_PATIENT_CONFIG,
  CENTENEGA: CENTENE_GROUP_DEFAULT_PATIENT_CONFIG,
  CENTENEKY: CENTENE_GROUP_DEFAULT_PATIENT_CONFIG,
  CENTENEMA: CENTENE_GROUP_DEFAULT_PATIENT_CONFIG,
  CENTENEMI: CENTENE_GROUP_DEFAULT_PATIENT_CONFIG,
  CENTENENH: CENTENE_GROUP_DEFAULT_PATIENT_CONFIG,
  CENTENEOH: CENTENE_GROUP_DEFAULT_PATIENT_CONFIG,
  CENTENEME: CENTENE_GROUP_DEFAULT_PATIENT_CONFIG,
  CENTENEIN: CENTENE_GROUP_DEFAULT_PATIENT_CONFIG,
  CENTENECT: CENTENE_GROUP_DEFAULT_PATIENT_CONFIG,
  CENTENETX: CENTENE_GROUP_DEFAULT_PATIENT_CONFIG,
  UHCMA: {
    externalID: {
      label: 'Member ID',
      pattern: /^[0-9]{3}_[0-9]{10}$/,
      placeholder: 'XXX_XXXXXXXXXX',
    },
    planName: {
      label: 'Plan name',
      placeholder: '',
    },
    responsiblePractitionerOptionality: 'required',
  },
  HUMANA: {
    ...HUMANA_GROUP_DEFAULT_PATIENT_CONFIG,
  },
  HUMANANY: {
    ...HUMANA_GROUP_DEFAULT_PATIENT_CONFIG,
  },
  HUMANAMI: {
    ...HUMANA_GROUP_DEFAULT_PATIENT_CONFIG,
  },
  ACCOMPANYHEALTHMI: {
    externalID: {
      label: 'MRN',
      pattern: /^.{1,}$/,
      placeholder: 'XXXXXXXXXX',
    },
    enableDiabeticCheck: true,
    responsiblePractitionerOptionality: 'skip',
  },
  DEVOTEDHEALTH: {
    enableDiabeticCheck: true,
    responsiblePractitionerOptionality: 'skip',
    externalID: {
      label: 'Member ID',
      pattern: /^.*$/, // Match any string; TODO(ruben): add validation once we have the format from Partnerships
    },
  },
};
