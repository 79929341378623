import React, { Suspense, lazy } from 'react';
import { Platform, SafeAreaView, ViewStyle } from 'react-native';
import { Redirect, Switch } from 'react-router';

import AppVersionWarningModal from './components/tech/AppVersionWarningModal';
import KHErrorBoundary from './khui/KHErrorBoundary';
import DevRoute from './routing/DevRoute';
import PrivateRoute from './routing/PrivateRoute';
import PublicRoute from './routing/PublicRoute';
import LoadingScreen from './screens/LoadingScreen';
import AlertXAPI from './xapis/AlertXAPI';
import Router from './xapis/RouterXAPI';

// Shared screens
const HomeScreen = lazy(() => import('./screens/HomeScreen'));
const SignInScreen = lazy(() => import('./screens/SignInScreen'));
const ListRoutesScreen = lazy(() => import('./screens/ListRoutesScreen'));
const PermissionsScreen = lazy(() => import('./screens/PermissionsScreen'));

// Coordinator screens
const AddRequisitionScreen = lazy(() => import('./screens/self-booking/AddRequisitionScreen'));
const AppointmentBillingScreen = lazy(
  () => import('./screens/self-booking/AppointmentBillingScreen'),
);
const AppointmentLocationScreen = lazy(
  () => import('./screens/self-booking/AppointmentLocationScreen'),
);
const AppointmentNotesScreen = lazy(() => import('./screens/self-booking/AppointmentNotesScreen'));
const AppointmentPatientsScreen = lazy(
  () => import('./screens/self-booking/AppointmentPatientsScreen'),
);
const AppointmentPrepScreen = lazy(() => import('./screens/self-booking/AppointmentPrepScreen'));
const AppointmentPriceEstimateScreen = lazy(
  () => import('./screens/self-booking/AppointmentPriceEstimateScreen'),
);
const AppointmentServicesScreen = lazy(
  () => import('./screens/self-booking/AppointmentServicesScreen'),
);
const BookingRedirectFailureScreen = lazy(
  () => import('./screens/self-booking/BookingRedirectFailureScreen'),
);
const BookingRedirectScreen = lazy(() => import('./screens/self-booking/BookingRedirectScreen'));
const BookingSelectionScreen = lazy(() => import('./screens/self-booking/BookingSelectionScreen'));
const BookingSignInScreen = lazy(() => import('./screens/self-booking/BookingSignInScreen'));
const ChooseInsurancePhotoScreen = lazy(
  () => import('./screens/self-booking/ChooseInsurancePhotoScreen'),
);
const ChooseMedicarePhotoScreen = lazy(
  () => import('./screens/self-booking/ChooseMedicarePhotoScreen'),
);
const ChooseNewTimeScreen = lazy(() => import('./screens/self-booking/ChooseNewTimeScreen'));
const CoordinatorRoleScreen = lazy(() => import('./screens/self-booking/CoordinatorRoleScreen'));
const Covid19DisclaimerForUsScreen = lazy(
  () => import('./screens/self-booking/Covid19DisclaimerForUsScreen'),
);
const EncounterCOVID19QuestionsScreen = lazy(
  () => import('./screens/self-booking/EncounterCOVID19QuestionsScreen'),
);
const MedicareEligibilityStatusScreen = lazy(
  () => import('./screens/self-booking/MedicareEligibilityStatusScreen'),
);
const MedicareInsuranceIDScreen = lazy(
  () => import('./screens/self-booking/MedicareInsuranceIDScreen'),
);
const MedicareScreenerScreen = lazy(() => import('./screens/self-booking/MedicareScreenerScreen'));
const PatientInfoScreen = lazy(() => import('./screens/self-booking/PatientInfoScreen'));
const ScheduleAppointmentScreen = lazy(
  () => import('./screens/self-booking/ScheduleAppointmentScreen'),
);
const YoureBookedScreen = lazy(() => import('./screens/self-booking/YoureBookedScreen'));
const ForgotPasswordScreen = lazy(() => import('./screens/ForgotPasswordScreen'));
const ResetPasswordScreen = lazy(() => import('./screens/ResetPasswordScreen'));

const UnsubscribeScreen = lazy(() => import('./screens/UnsubscribeScreen'));

const ChangePasswordScreen = lazy(() => import('./screens/ChangePasswordScreen'));

const CancelAppointmentConfirmScreen = lazy(
  () => import('./screens/cancel-appointment/CancelAppointmentConfirmScreen'),
);
const CancelAppointmentSuccessScreen = lazy(
  () => import('./screens/cancel-appointment/CancelAppointmentSuccessScreen'),
);
const RescheduleAppointmentTimeScreen = lazy(
  () => import('./screens/reschedule-appointment/RescheduleAppointmentTimeScreen'),
);
const RescheduleAppointmentConfirmScreen = lazy(
  () => import('./screens/reschedule-appointment/RescheduleAppointmentConfirmScreen'),
);
const RescheduleAppointmentSuccessScreen = lazy(
  () => import('./screens/reschedule-appointment/RescheduleAppointmentSuccessScreen'),
);

// Out of Service Region Screens
const OutOfServiceRegionEmailConfirmScreen = lazy(
  () => import('./screens/self-booking/OutOfServiceRegionEmailConfirmScreen'),
);
const OutOfServiceRegionEmailSuccessScreen = lazy(
  () => import('./screens/self-booking/OutOfServiceRegionEmailSuccessScreen'),
);

// Tech screens
const TechAppointmentsScreen = lazy(() => import('./screens/tech/TechAppointmentsScreen'));
const TechConfirmationsScreen = lazy(() => import('./screens/tech/TechConfirmationsScreen'));
const TechConfirmationScriptScreen = lazy(
  () => import('./screens/tech/TechConfirmationScriptScreen'),
);
const TechAvailabilitiesScreen = lazy(() => import('./screens/tech/TechAvailabilitiesScreen'));
const TechProfileScreen = lazy(() => import('./screens/tech/TechProfileScreen'));
const TechAppointmentDetailsScreen = lazy(
  () => import('./screens/tech/TechAppointmentDetailsScreen'),
);
const TechAppointmentTransferredSuccessScreen = lazy(
  () => import('./screens/tech/TechAppointmentTransferredSuccessScreen'),
);
const TechAppointmentChecklistScreen = lazy(
  () => import('./screens/tech/TechAppointmentChecklistScreen'),
);
const TechSupportScreen = lazy(() => import('./screens/tech/TechSupportScreen'));
const TechShakeRedirectScreen = lazy(() => import('./screens/tech/TechShakeRedirectScreen'));
const TechDisallowedDeviceScreen = lazy(() => import('./screens/tech/TechDisallowedDeviceScreen'));

// Remote Tech screens
const RemoteTechToolbarScreen = lazy(() => import('./screens/remote-tech/RemoteTechToolbarScreen'));

// Scheduler Portal screens (cooler than panel)
const SchedulerListAppointmentsScreen = lazy(
  () => import('./screens/scheduler/SchedulerListAppointmentsScreen'),
);
const SchedulerPostReviewQueueScreen = lazy(
  () => import('./screens/scheduler/SchedulerPostReviewQueueScreen'),
);
const SchedulerListAppointmentsBySprinterScreen = lazy(
  () => import('./screens/scheduler/SchedulerListAppointmentsBySprinterScreen'),
);
const SchedulerAppointmentDetailsScreen = lazy(
  () => import('./screens/scheduler/SchedulerAppointmentDetailsScreen'),
);
const SchedulerChecklistScreen = lazy(() => import('./screens/scheduler/SchedulerChecklistScreen'));
const SchedulerExperimentalScreen = lazy(
  () => import('./screens/scheduler/SchedulerExperimentalScreen'),
);

const SchedulerSprinterListScreen = lazy(
  () => import('./screens/scheduler/SchedulerSprinterListScreen'),
);
const SchedulerSprinterProfileScreen = lazy(
  () => import('./screens/scheduler/SchedulerSprinterProfileScreen'),
);

const SchedulerDropOffDirectoryScreen = lazy(
  () => import('./screens/scheduler/SchedulerDropOffDirectoryScreen'),
);
const SchedulerCreateDropOffScreen = lazy(
  () => import('./screens/scheduler/SchedulerCreateDropOffScreen'),
);
const SchedulerDropOffDetailsScreen = lazy(
  () => import('./screens/scheduler/SchedulerDropOffDetailsScreen'),
);

const SchedulerPractitionerDirectoryScreen = lazy(
  () => import('./screens/scheduler/SchedulerPractitionerDirectoryScreen'),
);
const SchedulerCreatePractitionerScreen = lazy(
  () => import('./screens/scheduler/SchedulerCreatePractitionerScreen'),
);
const SchedulerPractitionerDetailsScreen = lazy(
  () => import('./screens/scheduler/SchedulerPractitionerDetailsScreen'),
);

const SchedulerZendeskTicketSidebarSurface = lazy(
  () => import('./screens/scheduler/zendesk/SchedulerZendeskTicketSidebarSurface'),
);

const SchedulerSearchAppointmentsScreen = lazy(
  () => import('./screens/scheduler/SchedulerSearchAppointmentsScreen'),
);

const SchedulerCreateSprinterScreen = lazy(
  () => import('./screens/scheduler/SchedulerCreateSprinterScreen'),
);

const SchedulerCampaignMetricsScreen = lazy(
  () => import('./screens/scheduler/SchedulerCampaignMetricsScreen'),
);

const SchedulerEscalationReportScreen = lazy(
  () => import('./screens/scheduler/SchedulerEscalationReportScreen'),
);

const SchedulerTeamAvailabilityScreen = lazy(
  () => import('./screens/scheduler/SchedulerTeamAvailabilityScreen'),
);

const SchedulerRemoteTechInfoScreen = lazy(
  () => import('./screens/scheduler/SchedulerRemoteTechInfoScreen'),
);

const SchedulerPatientListUploadsScreen = lazy(
  () => import('./screens/scheduler/SchedulerPatientListUploadsScreen'),
);

const SchedulerDNCListUploadScreen = lazy(
  () => import('./screens/scheduler/SchedulerDNCListUploadScreen'),
);

const SchedulerOutreachSMSPreviewScreen = lazy(
  () => import('./screens/scheduler/SchedulerOutreachSMSPreviewScreen'),
);

const SchedulerPatientCampaignStateActionScreen = lazy(
  () => import('./screens/scheduler/SchedulerPatientCampaignStateActionScreen'),
);

// CMS Manager screens
const CMSManagerContentManagementScreen = lazy(
  () => import('./screens/cms-manager/CMSManagerContentManagementScreen'),
);

// Partner screens
const PartnerAppointmentDetailsScreen = lazy(
  () => import('./screens/partner/PartnerAppointmentDetailsScreen'),
);
const PartnerAppointmentBookingScreen = lazy(
  () => import('./screens/partner/PartnerAppointmentBookingScreen'),
);
const PartnerListAppointmentsScreen = lazy(
  () => import('./screens/partner/PartnerListAppointmentsScreen'),
);
const PartnerAdminScreen = lazy(() => import('./screens/partner/PartnerAdminScreen'));

// PES screens

const PESCareNavigationScreen = lazy(
  () => import('./screens/partner/care-navigation/CareNavigationScreen'),
);

const PartnerAppointmentSearchScreen = lazy(
  () => import('./screens/partner/PartnerAppointmentSearchScreen'),
);

// Dev screens
const PlaygroundDirectoryScreen = lazy(
  () => import('./screens/playground/PlaygroundDirectoryScreen'),
);
const ExampleWizardLayoutScreen = lazy(
  () => import('./screens/playground/ExampleWizardLayoutScreen'),
);
const ExampleKHAppBarScreen = lazy(() => import('./screens/playground/ExampleKHAppBarScreen'));
const ExampleKHButtonScreen = lazy(() => import('./screens/playground/ExampleKHButtonScreen'));
const ExampleKHCheckboxScreen = lazy(() => import('./screens/playground/ExampleKHCheckboxScreen'));
const ExampleKHChipScreen = lazy(() => import('./screens/playground/ExampleKHChipScreen'));
const ExampleKHErrorViewScreen = lazy(
  () => import('./screens/playground/ExampleKHErrorViewScreen'),
);
const ExampleKHGridScreen = lazy(() => import('./screens/playground/ExampleKHGridScreen'));
const ExampleKHMessageFieldScreen = lazy(
  () => import('./screens/playground/ExampleKHMessageFieldScreen'),
);
const ExampleKHProgressBarScreen = lazy(
  () => import('./screens/playground/ExampleKHProgressBarScreen'),
);
const ExampleKHSwitchScreen = lazy(() => import('./screens/playground/ExampleKHSwitchScreen'));
const ExampleKHTextInputScreen = lazy(
  () => import('./screens/playground/ExampleKHTextInputScreen'),
);
const ExampleKHNumberInputScreen = lazy(
  () => import('./screens/playground/ExampleKHNumberInputScreen'),
);
const ExampleKHLookupScreen = lazy(() => import('./screens/playground/ExampleKHLookupScreen'));
const ExampleKHMaskedTextInputScreen = lazy(
  () => import('./screens/playground/ExampleKHMaskedTextInputScreen'),
);
const ExampleKHToastScreen = lazy(() => import('./screens/playground/ExampleKHToastScreen'));

const ExampleKHIconScreen = lazy(() => import('./screens/playground/ExampleKHIconScreen'));

const ExampleChecklistScreen = lazy(() => import('./screens/playground/ExampleChecklistScreen'));

const ExampleKHDropDownScreen = lazy(() => import('./screens/playground/ExampleKHDropDownScreen'));

const NotificationsScreen = lazy(() => import('./screens/playground/NotificationsScreen'));

const ExampleKHQRCodeScreen = lazy(() => import('./screens/playground/ExampleKHQRCodeScreen'));

const ExampleIconButtonScreen = lazy(() => import('./screens/playground/ExampleIconButtonScreen'));

const ExampleSearchInputScreen = lazy(
  () => import('./screens/playground/ExampleSearchInputScreen'),
);

const ExampleButtonScreen = lazy(() => import('./screens/playground/ExampleButtonScreen'));

const ExampleCaptionScreen = lazy(() => import('./screens/playground/ExampleCaptionScreen'));

const ExampleTagScreen = lazy(() => import('./screens/playground/ExampleTagScreen'));

const ExampleToastScreen = lazy(() => import('./screens/playground/ExampleToastScreen'));

const ExampleNextButtonScreen = lazy(() => import('./screens/playground/ExampleNextButtonScreen'));

const PatientCampaignStateScreen = lazy(
  () => import('./screens/playground/PatientCampaignStateScreen'),
);

const ExampleTimeRangeRulesInputFieldScreen = lazy(
  () => import('./screens/playground/ExampleTimeRangeRulesInputFieldScreen'),
);

const ExampleKHErrorBoundaryScreen = lazy(
  () => import('./screens/playground/ExampleKHErrorBoundaryScreen'),
);

const AddExampleStatsigEventScreen = lazy(
  () => import('./screens/playground/AddExampleStatsigEventScreen'),
);
const PatientEncounterSummary = lazy(
  () => import('./screens/playground/PatientEncounterSummaryExample'),
);

const PartnerConfigScreen = lazy(() => import('./screens/playground/PartnerConfigScreen'));

// Referrals screens
const ReferralVerificationScreen = lazy(
  () => import('./screens/referral/ReferralVerificationScreen'),
);

const ReferralAppointmentLocationScreen = lazy(
  () => import('./screens/referral/ReferralAppointmentLocationScreen'),
);

const ReferralChooseTimeScreen = lazy(() => import('./screens/referral/ReferralChooseTimeScreen'));

const ReferralServicesInfoScreen = lazy(
  () => import('./screens/referral/ReferralServicesInfoScreen'),
);

const ReferralConfirmChildRelatedFieldsScreen = lazy(
  () => import('./screens/referral/ReferralConfirmChildRelatedFieldsScreen'),
);

const ReferralAppointmentBookingSummaryScreen = lazy(
  () => import('./screens/referral/ReferralAppointmentBookingSummaryScreen'),
);

const ReferralAppointmentBookingFinishScreen = lazy(
  () => import('./screens/referral/ReferralAppointmentBookingFinishScreen'),
);

const ReferralAppointmentCancelSuccessScreen = lazy(
  () => import('./screens/referral/ReferralAppointmentCancelSuccessScreen'),
);

const ReferralAppointmentRescheduleScreen = lazy(
  () => import('./screens/referral/ReferralAppointmentRescheduleScreen'),
);

const ReferralColorectalScreeningScreen = lazy(
  () => import('./screens/referral/ReferralColorectalScreeningScreen'),
);

const ReferralTopicalFluoridePrescreeningScreen = lazy(
  () => import('./screens/referral/ReferralTopicalFluoridePrescreeningScreen'),
);

const styles = {
  safeArea: {
    flex: 1,
  } as ViewStyle,
};

function getUserConfirmation(message: string, callback: (ok: boolean) => void) {
  AlertXAPI('Leave page?', message, [
    {
      text: 'Cancel',
      onPress: () => callback(false),
      style: 'cancel',
    },
    { text: 'Leave', onPress: () => callback(true) },
  ]);
}

function Routes(): JSX.Element {
  return (
    <Switch>
      {/* Shared routes */}
      <PublicRoute exact path="/" component={HomeScreen} />
      <PublicRoute path="/signin" render={() => <SignInScreen initialForm="signIn" />} />
      <PublicRoute path="/signup" render={() => <SignInScreen initialForm="signUp" />} />
      <PublicRoute path="/android-permissions-screen" component={PermissionsScreen} />
      <PublicRoute path="/ios-permissions-screen" component={PermissionsScreen} />
      <PublicRoute path="/tech/disallowed-device" component={TechDisallowedDeviceScreen} />
      <PublicRoute path="/change-password" component={ChangePasswordScreen} />
      {/* Forgot password routes */}
      <PublicRoute path="/forgot-password" component={ForgotPasswordScreen} />
      <PublicRoute path="/reset-password" component={ResetPasswordScreen} />
      {/* Unsubscribe to email routes */}
      <PublicRoute path="/unsubscribe" component={UnsubscribeScreen} />
      {/* Coordinator routes */}
      <Redirect exact path="/booking" to="/booking/booking-redirect" />
      <PublicRoute
        path="/booking/booking-redirect/:partnerOrganization?"
        component={BookingRedirectScreen}
      />
      <PublicRoute
        path="/booking/booking-redirect-failure"
        component={BookingRedirectFailureScreen}
      />
      <PublicRoute path="/booking/coordinator-role" component={CoordinatorRoleScreen} />
      <PublicRoute path="/booking/appointment-services" component={AppointmentServicesScreen} />
      <PublicRoute path="/booking/appointment-location" component={AppointmentLocationScreen} />
      <PublicRoute
        path="/booking/out-of-service-region-email-confirm"
        component={OutOfServiceRegionEmailConfirmScreen}
      />
      <PublicRoute
        path="/booking/out-of-service-region-email-success"
        component={OutOfServiceRegionEmailSuccessScreen}
      />
      <PublicRoute
        path="/booking/appointment-price-estimate"
        component={AppointmentPriceEstimateScreen}
      />
      <PublicRoute path="/booking/schedule-appointment" component={ScheduleAppointmentScreen} />
      <PublicRoute path="/booking/booking-signin" component={BookingSignInScreen} />
      <PrivateRoute
        authorizedGroups={['Coordinators']}
        path="/booking/appointment-patients"
        component={AppointmentPatientsScreen}
      />
      <PrivateRoute
        authorizedGroups={['Coordinators']}
        path="/cancel-appointment/success"
        component={CancelAppointmentSuccessScreen}
      />
      <PrivateRoute
        authorizedGroups={['Coordinators']}
        path="/cancel-appointment/:appointmentID"
        component={CancelAppointmentConfirmScreen}
      />
      <PrivateRoute
        authorizedGroups={['Coordinators']}
        path="/reschedule-appointment/confirm"
        component={RescheduleAppointmentConfirmScreen}
      />
      <PrivateRoute
        authorizedGroups={['Coordinators']}
        path="/reschedule-appointment/success"
        component={RescheduleAppointmentSuccessScreen}
      />
      <PrivateRoute
        authorizedGroups={['Coordinators']}
        path="/reschedule-appointment/:appointmentID"
        component={RescheduleAppointmentTimeScreen}
      />
      <PrivateRoute
        authorizedGroups={['Coordinators']}
        path="/booking/booking-selection"
        component={BookingSelectionScreen}
      />
      <PrivateRoute
        authorizedGroups={['Coordinators']}
        path="/booking/covid-19-for-us"
        component={Covid19DisclaimerForUsScreen}
      />
      <PrivateRoute
        authorizedGroups={['Coordinators']}
        path="/booking/patient-info"
        component={PatientInfoScreen}
      />
      <PrivateRoute
        authorizedGroups={['Coordinators']}
        path="/booking/medicare-screener"
        component={MedicareScreenerScreen}
      />
      <PrivateRoute
        authorizedGroups={['Coordinators']}
        path="/booking/medicare-eligibility-status"
        component={MedicareEligibilityStatusScreen}
      />
      <PrivateRoute
        authorizedGroups={['Coordinators']}
        path="/booking/medicare-photo"
        component={ChooseMedicarePhotoScreen}
      />
      <PrivateRoute
        authorizedGroups={['Coordinators']}
        path="/booking/medicare-insurance-id"
        component={MedicareInsuranceIDScreen}
      />
      <PrivateRoute
        authorizedGroups={['Coordinators']}
        path="/booking/insurance-photo"
        component={ChooseInsurancePhotoScreen}
      />
      <PrivateRoute
        authorizedGroups={['Coordinators']}
        path="/booking/requisition"
        component={AddRequisitionScreen}
      />
      <PrivateRoute
        authorizedGroups={['Coordinators']}
        path="/booking/encounter-covid-19-questions"
        component={EncounterCOVID19QuestionsScreen}
      />
      <PrivateRoute
        authorizedGroups={['Coordinators']}
        path="/booking/appointment-billing"
        component={AppointmentBillingScreen}
      />
      <PrivateRoute
        authorizedGroups={['Coordinators']}
        path="/booking/choose-new-time"
        component={ChooseNewTimeScreen}
      />
      <PrivateRoute
        authorizedGroups={['Coordinators']}
        path="/booking/youre-booked"
        component={YoureBookedScreen}
      />
      <PrivateRoute
        authorizedGroups={['Coordinators']}
        path="/booking/appointment-prep"
        component={AppointmentPrepScreen}
      />
      <PrivateRoute
        authorizedGroups={['Coordinators']}
        path="/booking/appointment-notes"
        component={AppointmentNotesScreen}
      />
      {/* Tech routes */}
      <PrivateRoute
        exact
        authorizedGroups={['Techs']}
        path="/tech/appointments"
        render={() => (
          /* To better debug issues with the tech app, adds stack trace */
          <KHErrorBoundary tags={{ location: 'tech-appointments-screen' }} includeStackTrace>
            <TechAppointmentsScreen />
          </KHErrorBoundary>
        )}
      />
      <PrivateRoute
        exact
        authorizedGroups={['Techs']}
        path="/tech/confirmations"
        render={() => (
          <KHErrorBoundary tags={{ location: 'tech-confirmations-screen' }} includeStackTrace>
            <TechConfirmationsScreen />
          </KHErrorBoundary>
        )}
      />
      <PrivateRoute
        exact
        authorizedGroups={['Techs']}
        path="/tech/confirmation/:appointmentID"
        render={() => (
          <KHErrorBoundary tags={{ location: 'tech-confirmation-script-screen' }} includeStackTrace>
            <TechConfirmationScriptScreen />
          </KHErrorBoundary>
        )}
      />
      <PrivateRoute
        authorizedGroups={['Techs']}
        path="/tech/profile"
        render={() => (
          <KHErrorBoundary tags={{ location: 'tech-profile-screen' }} includeStackTrace>
            <TechProfileScreen />
          </KHErrorBoundary>
        )}
      />
      <PrivateRoute
        authorizedGroups={['Techs']}
        path="/tech/appointments/:appointmentID/details"
        render={() => (
          <KHErrorBoundary tags={{ location: 'tech-appointment-details-screen' }} includeStackTrace>
            <TechAppointmentDetailsScreen />
          </KHErrorBoundary>
        )}
      />
      <PrivateRoute
        authorizedGroups={['Techs']}
        path="/tech/appointment-transferred-success"
        render={() => (
          <KHErrorBoundary
            tags={{ location: 'tech-appointment-transfer-screen' }}
            includeStackTrace
          >
            <TechAppointmentTransferredSuccessScreen />
          </KHErrorBoundary>
        )}
      />
      <PrivateRoute
        exact
        authorizedGroups={['Techs']}
        path="/tech/appointments/:appointmentID/checklist"
        render={() => (
          <KHErrorBoundary
            tags={{ location: 'tech-appointment-checklist-screen' }}
            includeStackTrace
          >
            <TechAppointmentChecklistScreen />
          </KHErrorBoundary>
        )}
      />
      <PrivateRoute
        authorizedGroups={['Techs']}
        path="/tech/support"
        render={() => (
          <KHErrorBoundary tags={{ location: 'tech-support-screen' }} includeStackTrace>
            <TechSupportScreen />
          </KHErrorBoundary>
        )}
      />
      {/* Remote tech routes */}
      <PrivateRoute
        authorizedGroups={['Schedulers']}
        path="/remote-tech/toolbar/:overrideAppointmentID?"
        desktopOnly
        render={() => (
          <KHErrorBoundary tags={{ location: 'remote-tech-toolbar' }} includeStackTrace>
            <RemoteTechToolbarScreen />
          </KHErrorBoundary>
        )}
      />
      {/* Scheduler routes */}
      <PrivateRoute
        authorizedGroups={['Schedulers']}
        path="/tech/availabilities"
        component={TechAvailabilitiesScreen}
      />
      <PrivateRoute
        exact
        desktopOnly
        authorizedGroups={['Schedulers']}
        path="/scheduler/appointments/experimental"
        render={() => (
          <KHErrorBoundary tags={{ location: 'experimental-screen' }}>
            <SchedulerExperimentalScreen />
          </KHErrorBoundary>
        )}
      />
      <PrivateRoute
        exact
        desktopOnly
        authorizedGroups={['Schedulers']}
        path="/scheduler/sprinters"
        render={() => (
          <KHErrorBoundary tags={{ location: 'sprinters-list-screen' }}>
            <SchedulerSprinterListScreen />
          </KHErrorBoundary>
        )}
      />
      <PrivateRoute
        exact
        desktopOnly
        authorizedGroups={['Schedulers']}
        path="/scheduler/sprinter/create"
        render={() => (
          <KHErrorBoundary tags={{ location: 'sprinter-create-screen' }}>
            <SchedulerCreateSprinterScreen />
          </KHErrorBoundary>
        )}
      />
      <PrivateRoute
        desktopOnly
        authorizedGroups={['Schedulers']}
        path="/scheduler/appointments/:appointmentID/escalationReport/:encounterID?"
        render={(props) => (
          <KHErrorBoundary tags={{ location: 'escalation-report-screen' }}>
            <SchedulerEscalationReportScreen
              key={`escalationReportScreen-${props.match.params.appointmentID || ''}-${
                props.match.params.encounterID || ''
              }`}
            />
          </KHErrorBoundary>
        )}
      />
      <PrivateRoute
        exact
        desktopOnly
        authorizedGroups={['Schedulers']}
        path="/scheduler/sprinter/:techID"
        render={() => (
          <KHErrorBoundary tags={{ location: 'sprinter-profile-screen' }}>
            <SchedulerSprinterProfileScreen />
          </KHErrorBoundary>
        )}
      />
      <PrivateRoute
        exact
        desktopOnly
        authorizedGroups={['Schedulers']}
        path="/scheduler/appointments/postReviewQueue"
        render={() => (
          <KHErrorBoundary tags={{ location: 'post-review-queue' }}>
            <SchedulerPostReviewQueueScreen />
          </KHErrorBoundary>
        )}
      />
      <PrivateRoute
        exact
        desktopOnly
        authorizedGroups={['Schedulers']}
        path="/scheduler/appointments/:view?"
        render={() => (
          <KHErrorBoundary tags={{ location: 'list-appointments-screen' }}>
            <SchedulerListAppointmentsScreen />
          </KHErrorBoundary>
        )}
      />
      <PrivateRoute
        desktopOnly
        authorizedGroups={['Schedulers']}
        path="/scheduler/sprinter-appointments"
        render={() => (
          <KHErrorBoundary tags={{ location: 'sprinter-appointments-screen' }}>
            <SchedulerListAppointmentsBySprinterScreen />
          </KHErrorBoundary>
        )}
      />
      <PrivateRoute
        desktopOnly
        authorizedGroups={['Schedulers']}
        path="/scheduler/appointments/details/:appointmentID"
        render={(props) => (
          <KHErrorBoundary tags={{ location: 'list-appointments-screen' }}>
            <SchedulerAppointmentDetailsScreen key={props.match.params.appointmentID} />
          </KHErrorBoundary>
        )}
      />
      <PrivateRoute
        desktopOnly
        authorizedGroups={['Schedulers']}
        path="/scheduler/appointments/:primaryChecklistName/:appointmentID"
        render={(props) => (
          <KHErrorBoundary tags={{ location: 'list-appointments-screen' }}>
            <SchedulerChecklistScreen
              key={`${props.match.params.primaryChecklistName ?? ''}/${
                props.match.params.appointmentID ?? ''
              }`}
            />
          </KHErrorBoundary>
        )}
      />
      <PrivateRoute
        exact
        desktopOnly
        authorizedGroups={['Schedulers']}
        path="/scheduler/drop-offs/directory"
        render={() => (
          <KHErrorBoundary tags={{ location: 'drop-off-directory-screen' }}>
            <SchedulerDropOffDirectoryScreen />
          </KHErrorBoundary>
        )}
      />
      <PrivateRoute
        exact
        desktopOnly
        authorizedGroups={['Schedulers']}
        path="/scheduler/drop-offs/create"
        render={() => (
          <KHErrorBoundary tags={{ location: 'drop-off-create-screen' }}>
            <SchedulerCreateDropOffScreen />
          </KHErrorBoundary>
        )}
      />
      <PrivateRoute
        exact
        desktopOnly
        authorizedGroups={['Schedulers']}
        path="/scheduler/drop-offs/:dropOffID"
        render={(props) => (
          <KHErrorBoundary tags={{ location: 'drop-off-details-screen' }}>
            <SchedulerDropOffDetailsScreen key={props.match.params.dropOffID} />
          </KHErrorBoundary>
        )}
      />
      <PrivateRoute
        exact
        desktopOnly
        authorizedGroups={['Schedulers']}
        path="/scheduler/practitioners/directory"
        render={() => (
          <KHErrorBoundary tags={{ location: 'practitioners-directory-screen' }}>
            <SchedulerPractitionerDirectoryScreen />
          </KHErrorBoundary>
        )}
      />
      <PrivateRoute
        exact
        desktopOnly
        authorizedGroups={['Schedulers']}
        path="/scheduler/practitioners/create"
        render={() => (
          <KHErrorBoundary tags={{ location: 'practitioners-create-screen' }}>
            <SchedulerCreatePractitionerScreen />
          </KHErrorBoundary>
        )}
      />
      <PrivateRoute
        exact
        desktopOnly
        authorizedGroups={['Schedulers']}
        path="/scheduler/practitioners/:practitionerID"
        render={(props) => (
          <KHErrorBoundary tags={{ location: 'practitioners-details-screen' }}>
            <SchedulerPractitionerDetailsScreen key={props.match.params.practitionerID} />
          </KHErrorBoundary>
        )}
      />
      <PrivateRoute
        exact
        desktopOnly
        authorizedGroups={['Schedulers']}
        path="/scheduler/zendesk/ticket-sidebar"
        component={SchedulerZendeskTicketSidebarSurface}
      />
      <PrivateRoute
        desktopOnly
        authorizedGroups={['Schedulers']}
        path="/scheduler/search-appointments"
        component={SchedulerSearchAppointmentsScreen}
        render={() => (
          <KHErrorBoundary tags={{ location: 'search-appointments-screen' }}>
            <SchedulerSearchAppointmentsScreen />
          </KHErrorBoundary>
        )}
      />
      <PrivateRoute
        exact
        desktopOnly
        authorizedGroups={['Schedulers']}
        path="/scheduler/campaign-metrics"
        component={SchedulerCampaignMetricsScreen}
      />
      <PrivateRoute
        exact
        desktopOnly
        authorizedGroups={['Schedulers']}
        path="/scheduler/team-availability"
        component={SchedulerTeamAvailabilityScreen}
      />
      <PrivateRoute
        exact
        desktopOnly
        authorizedGroups={['Schedulers']}
        path="/scheduler/team-availability/remote-tech"
        component={SchedulerRemoteTechInfoScreen}
      />
      <PrivateRoute
        desktopOnly
        authorizedGroups={['Schedulers']}
        path="/scheduler/patient-list-uploads"
        component={SchedulerPatientListUploadsScreen}
      />
      <PrivateRoute
        desktopOnly
        authorizedGroups={['Schedulers']}
        path="/scheduler/dnc-list-uploads"
        component={SchedulerDNCListUploadScreen}
      />
      <PrivateRoute
        desktopOnly
        authorizedGroups={['Schedulers', 'CMSManagers']}
        path="/scheduler/outreach-sms-preview"
        component={SchedulerOutreachSMSPreviewScreen}
      />
      <PrivateRoute
        desktopOnly
        authorizedGroups={['Schedulers']}
        path="/scheduler/patient-campaign-state-action"
        component={SchedulerPatientCampaignStateActionScreen}
      />
      {/* CMS Manager routes */}
      <PrivateRoute
        desktopOnly
        authorizedGroups={['CMSManagers']}
        path="/cms-manager/content-management"
        component={CMSManagerContentManagementScreen}
      />
      {/* Partner routes */}
      <PrivateRoute
        exact
        desktopOnly
        authorizedGroups={['Partners']}
        path="/partner/appointments"
        component={PartnerListAppointmentsScreen}
      />
      <PrivateRoute
        exact
        desktopOnly
        authorizedGroups={['Partners', 'Schedulers']}
        path="/partner/appointments/book"
        render={(props) => <PartnerAppointmentBookingScreen key={props.location.search} />}
      />
      <PrivateRoute
        exact
        desktopOnly
        authorizedGroups={['Partners', 'Schedulers']}
        path="/partner/appointments/book/:appointmentIDToDuplicate"
        render={(props) => (
          <PartnerAppointmentBookingScreen key={props.match.params.appointmentIDToDuplicate} />
        )}
      />
      <PrivateRoute
        desktopOnly
        authorizedGroups={['Partners', 'Schedulers']}
        path="/partner/appointments/reschedule/:appointmentIDToDuplicate"
        render={(props) => (
          <PartnerAppointmentBookingScreen key={props.match.params.appointmentIDToDuplicate} />
        )}
      />
      <PrivateRoute
        exact
        desktopOnly
        authorizedGroups={['Partners']}
        path="/partner/admin"
        component={PartnerAdminScreen}
      />
      <PrivateRoute
        exact
        desktopOnly
        authorizedGroups={['Schedulers', 'PatientEngagementCSRs']}
        path="/partner/care-navigation"
        component={PESCareNavigationScreen}
      />
      <PrivateRoute
        exact
        desktopOnly
        authorizedGroups={['PatientEngagementCSRs']}
        path="/partner/search-appointments"
        component={PartnerAppointmentSearchScreen}
      />
      <PrivateRoute
        desktopOnly
        authorizedGroups={['Partners', 'Schedulers']}
        path="/partner/appointments/:appointmentID/details"
        render={(props) => (
          <PartnerAppointmentDetailsScreen key={props.match.params.appointmentID} />
        )}
      />
      {/* Referral routes */}
      <PublicRoute
        path="/referral-booking/verification/:partnerOrganizationOrBrandSlug?/:brandSlug?"
        component={ReferralVerificationScreen}
      />
      <PublicRoute path="/referral-booking/services-info" component={ReferralServicesInfoScreen} />
      <PublicRoute
        path="/referral-booking/confirm-child-related-fields"
        component={ReferralConfirmChildRelatedFieldsScreen}
      />
      <PublicRoute
        path="/referral-booking/referral-appointment-location"
        component={ReferralAppointmentLocationScreen}
      />
      <PublicRoute path="/referral-booking/choose-time" component={ReferralChooseTimeScreen} />
      <PublicRoute
        path="/referral-booking/booking-summary"
        component={ReferralAppointmentBookingSummaryScreen}
      />
      <PublicRoute
        path="/referral-booking/booking-finished"
        component={ReferralAppointmentBookingFinishScreen}
      />
      <PublicRoute
        path="/referral-booking/booking-canceled"
        component={ReferralAppointmentCancelSuccessScreen}
      />
      <PublicRoute
        path="/referral-booking/reschedule-appointment"
        component={ReferralAppointmentRescheduleScreen}
      />
      <PublicRoute
        path="/referral-booking/colorectal-cancer-screening"
        component={ReferralColorectalScreeningScreen}
      />
      <PublicRoute
        path="/referral-booking/topical-fluoride-prescreening"
        component={ReferralTopicalFluoridePrescreeningScreen}
      />
      {/* Referral routes for case-managers */}
      <PrivateRoute
        exact
        authorizedGroups={['CaseManagers']}
        path="/case-manager/referral-booking/verification/:partnerOrganizationOrBrandSlug?/:brandSlug?"
        component={ReferralVerificationScreen}
      />
      <PrivateRoute
        exact
        authorizedGroups={['CaseManagers']}
        path="/case-manager/referral-booking/services-info"
        component={ReferralServicesInfoScreen}
      />
      <PrivateRoute
        exact
        authorizedGroups={['CaseManagers']}
        path="/case-manager/referral-booking/confirm-child-related-fields"
        component={ReferralConfirmChildRelatedFieldsScreen}
      />
      <PrivateRoute
        exact
        authorizedGroups={['CaseManagers']}
        path="/case-manager/referral-booking/referral-appointment-location"
        component={ReferralAppointmentLocationScreen}
      />
      <PrivateRoute
        exact
        authorizedGroups={['CaseManagers']}
        path="/case-manager/referral-booking/choose-time"
        component={ReferralChooseTimeScreen}
      />
      <PrivateRoute
        exact
        authorizedGroups={['CaseManagers']}
        path="/case-manager/referral-booking/booking-summary"
        component={ReferralAppointmentBookingSummaryScreen}
      />
      <PrivateRoute
        exact
        authorizedGroups={['CaseManagers']}
        path="/case-manager/referral-booking/booking-finished"
        component={ReferralAppointmentBookingFinishScreen}
      />
      <PrivateRoute
        exact
        authorizedGroups={['CaseManagers']}
        path="/case-manager/referral-booking/booking-canceled"
        component={ReferralAppointmentCancelSuccessScreen}
      />
      <PrivateRoute
        exact
        authorizedGroups={['CaseManagers']}
        path="/case-manager/referral-booking/reschedule-appointment"
        component={ReferralAppointmentRescheduleScreen}
      />
      <PrivateRoute
        exact
        authorizedGroups={['CaseManagers']}
        path="/case-manager/referral-booking/colorectal-cancer-screening"
        component={ReferralColorectalScreeningScreen}
      />
      <PrivateRoute
        exact
        authorizedGroups={['CaseManagers']}
        path="/case-manager/referral-booking/topical-fluoride-prescreening"
        component={ReferralTopicalFluoridePrescreeningScreen}
      />
      {/* Tech confirmations page entrypoint into referrals flow */}
      <PrivateRoute
        exact
        authorizedGroups={['Techs']}
        path="/tech/referral-booking/verification"
        component={ReferralVerificationScreen}
      />
      {/* Dev routes */}
      <DevRoute exact path="/playground" component={PlaygroundDirectoryScreen} />
      <DevRoute path="/playground/example-wizard-layout" component={ExampleWizardLayoutScreen} />
      <DevRoute path="/playground/example-khappbar" component={ExampleKHAppBarScreen} />
      <DevRoute path="/playground/example-khbutton" component={ExampleKHButtonScreen} />
      <DevRoute path="/playground/example-khcheckbox" component={ExampleKHCheckboxScreen} />
      <DevRoute path="/playground/example-khchip" component={ExampleKHChipScreen} />
      <DevRoute path="/playground/example-kherrorview" component={ExampleKHErrorViewScreen} />
      <DevRoute path="/playground/example-khgrid" component={ExampleKHGridScreen} />
      <DevRoute path="/playground/example-khmessagefield" component={ExampleKHMessageFieldScreen} />
      <DevRoute path="/playground/example-khnumberinput" component={ExampleKHNumberInputScreen} />
      <DevRoute path="/playground/example-khprogressbar" component={ExampleKHProgressBarScreen} />
      <DevRoute path="/playground/example-khswitch" component={ExampleKHSwitchScreen} />
      <DevRoute path="/playground/example-khtextinput" component={ExampleKHTextInputScreen} />
      <DevRoute path="/playground/example-khlookup" component={ExampleKHLookupScreen} />
      <DevRoute
        path="/playground/example-khmaskedtextinput"
        component={ExampleKHMaskedTextInputScreen}
      />
      <DevRoute path="/playground/example-khtoast" component={ExampleKHToastScreen} />
      <DevRoute path="/playground/example-khicon" component={ExampleKHIconScreen} />
      <DevRoute path="/playground/example-checklist" component={ExampleChecklistScreen} />
      <DevRoute path="/playground/example-khdropdown" component={ExampleKHDropDownScreen} />
      <DevRoute
        path="/playground/example-khdropdown"
        component={ExampleKHDropDownScreen}
        desktopOnly
      />
      <DevRoute path="/playground/example-khqrcode" component={ExampleKHQRCodeScreen} />
      <DevRoute path="/playground/example-iconbutton" component={ExampleIconButtonScreen} />
      <DevRoute path="/playground/example-searchinput" component={ExampleSearchInputScreen} />
      <DevRoute path="/playground/example-button" component={ExampleButtonScreen} />
      <DevRoute path="/playground/example-caption" component={ExampleCaptionScreen} />
      <DevRoute path="/playground/example-tag" component={ExampleTagScreen} />
      <DevRoute path="/playground/example-toast" component={ExampleToastScreen} />
      <DevRoute path="/playground/example-nextbutton" component={ExampleNextButtonScreen} />
      <DevRoute path="/playground/notifications" desktopOnly component={NotificationsScreen} />
      <DevRoute
        path="/playground/example-time-range-rules-input-field"
        component={ExampleTimeRangeRulesInputFieldScreen}
        desktopOnly
      />
      <DevRoute
        path="/playground/example-kherrorboundary"
        component={ExampleKHErrorBoundaryScreen}
      />
      <DevRoute
        path="/playground/patient-campaign-state"
        desktopOnly
        component={PatientCampaignStateScreen}
      />{' '}
      <DevRoute path="/playground/create-statsig-event" component={AddExampleStatsigEventScreen} />
      <DevRoute
        path="/playground/patient-encounter-summary"
        desktopOnly
        component={PatientEncounterSummary}
      />
      <DevRoute path="/playground/partner-config" desktopOnly component={PartnerConfigScreen} />
      <DevRoute path="/list-routes" component={ListRoutesScreen} />
      <Redirect to="/" />
    </Switch>
  );
}

export default function AppRouter(): JSX.Element {
  return (
    <Router getUserConfirmation={getUserConfirmation}>
      <SafeAreaView style={styles.safeArea}>
        <Suspense fallback={<LoadingScreen />}>
          <PublicRoute path="/tech" component={TechShakeRedirectScreen} />
          <Routes />
          {Platform.OS === 'ios' && <AppVersionWarningModal />}
        </Suspense>
      </SafeAreaView>
    </Router>
  );
}
